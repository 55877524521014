<template>
  <div class="address-actions-box">
    <ul
      v-if="!verticalMode"
      class="no-markers"
      @mouseover="onAddressActionsBoxHover"
      @click="hidePopover"
    >
      <li>
        <f-copy-button
          custom-id="address-actions-box__copy-btn"
          :text="currentAccount.address"
          :hide-popover-after="3100"
          class="btn large light same-size round dark address-actions-box__copy-btn"
        >
          <template #popover-text>
            Address copied to clipboard.
          </template>
        </f-copy-button>
      </li>
      <li>
        <button
          id="address-actions-box__qr-btn"
          class="btn large light same-size round dark"
          @click="$refs.qrWindow.show()"
        >
          <icon
            data="@/assets/svg/monochrome/Options/QR.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
        </button>
      </li>
      <li v-if="downloadKeystoreFile">
        <button
          id="address-actions-box__keystore-btn"
          class="btn large light same-size round"
          @click="onDownloadKeystoreClick"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Download.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
        </button>
      </li>
      <li v-if="currentAccount.isLedgerAccount">
        <button
          id="address-actions-box__ledger-btn"
          class="btn large light same-size round"
          @click="onVerifyOnLedgerClick"
        >
          <icon
            data="@/assets/svg/check.svg"
            width="20"
            height="20"
          />
        </button>
      </li>
      <li>
        <button
          id="address-actions-box__edit-btn"
          class="btn large light same-size round dark"
          @click="$refs.accountSettingsWindow.show()"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Edit.svg"
            width="16"
            height="16"
            aria-hidden="true"
          />
        </button>
      </li>
      <li>
        <button
          id="address-actions-box__remove-btn"
          class="btn large light same-size round dark"
          @click="$refs.removeAccountWindow.show()"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Logout.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
        </button>
      </li>
    </ul>
    <ul
      v-else
      class="no-markers vertical-mode"
    >
      <li>
        <f-copy-button
          :text="currentAccount.address"
          :hide-popover-after="3100"
          class="btn large light"
          @window-hide="onWindowHide"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Copy.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
          Copy Address
          <template #popover-text>
            Address copied to clipboard.
          </template>
        </f-copy-button>
      </li>
      <li>
        <button
          class="btn large light dark"
          @click="$refs.qrWindow.show()"
        >
          <icon
            data="@/assets/svg/monochrome/Options/QR.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
          Show QR Code
        </button>
      </li>
      <li v-if="currentAccount.isLedgerAccount">
        <button
          class="btn large light dark"
          @click="onVerifyOnLedgerClick"
        >
          <icon
            data="@/assets/svg/check.svg"
            width="20"
            height="20"
          />
          Verify on Ledger
        </button>
      </li>
      <li>
        <button
          class="btn large light dark"
          @click="$refs.accountSettingsWindow.show()"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Edit.svg"
            width="16"
            height="16"
            aria-hidden="true"
          />
          Edit Wallet
        </button>
      </li>
      <li v-if="downloadKeystoreFile">
        <button
          class="btn large light"
          @click="onDownloadKeystoreClick"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Download.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
          Download Keystore
        </button>
      </li>
      <li>
        <button
          class="btn large light dark"
          @click="$refs.removeAccountWindow.show()"
        >
          <icon
            data="@/assets/svg/monochrome/Options/Logout.svg"
            width="20"
            height="20"
            aria-hidden="true"
          />
          Remove Wallet
        </button>
      </li>
    </ul>

    <q-r-code-window
      ref="qrWindow"
      :address="currentAccount.address"
      @window-hide="onWindowHide"
    />

    <account-settings-window
      ref="accountSettingsWindow"
      :account-address="accountData.address"
      :account-order="accountData.order"
      @window-hide="onWindowHide"
    />
    <remove-account-window ref="removeAccountWindow"/>
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="popoverAttachId"
      :attach-position="popoverAttachPosition"
      :preferred-attach-position="popoverAttachPosition"
      :attach-margin="[1, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FWindow from '../core/FWindow/FWindow.vue';
import FCopyButton from '../core/FCopyButton/FCopyButton.vue';
import AccountSettingsWindow from '../windows/AccountSettingsWindow/AccountSettingsWindow.vue';
import QRCodeWindow from '../windows/QRCodeWindow/QRCodeWindow.vue';
import RemoveAccountWindow from '../windows/RemoveAccountWindow/RemoveAccountWindow.vue';
import { clientInfo } from '../../utils/client-info';

export default {
  components: {
    RemoveAccountWindow,
    QRCodeWindow,
    AccountSettingsWindow,
    FCopyButton,
    FWindow,
  },

  props: {
    /** Show buttons with labels. */
    verticalMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      popoverCreated: false,
      popoverAttachId: '',
      popoverAttachPosition: '',
      popoverText: '',
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    accountData() {
      return {
        address: this.currentAccount.address,
        order: -1,
      };
    },

    downloadKeystoreFile() {
      return !this.currentAccount.isLedgerAccount && !this.currentAccount.isMetamaskAccount && !clientInfo.mobile;
    },
  },

  methods: {
    onDownloadKeystoreClick() {
      const { keystore } = this.currentAccount;

      if (keystore) {
        this.$fWallet.downloadKeystore(keystore);
      }
    },

    onVerifyOnLedgerClick() {
      this.$router.push({
        name: 'account-receive',
        params: { verify: true },
      })
        .catch(() => {
        });
      this.$emit('window-hide');
    },

    /**
     * Re-target `'window-hide'` event.
     *
     * @param {object} _data
     */
    onWindowHide(_data) {
      this.$emit('window-hide', _data);
    },

    /**
     * Show or hide popovers for buttons in the actions box
     *
     * @param {Event: target} target
     */
    onAddressActionsBoxHover({ target }) {
      const copyBtnId = '#address-actions-box__copy-btn';
      const qrBtnId = '#address-actions-box__qr-btn';
      const ledgerBtnId = '#address-actions-box__ledger-btn';
      const keystoreBtnId = '#address-actions-box__keystore-btn';
      const editBtnId = '#address-actions-box__edit-btn';
      const removeBtnId = '#address-actions-box__remove-btn';
      if (target.closest(copyBtnId)) {
        this.showPopover(copyBtnId, 'Copy Address');
      }
      if (target.closest(qrBtnId)) {
        this.showPopover(qrBtnId, 'Show QR Code');
      }
      if (target.closest(ledgerBtnId)) {
        this.showPopover(ledgerBtnId, 'Verify on Ledger');
      }
      if (target.closest(keystoreBtnId)) {
        this.showPopover(keystoreBtnId, 'Download Keystore');
      }
      if (target.closest(editBtnId)) {
        this.showPopover(editBtnId, 'Edit Wallet');
      }
      if (target.closest(removeBtnId)) {
        this.showPopover(removeBtnId, 'Remove Wallet');
      }

      if (
        !target.closest(copyBtnId) &&
        !target.closest(qrBtnId) &&
        !target.closest(ledgerBtnId) &&
        !target.closest(keystoreBtnId) &&
        !target.closest(editBtnId) &&
        !target.closest(removeBtnId)
      ) {
        this.hidePopover();
      }
    },

    /**
     * Shows popovers for buttons on hover
     * @param {string} _id - id of button that is hovered
     * @param {string} _text - text of the popover
     * @param {string} _position - position of the popover
     * @param {Event: target} _target
     */
    showPopover(id, text, position = 'bottom') {
      if (text) {
        this.popoverAttachId = id;
        this.popoverAttachPosition = position;
        this.popoverText = text;
        this.popoverCreated = true;
      }
    },

    /**
     * Hides popovers for buttons on hover
     */
    hidePopover() {
      this.popoverCreated = false;
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
