<template>
  <div
    class="f-simple-navigation"
    :class="{ 'f-simple-navigation--vertical': vertical }"
  >
    <component :is="navTag">
      <slot>
        <ul
          v-if="cItems"
          class="no-markers"
        >
          <li
            v-for="item in cItems"
            :key="item.id"
            @mouseenter="onLinkHover(item.id, item.linkTitle)"
            @mouseleave="onLinkHover(item.id, item.linkTitle)"
          >
            <router-link
              v-if="!item.outerLink"
              :id="item.id"
              :to="item.url"
              :class="{ disabled: item.disabled, 'router-link-active': item._active }"
              :target="item.blank ? '_blank' : null"
            >
              <icon
                v-if="item.icon"
                :data="item.icon"
                :width="item.iconSize || iconSize"
                :height="item.iconSize || iconSize"
                :fill="item.fill || false"
                :original="item.original || false"
                :class="{ 'icon-fill-color': item.fillColor }"
              />
              <span class="title">{{ item.title }}</span>
            </router-link>
            <a
              v-else
              :id="item.id"
              :href="item.url"
              :class="{ disabled: item.disabled, 'router-link-active': item._active }"
              :target="item.blank ? '_blank' : null"
            >
              <icon
                v-if="item.icon"
                :data="item.icon"
                :width="item.iconSize || iconSize"
                :height="item.iconSize || iconSize"
                :fill="item.fill || false"
                :original="item.original || false"
                :class="{ 'icon-fill-color': item.fillColor }"
              />
              <span class="title">{{ item.title }}</span>
            </a>
          </li>
        </ul>
      </slot>
    </component>
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="`#${popoverAttachId}`"
      attach-position="bottom"
      preferred-attach-position="bottom"
      :attach-margin="[1, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import { getAppNodeParents } from '@/app-structure';
import { cloneObject } from '@/utils';
import FWindow from '../FWindow/FWindow.vue';
import { helpersMixin } from '../../../mixins/helpers';

export default {
  components: { FWindow },

  mixins: [helpersMixin],

  props: {
    /**
     * Array of navigation links.
     *
     * @type {{title: string, url: string}[]}
     */
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    /** Default icon size */
    iconSize: {
      type: String,
      default: '32',
    },
    /** Render <nav> element. */
    renderNav: {
      type: Boolean,
      default: true,
    },
    /** Render navigation as column. */
    vertical: {
      type: Boolean,
      default: false,
    },
    /** Highlight route by application structure. */
    highlightRouteByAppStructure: {
      type: Boolean,
      default: false,
    },

    //* * Whether to show linkTitle as popover or not */
    noPopovers: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dItems: [],
      popoverCreated: false,
      popoverAttachId: '',
      popoverText: '',
    };
  },

  computed: {
    cItems() {
      this.setIds(this.dItems);

      return this.dItems;
    },

    navTag() {
      return this.renderNav ? 'nav' : 'div';
    },

    routeNames() {
      return this.dItems.map((_item) => _item.url.name);
    },
  },

  watch: {
    /**
     * Watches for vue route change.
     */
    $route(_route) {
      this.processRoute(_route);
    },
  },

  mounted() {
    this.dItems = this.items;
    this.processRoute(this.$route);
  },

  methods: {
    /**
     * @param {object} _route
     */
    processRoute(_route) {
      if (!this.highlightRouteByAppStructure) {
        return;
      }

      const { routeNames } = this;
      const appNode = getAppNodeParents(_route.name)
        .find((_item) => routeNames.includes(_item.route));

      if (appNode) {
        const items = cloneObject(this.items);

        items.forEach((_item) => {
          _item._active = _item.url.name === appNode.route;
        });

        this.dItems = items;
      }
    },

    onLinkHover(id, text) {
      if (!this.noPopovers && text) {
        this.popoverAttachId = id;
        this.popoverText = text;
        this.popoverCreated = !this.popoverCreated;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
