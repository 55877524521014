<template>
  <div class="create-account-window">
    <f-window
      ref="win"
      modal
      style="max-width: 720px;"
      class="colored-header create-account-color double-body-padding c-footer"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      @window-hide="onWindowHide"
    >
      <template #title>
        <h2>
          Create a new wallet
          <span class="steps"> {{ dStep }} <span class="count">/ 4</span> </span>
        </h2>
      </template>
      <template #controls>
        <router-link
          to="/"
          class="router-link"
        >
          <icon
            data="@/assets/svg/times.svg"
            width="24"
            height="24"
          />
        </router-link>
      </template>
      <component
        :is="dCurrentComponent"
        v-bind="cCurrentComponentProperties"
        @change-component="onChangeComponent"
      />
    </f-window>
  </div>
</template>

<script>
import FWindow from '@/components/core/FWindow/FWindow.vue';
import CreatePasswordForm from '../../components/forms/CreatePasswordForm.vue';
import MnemonicPhrase from '../../components/MnemonicPhrase/MnemonicPhrase.vue';
import MnemonicPhraseVerification from '../../components/MnemonicPhraseVerification/MnemonicPhraseVerification.vue';
import AccountSuccessMessage from '../../components/AccountSuccessMessage/AccountSuccessMessage.vue';

const DEFAULT_COMPONENT = 'create-password-form';

export default {
  components: {
    AccountSuccessMessage,
    MnemonicPhraseVerification,
    MnemonicPhrase,
    FWindow,
    CreatePasswordForm,
  },

  data() {
    return {
      dCurrentComponent: DEFAULT_COMPONENT,
      dStep: 1,
    };
  },

  computed: {
    cCurrentComponentProperties() {
      switch (this.dCurrentComponent) {
        case 'mnemonic-phrase':
        case 'mnemonic-phrase-verification':
          return {
            account: this._account,
          };
        case 'account-success-message':
          return {
            address: this._address,
          };
        default:
          return null;
      }
    },
  },

  created() {
    this._account = {
      privateKey: '',
      mnemonic: '',
      keystore: null,
    };
    this._address = '';
  },

  mounted() {
    /** Показывать окно только по клику на соответсвующую кнопку */
    if (this.$route.name === 'create-account') {
      this.show();
    }
  },

  methods: {
    /**
     * Delete temporaty properties.
     */
    deleteTmpProps() {
      this.$nextTick(() => {
        this._account = {
          privateKey: '',
          mnemonic: '',
          keystore: null,
        };
        this._address = '';
      });
    },

    setDefaultComponent() {
      this.dCurrentComponent = DEFAULT_COMPONENT;
      this.dStep = 1;
    },

    /**
     * @param {Object} _event
     */
    onChangeComponent(_event) {
      const data = _event.detail;

      if (data.from === 'create-password-form') {
        this._account = data.data.account;
        this.dCurrentComponent = 'mnemonic-phrase';
        this.dStep = 2;
      } else if (data.from === 'mnemonic-phrase') {
        this._account = data.data.account;
        this.dCurrentComponent = 'mnemonic-phrase-verification';
        this.dStep = 3;
      } else if (data.from === 'mnemonic-phrase-verification') {
        this._address = data.data.address;
        this.dCurrentComponent = 'account-success-message';
        this.dStep = 4;
      }

      this.deleteTmpProps();
    },

    show() {
      this.$refs.win.show();
    },

    /**
     * Re-target `'window-hide'` event.
     *
     * @param {object} _data
     */
    onWindowHide(_data) {
      this.setDefaultComponent();

      this.$emit('window-hide', _data);
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
