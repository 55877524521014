<template>
  <div class="view-account-stake account-main-content-mt">
    <!--        <keep-alive>-->
    <component
      :is="currentComponent"
      v-bind="currentComponentProperties"
      @change-component="onChangeComponent"
    ></component>
    <!--        </keep-alive>-->
  </div>
</template>

<script>
import DelegationsInfo from '@/components/DelegationsInfo/DelegationsInfo.vue';
import DelegationLock from '@/components/DelegationLock/DelegationLock.vue';
import DelegationLockConfirmation from '@/components/DelegationLockConfirmation/DelegationLockConfirmation.vue';
import DelegationUnlockConfirmation from '@/components/DelegationUnlockConfirmation/DelegationUnlockConfirmation.vue';
import StakingInfo from '../components/StakingInfo/StakingInfo.vue';
import StakeForm from '../components/forms/StakeForm.vue';
import StakeConfirmation from '../components/StakeConfirmation/StakeConfirmation.vue';
import TransactionSuccessMessage from '../components/TransactionSuccessMessage/TransactionSuccessMessage.vue';
import TransactionRejectMessage from '../components/TransactionRejectMessage/TransactionRejectMessage.vue';
import UnstakeNETS from '../components/UnstakeNETS/UnstakeNETS.vue';
import UnstakeConfirmation from '../components/UnstakeConfirmation/UnstakeConfirmation.vue';
import ClaimRewardsConfirmation from '../components/ClaimRewardsConfirmation/ClaimRewardsConfirmation.vue';
import WithdrawFTMConfirmation from '../components/WithdrawFTMConfirmation/WithdrawFTMConfirmation.vue';
import { eventBusMixin } from '../mixins/event-bus';

const DEFAULT_COMPONENT = 'delegations-info';

export default {
  name: 'AccountStake',

  components: {
    UnstakeNETS,
    TransactionSuccessMessage,
    TransactionRejectMessage,
    StakeConfirmation,
    StakeForm,
    DelegationsInfo,
    StakingInfo,
    UnstakeConfirmation,
    ClaimRewardsConfirmation,
    WithdrawFTMConfirmation,
    DelegationLock,
    DelegationLockConfirmation,
    DelegationUnlockConfirmation,
  },

  mixins: [eventBusMixin],

  data() {
    return {
      currentComponent: DEFAULT_COMPONENT,
    };
  },

  computed: {
    currentComponentProperties() {
      switch (this.currentComponent) {
        case 'stake-confirmation':
          return {
            stakeData: this._data_,
            stakerInfo: this._data_.stakerInfo,
            previousComponent: this._data_.previousComponent,
            stakerId: this._data_.stakerId,
          };
        case 'unstake-n-e-t-s':
        case 'unstake-confirmation':
        case 'delegation-unlock-confirmation':
        case 'claim-rewards-confirmation':
          return {
            accountInfo: this._data_.accountInfo,
            toUnstakeAmount: this._data_.toUnstakeAmount,
            toUnlockAmount: this._data_.toUnlockAmount,
            stakerId: this._data_.stakerId,
            fromDelegationList: this._data_.fromDelegationList,
            tmpPwdCode: this._data_.tmpPwdCode,
          };
        case 'transaction-success-message':
          return {
            tx: this._data_.tx,
            title: this._data_.successMessage,
            continueTo: this._data_.continueTo,
            continueToParams: this._data_.continueToParams,
            stakerId: this._data_.stakerId,
          };
        case 'withdraw-f-t-m-confirmation':
        case 'stake-form':
        case 'staking-info':
        case 'delegation-lock':
        case 'delegation-lock-confirmation':
          return this._data_;
        default:
          return null;
      }
    },
  },

  created() {
    // temporary data
    this._data_ = null;

    this._eventBus.on('account-picked', this.onAccountPicked);
  },

  methods: {
    /**
     * @param {Object} _data
     */
    onChangeComponent(_data) {
      this._data_ = _data.data;
      this.currentComponent = _data.to;

      this.$nextTick(() => {
        this._data_ = null;
      });
    },

    onAccountPicked() {
      this.currentComponent = '';
      this.$nextTick(() => {
        this.currentComponent = DEFAULT_COMPONENT;
      });
    },
  },
};
</script>
