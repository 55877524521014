import './defi.types';
import web3utils from 'web3-utils';
import fetchData from '@/api';
import defiSettingsQuery from '@/api/queries/defi/defiSettings';
import defiTokensByAddressQuery from '@/api/queries/defi/defiTokensByAddress';
import defiTokensWithoutAddressQuery from '@/api/queries/defi/defiTokensWithoutAddress';
import erc20TokenListByAddressQuery from '@/api/queries/erc20/erc20TokenListByAddress';
import erc20TokenListWithoutAddressQuery from '@/api/queries/erc20/erc20TokenListWithoutAddress';
import erc20AssetsByAddressQuery from '@/api/queries/erc20/erc20AssetsByAddress';
import erc20TokenBalanceQuery from '@/api/queries/erc20/erc20TokenBalance';
import defiNativeTokenByAddressQuery from '@/api/queries/defi/nativeTokenByAddress';
import defiNativeTokenWithoutAddressQuery from '@/api/queries/defi/nativeTokenWithoutAddress';
import { cloneObject, isObjectEmpty } from '../../utils';

/** @type {BNBridgeExchange} */
export let defi = null;

// TMP!!
const filterTokens = [];

/**
 * Plugin for various DeFi requests and calculations.
 */
export class DeFi {
  /**
   * @param {Vue} _Vue
   * @param {{apolloClient: ApolloClient}} _options
   */
  static install(_Vue, _options) {
    if (!defi) {
      defi = new DeFi(_options);
      _Vue.prototype.$defi = defi;
    }
  }

  /**
   * @param {{apolloClient: ApolloClient}} _options
   */
  constructor(_options) {
    this.apolloClient = _options.apolloClient;
    /** DeFi settings was loaded. */
    this.settingsLoaded = false;
    /** @type {DefiToken[]} */
    this.tokens = [];
    /** @type {DefiToken} */
    this.fusdToken = {};
    /** @type {DefiToken} */
    this.ftmToken = {};
    /** Keys are token symbols, values are number of decimals. */
    this.tokenDecimals = {};
    /** Addresses of various contracts. */
    this.contracts = {
      StakeTokenizerContract: '',
    };
  }

  /**
   * Load settings if it's necessary.
   *
   * @return {Promise}
   */
  async init() {
    if (!this.settingsLoaded) {
      this.settingsLoaded = true;
      // this.initProperties(await this.fetchSettings());
    }
  }

  /**
   * Set properties.
   *
   * @param {DefiSettings} _settings
   */
  initProperties(_settings) {
    const { contracts } = this;
    contracts.StakeTokenizerContract = _settings.StakeTokenizerContract;
  }

  /**
   * @param {DefiToken[]} _tokens
   * @private
   */
  _setTokens(_tokens) {
    this.tokens = _tokens;
    this.fusdToken = _tokens.find((_item) => _item.symbol === 'FUSD');
    this.ftmToken = _tokens.find((_item) => _item.symbol === 'FTM');

    /*
        if (isObjectEmpty(this.tokenDecimals)) {
            this.tokens.forEach((_token) => {
                this._setTokenDecimals(_token);
            });
        }
        */
  }

  /**
   * @param {DefiToken} _token
   * @private
   */
  _setTokenDecimals(_token) {
    const tokenPrice = this.getTokenPrice(_token);
    let decimals = 0;

    if (tokenPrice === 0) {
      decimals = 6;
    } else if (tokenPrice < 0.5) {
      decimals = 1;
    } else if (tokenPrice < 100) {
      decimals = 2;
    } else if (tokenPrice < 1000) {
      decimals = 5;
    } else {
      decimals = 6;
    }

    this.tokenDecimals[_token.symbol] = decimals;
  }

  /**
   * @param {DefiToken} _token
   * @param {number} _default
   * @return {number}
   */
  getTokenDecimals(_token, _default = 6) {
    const tokenPrice = this.getTokenPrice(_token);
    let decimals = _default;

    if (tokenPrice < 0.5 && tokenPrice > 0) {
      decimals = 1;
    } else if (tokenPrice < 100) {
      decimals = 2;
    } else if (tokenPrice < 1000) {
      decimals = 5;
    }

    return decimals;
    // return this.tokenDecimals[_token.symbol] || _default;
  }

  /**
   * @param {DefiToken} _token
   * @return {string}
   */
  getTokenSymbol(_token) {
    return _token && _token.symbol;
    // ? _token.symbol !== 'FTM'
    //     ? lowercaseFirstChar(_token.symbol)
    //     : _token.symbol
    // : '';
  }

  /**
   * @param {DefiToken} _token
   * @return {number}
   */
  getTokenPrice(_token) {
    return _token && 'price' in _token ? this.fromTokenValue(_token.price, _token, true) : 0;
  }

  async getCBRBalance(address) {
    const CBR = await this.fetchTokens(address, 'CBR');
    return this.fromTokenValue(CBR.availableBalance, CBR);
  }

  /**
   * Convert given value from token decimals space.
   *
   * @param {string} _value Hex value.
   * @param {DefiToken} _token
   * @param {boolean} [_isPrice]
   */
  fromTokenValue(_value, _token, _isPrice = false) {
    let value = 0;

    if (_value !== undefined && !isNaN(_value)) {
      value = parseFloat(this.shiftDecPointLeft(_value, _isPrice ? _token.priceDecimals : _token.decimals));
    }

    return value;
  }

  /**
   * Convert given value to token decimals space.
   *
   * @param {string} _value
   * @param {DefiToken} _token
   * @param {boolean} [_isPrice]
   * @return {string}
   */
  toTokenValue(_value, _token, _isPrice = false) {
    let value = 0;

    if (_value !== undefined && !isNaN(_value)) {
      value = this.shiftDecPointRight(_value.toString(), _isPrice ? _token.priceDecimals : _token.decimals);
    }

    return value;
  }

  /**
   * @param {string} _value
   * @param {number} _dec Number of decimals.
   * @return {string}
   */
  shiftDecPointLeft(_value, _dec = 0) {
    // const value = web3utils.toBN(_value).toString(10);
    const value = web3utils.toBN(this.removeSN(_value, _dec))
      .toString(10);
    const idx = value.length - _dec;

    if (idx < 0) {
      return `0.${web3utils.padLeft(value, _dec, '0')}`;
    }
    return `${value.slice(0, idx)}.${value.slice(idx)}`;
  }

  /**
   * @param {string} _value
   * @param {number} _dec Number of decimals.
   * @param {boolean} [_float] Don't remove decimals.
   * @return {string}
   */
  shiftDecPointRight(_value, _dec = 0, _float = false) {
    const value = this.removeSN(_value.toString(), _dec);
    let idx = value.indexOf('.');
    let left;
    let right;
    let res = '';
    const isHex = value.indexOf('0x') === 0;

    if (idx > -1) {
      left = value.slice(0, idx);
      right = value.slice(idx + 1);

      if (_dec < right.length) {
        res = `${left + right.slice(0, _dec)}.${right.slice(_dec)}`;
      } else if (_dec === right.length) {
        res = left + right;
      } else {
        res = left + web3utils.padRight(right, _dec, '0');
      }
    } else {
      res = value + web3utils.padRight('', _dec, '0');
    }

    // remove leading zeros
    while (res.length > 0 && res.charAt(0) === '0') {
      res = res.slice(1);
    }

    if (!_float) {
      idx = res.indexOf('.');
      if (idx > -1) {
        res = res.slice(0, idx);
      }

      if (!res) {
        res = '0';
      }
    }

    if (isHex && res.charAt(0) === 'x') {
      res = `0${res}`;
    }

    return res;
  }

  /**
   * @param {string} _value
   * @param {number} _decimals
   * @return {string}
   */
  shiftDecPoint(_value, _decimals) {
    const value = _value.toString();

    if (_decimals === 0) {
      return value;
    }
    if (_decimals < 0) {
      return this.shiftDecPointLeft(value, -_decimals);
    }
    return this.shiftDecPointRight(value, _decimals);
  }

  /**
   * Remove scientific notation.
   *
   * @param {string|number} _value
   * @param {number} _dec
   * @return {string|*}
   */
  removeSN(_value, _dec) {
    const value = typeof _value !== 'string' ? _value.toString() : _value;

    if (value.indexOf('0x') === -1 && (value.indexOf('e') > -1 || value.indexOf('E') > -1)) {
      return parseFloat(value)
        .toFixed(_dec);
    }

    return _value;
  }

  /**
   * Value and result value are both in "WEI".
   *
   * @param {string|number} _value Value in `_token` decimal space.
   * @param {DefiToken} _token
   * @param {DefiToken} _toToken
   * @return {string}
   */
  convertTokenValueWEI(_value, _token, _toToken) {
    if (isObjectEmpty(_token) || isObjectEmpty(_toToken)) {
      return '';
    }

    const value = web3utils.toBN(_value);
    const tokenPrice = web3utils.toBN(_token.price);
    const toTokenPrice = web3utils.toBN(_toToken.price);
    const result = value.mul(tokenPrice)
      .div(toTokenPrice)
      .toString(10);
    const resultDecimals = _toToken.decimals - (_token.decimals + _token.priceDecimals - _toToken.priceDecimals);

    return this.shiftDecPoint(result, resultDecimals);
  }

  /**
   * Value and result value are converted from "WEI".
   *
   * @param {string|number} _value Value in `_token` decimal space.
   * @param {DefiToken} _token
   * @param {DefiToken} _toToken
   * @return {string}
   */
  convertTokenValue(_value, _token, _toToken) {
    return this.fromTokenValue(
      this.convertTokenValueWEI(this.toTokenValue(_value, _token), _token, _toToken),
      _toToken,
    );
  }

  /**
   * Compare big numbers, hex.
   *
   * @param {string} _a
   * @param {string} _b
   * @return {-1 | 0 | 1} -1 (_a < _b), 0 (_a == _b), or 1 (_a > _b)
   */
  compareBN(_a, _b) {
    const hex1 = web3utils.toBN(_a);
    const hex2 = web3utils.toBN(_b);

    return hex1.cmp(hex2);
  }

  /**
   * @param {DefiToken} _token
   * @return {boolean}
   */
  canTokenBeTraded(_token) {
    // return _token && _token.isActive && _token.canTrade;
    return _token && _token.isActive && (_token.canTrade || _token.symbol === 'FTM');
    // return _token && _token.isActive && (_token.canTrade || _token.symbol === 'FUSD');
  }

  /**
   * @param {DefiToken} _token
   * @return {boolean}
   */
  filterTokensBySymbol(_token) {
    return _token && filterTokens.indexOf(_token.symbol) > -1;
  }

  /**
   * @return {Promise<DefiSettings>}
   */
  async fetchSettings() {
    const data = await fetchData(defiSettingsQuery);
    return data.defiConfiguration || {};
  }

  /**
   * @param {string} _ownerAddress
   * @param {string|array} [_symbol]
   * @return {Promise<DefiToken[]>}
   */
  async fetchTokens(_ownerAddress, _symbol) {
    const data = _ownerAddress ? await fetchData(
      defiTokensByAddressQuery,
      {
        owner: _ownerAddress,
      },
    )
      : await fetchData(defiTokensWithoutAddressQuery);

    let defiTokens = data.defiTokens || [];
    if (filterTokens.length > 0) {
      defiTokens = defiTokens.filter(this.filterTokensBySymbol);
    }

    let tokens = [];

    this._setTokens(defiTokens);

    if (_symbol) {
      if (typeof _symbol === 'string') {
        tokens = defiTokens.find((_item) => _item.symbol === _symbol);
      } else if (_symbol.length) {
        tokens = defiTokens.filter(
          (_item) => _symbol.indexOf(_item.symbol) > -1,
        );
      }
    } else {
      tokens = defiTokens;
    }

    return tokens;
  }

  /**
   * @param {string} [_ownerAddress]
   * @param {string|array} [_symbol]
   * @return {Promise<ERC20Token[]>}
   */
  async fetchERC20Tokens(_ownerAddress, _symbol) {
    const data = _ownerAddress ? await fetchData(erc20TokenListByAddressQuery, {
      owner: _ownerAddress,
    }) : await fetchData(erc20TokenListWithoutAddressQuery);
    let erc20TokenList = data.erc20TokenList || [];
    if (filterTokens.length > 0) {
      erc20TokenList = erc20TokenList.filter(this.filterTokensBySymbol);
    }

    let tokens = [];

    this._setTokens(erc20TokenList);

    if (_symbol) {
      if (typeof _symbol === 'string') {
        tokens = erc20TokenList.find((_item) => _item.symbol === _symbol);
      } else if (_symbol.length) {
        tokens = erc20TokenList.filter(
          (_item) => _symbol.indexOf(_item.symbol) > -1,
        );
      }
    } else {
      tokens = erc20TokenList;
    }

    return tokens;
  }

  /**
   * @param {string} _ownerAddress
   * @return {Promise<ERC20Token[]>}
   */
  async fetchERC20TokensAvailableBalances(_ownerAddress) {
    const data = await fetchData(erc20TokenListByAddressQuery, {
      owner: _ownerAddress,
    });
    return data.erc20TokenList || [];
  }

  /**
   * @param {string} _ownerAddress
   * @param {string} _tokenAddress
   * @return {Promise<string>} - number in hex, e.g '0x4'
   */
  async fetchERC20TokenAvailableBalance(_ownerAddress, _tokenAddress) {
    const data = await fetchData(erc20TokenBalanceQuery, {
      owner: _ownerAddress,
      token: _tokenAddress,
    });
    return data.ercTokenBalance || '0x0';
  }

  /**
   * @param {string} _ownerAddress
   * @param {ERC20Token[]} _tokens
   */
  async getERC20TokensWithAvailableBalances(_ownerAddress, _tokens) {
    const tokenBalances = await this.fetchERC20TokensAvailableBalances(_ownerAddress);
    const tokens = cloneObject(_tokens);

    if (tokenBalances) {
      tokenBalances.forEach((_token) => {
        const token = tokens.find((_t) => _t.address === _token.address);

        if (token) {
          token.balanceOf = _token.balanceOf;
        }
      });
    }

    return tokens;
  }

  /**
   * @param {string} [_ownerAddress]
   * @return {Promise<ERC20Token[]>}
   */
  async fetchERC20Assets(_ownerAddress) {
    const data = await fetchData(erc20AssetsByAddressQuery, {
      owner: _ownerAddress,
    });
    return data.erc20Assets || [];
  }

  // ? Возможно, для работы с ERC токенами. Используется только в 1 месте, здесь.
  /**
   * @param {string} _address
   * @return {Promise<void>}
   */
  async fetchNativeToken(_address) {
    const data = _address
      ? await fetchData(defiNativeTokenByAddressQuery, {
        owner: _address,
      })
      : await fetchData(defiNativeTokenWithoutAddressQuery);

    return data.defiNativeToken || {};
  }
}
