<template>
  <div
    class="address-info-box"
    :class="{ 'one-account': accountsLen === 1 }"
  >
    <div class="align-items-center collapse-md address-info-box__content">
      <div class="align-center-md address-info-box__address-col">
        <account-name
          v-if="accountsLen === 1"
          :account="currentAccount"
          class="address one-account"
          hide-address
        />
        <button
          v-else
          id="address-info-box__pick-account-btn"
          class="no-style pick-account-btn"
          @click="onPickAccountBtn"
          @mouseenter="onPickAccountBtnHover"
          @mouseleave="onPickAccountBtnHover"
        >
          <account-name
            :account="currentAccount"
            class="address"
            hide-address
          />
        </button>
        <button
          v-if="mobileView"
          id="address-more-actions-btn"
          class="btn light same-size round"
          title="More Actions"
          @click="$refs.addressActionsPopover.show()"
        >
          <icon
            data="@/assets/svg/ellipsis-v.svg"
            width="24"
            height="24"
            aria-hidden="true"
          />
        </button>
      </div>
      <div
        v-if="!mobileView"
        class="align-right align-center-md"
      >
        <address-actions-box/>
      </div>
    </div>

    <account-picker-window ref="accountPickerWindow"/>

    <f-window
      v-if="mobileView"
      ref="addressActionsPopover"
      popover
      no-title
      hide-on-window-mousedown
      attach-to="#address-more-actions-btn"
      attach-position="auto"
      preferred-attach-position="bottom"
      :attach-margin="[4, 4, 4, 4]"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      class="light popover-menu"
      style="width: auto; max-width: 100%;"
    >
      <address-actions-box
        vertical-mode
        @window-hide="onWindowHide"
      />
    </f-window>
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="popoverAttachId"
      :attach-position="popoverAttachPosition"
      :preferred-attach-position="popoverAttachPosition"
      :attach-margin="[4, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddressActionsBox from '../AddressActionsBox/AddressActionsBox.vue';
import { formatHash } from '../../filters';
import AccountPickerWindow from '../windows/AccountPickerWindow/AccountPickerWindow.vue';
import AccountName from '../AccountName/AccountName.vue';
import FWindow from '../core/FWindow/FWindow.vue';

export default {
  components: {
    FWindow,
    AccountName,
    AccountPickerWindow,
    AddressActionsBox,
  },

  data() {
    return {
      popoverCreated: false,
      popoverAttachId: '#address-info-box__pick-account-btn',
      popoverAttachPosition: 'bottom',
      popoverText: 'Select wallet',
    };
  },
  computed: {
    ...mapGetters(['currentAccountAddress', 'currentAccount', 'accounts']),

    /**
     * Property is set to `true`, if 'menu-mobile' breakpoint is reached.
     *
     * @return {Boolean}
     */
    mobileView() {
      const menuMobileBreakpoint = this.$store.state.breakpoints['menu-mobile'];

      return menuMobileBreakpoint && menuMobileBreakpoint.matches;
    },

    /**
     * @return {number}
     */
    accountsLen() {
      return this.accounts.length;
    },
  },

  methods: {
    onPickAccountBtn() {
      if (this.accountsLen > 1) {
        this.$refs.accountPickerWindow.show();
      }
    },

    onWindowHide() {
      this.$refs.addressActionsPopover.hide();
    },

    onPickAccountBtnHover() {
      this.popoverCreated = !this.popoverCreated;
    },

    formatHash,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
