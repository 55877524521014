const appConfig = {
  // app title
  name: 'Netsbo Wallet',
  // app description
  description: 'Netsbo Wallet',
  // app keywords
  keywords: 'netsbo, metamask',
  // apollo client settings
  apollo: {
    // list of providers. if one of them is unavailable, another is randomly picked
    providers: [
      /* {
                http: 'https://xapi3.fantom.network/api',
                // for subscriptions
                ws: '',
            }, */
      {
        http: 'https://xapi4.fantom.network/api',
        // for subscriptions
        ws: '',
      },
      /* {
                http: 'https://xapi5.fantom.network/api',
                // for subscriptions
                ws: '',
            }, */
      {
        http: 'https://xapi6.fantom.network/api',
        // for subscriptions
        ws: '',
      },
      {
        http: 'https://xapi7.fantom.network/api',
        // for subscriptions
        ws: '',
      },
      {
        http: 'https://xapi8.fantom.network/api',
        // for subscriptions
        ws: '',
      },
      {
        http: 'https://xapi9.fantom.network/api',
        // for subscriptions
        ws: '',
      },
    ],
    // index into providers array of default provider or 'random' - takes index randomly
    defaultProviderIndex: 'random',
  },
  // Opera chain id
  chainId: '0xfa',
  // JSON-RPC endpoint
  rpc: 'https://rpcapi.fantom.network/',
  cbrSwapContract: '',
  cbrContract: '',
  // SFC_CONTRACT_ADDRESS is the address on which the SFC smart contract is deployed.
  sfcContractAddress: process.env.VUE_APP_SFC_CONTRACT_ADDRESS,
  wNETSPrice: '0x15284c6bdbef0800',
  // used in links pointing to fantom explorer
  explorerUrl: 'https://ftmscan.com/',
  // used in links pointing to validators
  explorerUrl2: 'https://explorer.goldway.ws/',
  // used in links pointing to fantom explorer's transaction detail
  explorerTransactionPath: 'tx',
  // used in links pointing to ethereum explorer
  ethereumExplorerUrl: 'https://etherscan.io/',
  // used in links pointing to binance explorer
  binanceExplorerUrl: 'https://explorer.binance.org/',
  // BNBridge api config
  bnbridgeApi: {
    // url of api point
    url: 'https://api.bnbridge.exchange/api/v1',
    // auth token
    token: '',
    // use Ethereum chain
    useETH: true,
    // use Binance chain
    useBNB: true,
    // minimal amount of FTM to ETH transfer
    minFTMToTransfer: 50,
  },
  disableFLend: true,
  // testnet is SFC, localTestnet is Testnet
  useTestnet: process.env.VUE_APP_USE_TESTNET === 'true',
  useLocalTestnet: process.env.VUE_APP_USE_LOCAL_TESTNET === 'true',
  // testnet config
  testnet: {
    // list of providers. if one of them is unavailable, another is randomly picked
    providers: [
      {
        http: `${process.env.VUE_APP_TESTNET_GRAPHQL_URL}api/graphql`,
        // for subscriptions
        ws: '',
      },
    ],
    // JSON-RPC endpoint
    rpc: process.env.VUE_APP_TESTNET_RPC,
    cbrSwapContract: process.env.VUE_APP_TESTNET_CBR_SWAP_CONTRACT,
    cbrContract: process.env.VUE_APP_TESTNET_CBR_CONTRACT,
    // * адрес локального поднятого graphql
    // * localRpc: 'http://localhost:15113/graphql',
    // used in links pointing to fantom explorer
    explorerUrl: process.env.VUE_APP_TESTNET_EXPLORER_URL,
    // used in links pointing to validators
    explorerUrl2: process.env.VUE_APP_TESTNET_EXPLORER_URL_2,
    // used in links pointing to netsbo explorer's transaction detail
    explorerTransactionPath: process.env.VUE_APP_TESTNET_EXPLORER_TRANSACTION_PATH,
    // chain id for testnet
    chainId: `0x${parseInt(process.env.VUE_APP_TESTNET_CHAIN_ID)
      .toString(16)}`,
  },
  // local testnet - поднятая разработчиками данного проекта сеть
  // на нодах заказчика для тестирования перед влитием на прод
  localTestnet: {
    // list of providers. if one of them is unavailable, another is randomly picked
    providers: [
      {
        http: `${process.env.VUE_APP_LOCAL_TESTNET_GRAPHQL_URL}api`,
        // for subscriptions
        ws: '',
      },
    ],
    // JSON-RPC endpoint
    rpc: process.env.VUE_APP_LOCAL_TESTNET_RPC,
    cbrSwapContract: process.env.VUE_APP_LOCAL_TESTNET_CBR_SWAP_CONTRACT,
    cbrContract: process.env.VUE_APP_LOCAL_TESTNET_CBR_CONTRACT,
    // used in links pointing to fantom explorer
    explorerUrl: process.env.VUE_APP_LOCAL_TESTNET_EXPLORER_URL,
    // used in links pointing to validators
    explorerUrl2: process.env.VUE_APP_LOCAL_TESTNET_EXPLORER_URL_2,
    // used in links pointing to fantom explorer's transaction detail
    explorerTransactionPath: process.env.VUE_APP_LCOAL_TESTNET_EXPLORER_TRANSACTION_PATH,
    // chain id for testnet
    chainId: `0x${parseInt(process.env.VUE_APP_LOCAL_TESTNET_CHAIN_ID)
      .toString(16)}`,
  },
  // progressive web application
  usePWA: true,
  // pwa settings
  pwa: {
    // name used in pwa manifest
    name: 'Netsbo Wallet',
    categories: ['finance'],
  },
  // determines if app is chrome extension
  isChromeExtension: !!process.env.VUE_APP_IS_CHROME_EXTENSION,
  // chrome extension settings
  chromeExtension: {
    // chrome extension version - increase version number, if you want to publish in the chrome web store
    version: '0.0.1',
    // chrome extension name
    name: 'Netsbo Wallet',
    // chrome extension description
    description: 'Netsbo Wallet',
    // output directory for application
    outputDir: 'chrome-extension/dist',
    // output directory for application (relative to outputDir)
    outputDirApp: 'app',
    // output directory for background js bundle script (relative to outputDir)
    outputDirBackgroundJs: 'background-js',
    // show extension prompts in new tab, instead of popup window
    tabNotPopup: false,
  },
  // default options for production build
  build: {
    // output dir for production build
    outputDir: 'dist',
  },
  // app settings
  settings: {
    // list of available currencies displayed in settings view ( https://en.wikipedia.org/wiki/ISO_4217 )
    currencies: ['USD', 'EUR'],
    // success transaction message will automatically continue to the next step after this number of ms
    autoContinueToAfter: 2000,
  },
};

appConfig.mainnet = {
  chainId: appConfig.chainId,
  rpc: appConfig.rpc,
  cbrSwapContract: appConfig.cbrSwapContract,
  explorerUrl: appConfig.explorerUrl,
  explorerUrl2: appConfig.explorerUrl2,
};

if (appConfig.useTestnet) {
  appConfig.apollo.providers = appConfig.testnet.providers;
  appConfig.explorerUrl = appConfig.testnet.explorerUrl;
  appConfig.explorerUrl2 = appConfig.testnet.explorerUrl2;
  appConfig.explorerTransactionPath = appConfig.testnet.explorerTransactionPath;
  appConfig.rpc = appConfig.testnet.rpc;
  appConfig.cbrSwapContract = appConfig.testnet.cbrSwapContract;
  appConfig.cbrContract = appConfig.testnet.cbrContract;
  appConfig.chainId = appConfig.testnet.chainId;
}

if (appConfig.useLocalTestnet) {
  appConfig.apollo.providers = appConfig.localTestnet.providers;
  appConfig.explorerUrl = appConfig.localTestnet.explorerUrl;
  appConfig.explorerUrl2 = appConfig.localTestnet.explorerUrl2;
  appConfig.explorerTransactionPath = appConfig.localTestnet.explorerTransactionPath;
  appConfig.rpc = appConfig.localTestnet.rpc;
  appConfig.cbrSwapContract = appConfig.localTestnet.cbrSwapContract;
  appConfig.cbrContract = appConfig.localTestnet.cbrContract;
  appConfig.chainId = appConfig.localTestnet.chainId;
}

//
if (appConfig.isChromeExtension) {
  appConfig.usePWA = false;

  appConfig.build = {
    ...{
      // output dir for production build
      outputDir: `${appConfig.chromeExtension.outputDir}/${appConfig.chromeExtension.outputDirApp}`,
    },
  };
}

// scss variables prepended to every scss file
appConfig.scssData = `
    // @import "src/assets/scss/vars";

    $IS_CHROME_EXTENSION: ${appConfig.isChromeExtension ? 'true' : 'false'};
`;

module.exports = appConfig;
