<template>
  <div class="view-defi-ftrade">
    <h1 class="with-back-btn"> CBR</h1>
    <div class="grid">
      <div class="from-col">
        <div class="defi-label">From</div>
        <f-placeholder
          :content-loaded="!!fromToken.symbol"
          block
          :replacement-num-chars="20"
          class="cbr-defi-fplaceholder"
        >
          <div class="cbr-sb-content">
            <f-crypto-symbol :token="fromToken"/>
            <span>
              <f-token-value
                :token="fromToken"
                :value="fromTokenBalance"
                :use-placeholder="false"
                no-currency
              />
            </span>
          </div>
        </f-placeholder>
        <div class="defi-price-input">
          <div
            ref="fromSign"
            class="sign"
          >
            -
          </div>
          <f-auto-resize-input
            ref="fromARInput"
            min-width="48px"
          >
            <input
              :id="`text-input-${id}`"
              ref="fromInput"
              :value="fromInputValue === 0 ? '' : fromInputValue"
              type="text"
              inputmode="decimal"
              placeholder="0"
              :step="perPrice.toString()"
              min="0"
              :max="maxFromInputValue"
              class="text-input no-style"
              @change="onFromInputChange"
              @input="onFromInputInput"
              @keydown="onInputKeydown"
            />
          </f-auto-resize-input>
        </div>
      </div>
      <div class="swap-col">
        <div class="one-way-swap-icon-wrapper same-size">
          <icon
            data="@/assets/svg/defi/one-way-swap.svg"
            width="20"
            height="20"
            aria-hidden="true"
            class="one-way-swap-icon"
          />
        </div>
      </div>
      <div class="to-col">
        <div class="defi-label">To</div>
        <f-placeholder
          :content-loaded="!!toToken.symbol"
          block
          :replacement-num-chars="20"
          class="cbr-defi-fplaceholder"
        >
          <div class="cbr-sb-content">
            <f-crypto-symbol :token="toToken"/>
            <span>
              <f-token-value
                :token="toToken"
                :value="toTokenBalance"
                :use-placeholder="false"
                no-currency
              />
            </span>
          </div>
        </f-placeholder>
        <div class="defi-price-input">
          <div
            ref="toSign"
            class="sign"
          >
            +
          </div>
          <f-auto-resize-input
            ref="toARInput"
            min-width="48px"
          >
            <input
              :id="`text-input-${id}-2`"
              ref="toInput"
              :value="toInputValue === 0 ? '' : toInputValue"
              type="text"
              inputmode="decimal"
              placeholder="0"
              step="any"
              min="0"
              :max="maxToInputValue"
              class="text-input no-style"
              @change="onToInputChange"
              @input="onToInputInput"
              @keydown="onInputKeydown"
            />
          </f-auto-resize-input>
        </div>
      </div>
      <div class="swap-cont">
        <!--
        <div>
            <div class="defi-label">Current rate</div>
            <div class="value">{{ toTokenPrice }}</div>
        </div>
-->
        <!-- иконка обмена для адаптива -->
        <div class="swap-col">
          <div class="one-way-swap-icon-wrapper same-size">
            <icon
              data="@/assets/svg/defi/one-way-swap.svg"
              width="20"
              height="20"
              dir="right"
              aria-hidden="true"
              class="one-way-swap-icon"
            />
          </div>
        </div>
        <!--
        <div class="align-right">
            <div class="defi-label">Today's change</div>
            <div class="value">2.38%</div>
        </div>
-->
      </div>
    </div>

    <div class="se-cont">
      <div class="f-slider-wrap">
        <f-slider
          ref="slider"
          v-model="currFromValue"
          :step="perPrice.toString()"
          min="0"
          :max="maxFromInputValue.toString()"
          :labels="sliderLabels"
          clickable-labels
          use-lower-fill-bar
        >
          <template #top="sProps">
            <label
              :for="sProps.inputId"
              class="not-visible"
            >slider label</label>
          </template>
        </f-slider>
      </div>

      <div class="exchange-price">
        <div class="defi-label">Price</div>
        <div class="value">
          <f-placeholder
            :content-loaded="!!perPriceText"
            replacement-text="000.00 NETS per CBR"
          >
            {{ perPriceText }}
          </f-placeholder>
        </div>
      </div>
    </div>

    <div class="defi-buttons">
      <button
        class="btn large btn-dark"
        :disabled="submitDisabled"
        @click="onSubmit"
      >
        Submit
        <!--Trade now-->
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters, mapState } from 'vuex';
import erc20Utils from 'fantom-ledgerjs/src/erc20-utils';
import FTokenValue from '@/components/core/FTokenValue/FTokenValue.vue';
import FAutoResizeInput from '@/components/core/FAutoResizeInput/FAutoResizeInput.vue';
import FPlaceholder from '@/components/core/FPlaceholder/FPlaceholder.vue';
import { setTokensURL } from '@/utils/defi-tokens';
import FCryptoSymbol from '../../components/core/FCryptoSymbol/FCryptoSymbol.vue';
import { getUniqueId } from '../../utils';
import { eventBusMixin } from '../../mixins/event-bus';
import FSlider from '../../components/core/FSlider/FSlider.vue';
import { formatNumberByLocale } from '../../filters';
import appConfig from '../../../app.config';

export default {
  name: 'DefiFTradeCBR',

  components: {
    FPlaceholder,
    FAutoResizeInput,
    FTokenValue,
    FSlider,
    FCryptoSymbol,
  },

  mixins: [eventBusMixin],

  data() {
    return {
      fromValue: 0,
      currFromValue: '0',
      // * amount of NETS for 1 CBR
      perPrice: 0,
      // * displayed in markup text about price
      perPriceText: '',
      /** @type {DefiToken} */
      fromToken: {},
      toValue: 0,
      /** @type {DefiToken} */
      toToken: {},
      /** @type {DefiToken[]} */
      tokens: [],
      sliderLabels: ['0%', '25%', '50%', '75%', '100%'],
      id: getUniqueId(),
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    ...mapState(['breakpoints']),

    /**
     * @return {{fromToken: DefiToken, toToken: DefiToken}}
     */
    params() {
      const { $route } = this;

      return $route && $route.params ? $route.params : {};
    },

    fromInputValue() {
      return this.formatFromInputValue(this.fromValue);
    },

    toInputValue() {
      return this.formatToInputValue(this.toValue);
    },

    fromTokenBalance() {
      const { fromToken } = this;
      let balance = this.$defi.fromTokenValue(fromToken.availableBalance, fromToken);

      if (balance < 0) {
        balance = 0;
      }

      return balance;
    },

    toTokenBalance() {
      return this.$defi.fromTokenValue(this.toToken.availableBalance, this.toToken);
    },

    // * пока что не используется
    toTokenPrice() {
      return formatNumberByLocale(this.$defi.getTokenPrice(this.toToken), 5, 'USD');
    },

    maxFromInputValue() {
      let max = 0;

      max = this.fromTokenBalance;

      if (max < 0) {
        max = 0;
      }

      return max;
    },

    /**
     * Max CBR you can get - calculating from your NETS balmce
     *
     */
    maxToInputValue() {
      return Math.floor(this.fromTokenBalance / this.perPrice);
    },

    submitDisabled() {
      return this.correctFromInputValue(this.fromValue) === 0;
    },
  },

  watch: {
    currFromValue(_value, _oldValue) {
      if (_value !== _oldValue) {
        this.fromValue = parseFloat(_value);
        this.setPerPrice();
        this.updateSigns();
      }
    },

    fromValue(_value, _oldValue) {
      if (_value !== _oldValue) {
        this.updateFromValue(_value);
      }
    },

    breakpoints() {
      const { $refs } = this;

      $refs.fromARInput.update();
      $refs.toARInput.update();
    },
  },

  created() {
    this.init();

    this._eventBus.on('account-picked', this.onAccountPicked);
  },

  mounted() {
    this.$refs.fromSign.style.visibility = 'hidden';
    this.$refs.toSign.style.visibility = 'hidden';
  },

  methods: {
    async init() {
      const { params } = this;
      const result = await this.$defi.fetchTokens(this.currentAccount.address);

      const CBR = result.filter((_token) => _token && _token.symbol === 'CBR')[0];

      const account = await this.$fWallet.getBalance(this.currentAccount.address, false, true);
      const netsToken = {
        // * address is not required for nets - cbr swap
        address: '',
        symbol: 'NETS',
        name: 'Netsbo',
        isActive: true,
        decimals: 18,
        price: CBR.price,
        priceDecimals: CBR.priceDecimals,
        availableBalance: account.balance,
        allowance: '0x0',
      };
      this.$defi._setTokenDecimals(netsToken);

      // add NETS
      result.push(netsToken);

      this.tokens = result.filter((_token) => _token && (_token.symbol === 'NETS' || _token.symbol === 'CBR'));
      setTokensURL(this.tokens);

      if (params.fromToken && params.toToken) {
        this.fromToken = this.tokens.find((_item) => _item.symbol === params.fromToken.symbol);
        this.toToken = this.tokens.find((_item) => _item.symbol === params.toToken.symbol);
      } else if (this.tokens.length >= 2) {
        this.fromToken = netsToken;
        this.toToken = CBR;
      }

      this.setPerPrice();
    },

    // * Updates FromValue in watch
    updateFromValue: debounce(async function (_value) {
      this.toValue = await this.convertFrom2To(_value);
      this.currFromValue = _value.toString();
      this.setPerPrice();
    }, 50),

    /**
     * Amount of CBR in right input - only integers are allowed
     *
     * @param {number} _value - typed number from the input
     */
    formatToInputValue(_value) {
      this.resizeToARInput();

      return _value !== 0 ? Math.floor(_value) : 0;
    },

    /**
     * Amount of NETS in left input
     *
     * @param {number} _value - typed number from the input
     */
    formatFromInputValue(_value) {
      this.resizeFromARInput();
      // * after calculations - toFixed(2) (ex: 10.00)
      return _value !== 0 ? _value.toFixed(this.$defi.getTokenDecimals(this.fromToken)) : _value;
    },

    /**
     * Corected amount of NETS in left input - can't type < 0 amd > maxFromInputValue
     *
     * @param {number} _value - typed number from the input
     */
    correctFromInputValue(_value) {
      // * if _value > 0 , Math.max will return _value
      // * if _value > max possible value (maxFromInputValue), will return max possible
      return Math.min(Math.max(_value, 0), this.maxFromInputValue);
    },

    /**
     * Corected amount of CBR in right input - can't type < 0 amd > maxToInputValue
     *
     * @param {number} _value - typed number from the input
     */
    correctToInputValue(_value) {
      return Math.min(Math.max(_value, 0), this.maxToInputValue);
    },

    /**
     * To count CBR from NETS
     *
     * @param {number} _value
     * @return {Promise<string>} - amount of CBR for given NETS
     */
    async convertFrom2To(_value) {
      return await erc20Utils.erc20CoreToTokenPrice(appConfig.rpc, appConfig.cbrSwapContract, Math.floor(_value));
    },

    /**
     * To count NETS from CBR
     *
     * @param {number} _value
     * @return {Promise<string>} - amount of NETS for given CBR
     */
    async convertTo2From(_value) {
      const netsINWEi = await erc20Utils.erc20TokenToCorePrice(
        appConfig.rpc,
        appConfig.cbrSwapContract,
        Math.floor(_value),
      );
      return this.$fWallet.fromWei(netsINWEi.toString());
    },

    resetInputValues() {
      this.fromValue = 0;
      this.toValue = 0;
      this.currFromValue = '0';
    },

    /**
     * Calculate amount of NETS fot CBR and set describing this text
     */
    async setPerPrice() {
      const perPriceInWei = await erc20Utils.erc20TokenToCorePrice(appConfig.rpc, appConfig.cbrSwapContract);
      const { fromToken } = this;
      const { toToken } = this;
      this.perPrice = this.$fWallet.fromWei(perPriceInWei);
      const { $defi } = this;

      this.perPriceText = `${this.perPrice.toFixed(
        this.$defi.getTokenDecimals(fromToken),
      )} ${$defi.getTokenSymbol(fromToken)} per ${$defi.getTokenSymbol(toToken)}`;
    },

    resizeFromARInput() {
      this.$nextTick(() => {
        this.$refs.fromARInput.resizeInput();
      });
    },

    resizeToARInput() {
      this.$nextTick(() => {
        this.$refs.toARInput.resizeInput();
      });
    },

    /**
     * Mark the input fields invalid if the typed amount > maximum allowed
     */
    updateInputColor(_value, _toInput = false) {
      const cValue = _toInput ? this.correctToInputValue(_value) : this.correctFromInputValue(_value);
      const eInput = _toInput ? this.$refs.toInput : this.$refs.fromInput;

      if (_value > cValue) {
        eInput.classList.add('invalid');
      } else {
        eInput.classList.remove('invalid');
      }
    },

    updateSigns() {
      this.$nextTick(() => {
        const { $refs } = this;
        let { value } = $refs.fromInput;

        $refs.fromSign.style.visibility = !value || value === '0' ? 'hidden' : 'visible';
        value = $refs.toInput.value;
        $refs.toSign.style.visibility = !value || value === '0' ? 'hidden' : 'visible';
      });
    },

    /**
     * @param {InputEvent} _event
     */
    onFromInputChange(_event) {
      const cValue = this.correctFromInputValue(_event.target.value);

      if (this.fromValue === cValue) {
        this.$nextTick(() => {
          this.$refs.fromInput.value = this.formatFromInputValue(cValue);
        });
      }

      this.fromValue = cValue;

      this.updateInputColor(this.fromValue);
    },

    /**
     * @param {InputEvent} _event
     */
    async onFromInputInput(_event) {
      const data = await this.convertFrom2To(_event.target.value);
      this.$refs.toInput.value = this.formatToInputValue(this.correctToInputValue(data));

      this.updateInputColor(parseFloat(_event.target.value));
      this.updateSigns();
    },

    /**
     * @param {InputEvent} _event
     */
    async onToInputChange(_event) {
      const cValue = this.correctToInputValue(_event.target.value);

      if (this.toValue === cValue) {
        this.$nextTick(() => {
          this.$refs.toInput.value = this.formatToInputValue(cValue);
        });
      }

      this.toValue = cValue;
      this.fromValue = await this.convertTo2From(this.toValue);

      this.updateInputColor(this.toValue, true);
    },

    /**
     * @param {InputEvent} _event
     */
    async onToInputInput(_event) {
      const data = await this.convertTo2From(_event.target.value);
      this.$refs.fromInput.value = this.formatFromInputValue(this.correctFromInputValue(data));

      this.updateInputColor(parseInt(_event.target.value), true);
      this.updateSigns();
    },

    /**
     * Prevent typing '+' or '-'.
     * @param {KeyboardEvent} _event
     */
    onInputKeydown(_event) {
      if (_event.key === '+' || _event.key === '-') {
        _event.preventDefault();
      }
    },

    onSubmit() {
      const { fromToken } = this;
      const { toToken } = this;

      const params = {
        fromValue: this.fromValue,
        toValue: this.toValue,
        fromToken: { ...fromToken },
        toToken: { ...toToken },
        max: this.maxFromInputValue === this.fromValue,
      };

      if (!this.submitDisabled) {
        this.$router.push({
          name: 'defi-ftrade-c-b-r-confirmation',
          params,
        });
      }
    },

    onAccountPicked() {
      this.init();
      this.resetInputValues();
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
