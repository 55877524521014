<template>
  <div class="stake-form account-main-content-mt">
    <f-card class="f-card-double-padding">
      <f-form
        ref="stakeForm"
        center-form
        @f-form-submit="onFormSubmit"
      >
        <fieldset class="">
          <legend class="h2">
            <div class="cont-with-back-btn">
              <span>
                Delegate CBR <span class="f-steps"><b>1</b> / 3</span>
              </span>
              <a
                href="#"
                class="btn light dark"
                style="max-width: 100%;"
                aria-label="Go to previous form"
                @click.prevent="onPreviousBtnClick"
              >
                Back
              </a>
            </div>
          </legend>

          <div class="form-body">
            <f-input
              v-model="amount"
              label="Amount"
              field-size="large"
              type="number"
              autocomplete="off"
              min="1"
              step="any"
              name="amount"
              :validator="checkAmount"
              validate-on-input
            >
              <template #top="sProps">
                <div class="input-label-layout">
                  <label :for="sProps.inputId">{{ sProps.label }}</label>
                  <button
                    type="button"
                    class="btn light small dark"
                    @click="onEntireBalanceClick"
                  >
                    Entire Balance
                  </button>
                </div>
              </template>
              <template #bottom="sProps">
                <f-message
                  v-show="sProps.showErrorMessage"
                  type="error"
                  role="alert"
                  with-icon
                >
                  {{ amountErrMsg }}
                </f-message>
              </template>
            </f-input>

            <f-input
              v-model="validator"
              label="Validator"
              field-size="large"
              autocomplete="off"
              name="validator"
              readonly
              disabled-as-text
              class="validator-select fake-readonly"
              :validator="checkValidator"
              validate-on-input
              @click.native="onSelectValidatorClick"
              @keydown.native="onSelectValidatorKeyup"
            >
              <template #suffix>
                <span class="btn same-size round small light">
                  <icon
                    data="@/assets/svg/chevron-down.svg"
                    color="rgb(140,140,140)"
                  />
                </span>
              </template>
              <template #bottom="sProps">
                <f-message
                  v-show="sProps.showErrorMessage"
                  type="error"
                  role="alert"
                  with-icon
                >
                  {{ validatorErrMsg }}
                </f-message>
              </template>
            </f-input>

            <div class="align-center form-buttons">
              <button
                type="submit"
                class="btn large break-word btn-dark"
                style="max-width: 100%;"
              >
                Continue
              </button>
            </div>
          </div>
        </fieldset>
      </f-form>
    </f-card>

    <validator-picker-window
      ref="validatorPickerWindow"
      @validator-selected="onValidatorSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FCard from '../core/FCard/FCard.vue';
import FForm from '../core/FForm/FForm.vue';
import FMessage from '../core/FMessage/FMessage.vue';
import FInput from '../core/FInput/FInput.vue';
import ValidatorPickerWindow from '../windows/ValidatorPickerWindow.vue';
import { isAriaAction } from '../../utils/aria';
import { checkCBRAmount, validateNumberInput } from '../../utils/defi-tokens';

export default {
  name: 'StakeForm',

  components: {
    ValidatorPickerWindow,
    FInput,
    FMessage,
    FForm,
    FCard,
  },

  props: {
    /** Validator info. */
    stakerInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    /** Name of previous component. */
    previousComponent: {
      type: String,
      default: 'staking-info',
    },
    /** */
    stakerId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      amountErrMsg: 'Invalid amount',
      gasPrice: '',
      validator: 'Select a Validator',
      validatorErrMsg: 'Please select a validator',
      /** Info about selected validator. */
      validatorInfo: {
        address: '',
        id: '',
        name: '',
      },
      // * chosen for stake amount
      amount: '',
      cbrBalance: 0,
      cbrContractAddress: '',
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),
  },

  created() {
    this.$fWallet.getGasPrice()
      .then((_gasPrice) => {
        this.gasPrice = _gasPrice;
      });
    this.$defi.fetchTokens(this.currentAccount.address, 'CBR')
      .then((token) => {
        this.cbrContractAddress = token.address;
      });
  },

  // activated() {
  mounted() {
    const { stakerInfo } = this;

    this.validator = 'Select a Validator';
    this.validatorInfo = {
      address: '',
      id: '',
      name: '',
    };

    if (stakerInfo && stakerInfo.id) {
      this.validatorInfo = {
        id: stakerInfo.id,
        address: stakerInfo.stakerAddress,
        name: stakerInfo.stakerInfo.name,
      };

      this.updateValidatorInfo()
        .then(() => {
          this.validator = `${this.validatorInfo.name}, ${parseInt(this.validatorInfo.id, 16)}`;
        });
    }
  },

  methods: {
    /**
     * Validator for `amount` input field.
     *
     * @param {String} _value
     * @return {Boolean}
     */
    async checkAmount(_value) {
      this.cbrBalance = await this.$defi.getCBRBalance(this.currentAccount.address);
      const delegatedLimit =
        this.validatorInfo.delegatedLimit && this.validatorInfo.address
          ? parseInt(this.validatorInfo.delegatedLimit)
          : 0;
      // не допускаем наличия числа типа 076
      this.amount = validateNumberInput(this.amount);

      this.amountErrMsg = checkCBRAmount(_value, this.cbrBalance, delegatedLimit, 'stake', this.validator);
      return !this.amountErrMsg;
    },

    /**
     * Validator for `validator` input field.
     *
     * @return {Boolean}
     */
    checkValidator() {
      return !!this.validatorInfo.address;
    },

    /**
     * Update validator info with the newest data.
     *
     * @return {Promise<void>}
     */
    async updateValidatorInfo() {
      if (this.validatorInfo.id) {
        const validatorInfo = await this.$fWallet.getStakerById(this.validatorInfo.id);

        this.validatorInfo = {
          ...this.validatorInfo,
          ...validatorInfo,
        };
      }
    },

    /**
     * Get transaction object for staking and change view to `StakeConfirmation`.
     *
     * @param {Number} _amount Amount of FTM to stake.
     * @return {Promise<void>}
     */
    async stakeCofirmation(_amount) {
      const amount = parseInt(_amount);

      const tx = {
        amount,
        validatorId: parseInt(this.validatorInfo.id, 16),
      };

      this.$emit('change-component', {
        to: 'stake-confirmation',
        from: 'stake-form',
        data: {
          amount,
          ...this.validatorInfo,
          tx,
          stakerInfo: this.stakerInfo || this.validatorInfo,
          previousComponent: this.previousComponent,
          stakerId: this.stakerId,
          cbrBalance: this.cbrBalance,
          cbrContractAddress: this.cbrContractAddress,
        },
      });
    },

    onValidatorSelected(_validatorInfo) {
      this.validator = `${_validatorInfo.name}, ${parseInt(_validatorInfo.id, 16)}`;
      this.validatorInfo = { ..._validatorInfo };
      this.updateValidatorInfo()
        .then(() => {
          this.$refs.stakeForm.checkValidity();
        });
    },

    onSelectValidatorClick() {
      this.$refs.validatorPickerWindow.show();
    },

    onSelectValidatorKeyup(_event) {
      if (isAriaAction(_event)) {
        this.$refs.validatorPickerWindow.show();
      }
    },

    onPreviousBtnClick() {
      this.$emit('change-component', {
        to: this.previousComponent,
        from: 'stake-form',
        data: {
          stakerId: this.stakerId,
        },
      });
    },

    async onFormSubmit(_event) {
      const { data } = _event.detail;

      this.updateValidatorInfo()
        .then(async () => {
          if ((await this.$refs.stakeForm.checkValidity()) && this.validatorInfo.address) {
            this.stakeCofirmation(parseInt(data.amount));
          }
        });
    },

    async onEntireBalanceClick() {
      if (this.currentAccount) {
        this.cbrBalance = await this.$defi.getCBRBalance(this.currentAccount.address);
        this.amount = this.cbrBalance.toString();
      }
    },
  },
};
</script>

<style lang="scss">
.stake-form:not(.increase-delegation) {
  .validator-select,
  .validator-select > *,
  .validator-select input {
    cursor: pointer !important;
  }

  // todo - correct! do not use a readonly input when it doesn't have such meaning
  .fake-readonly .inp {
    opacity: 1 !important;
  }
}
</style>
