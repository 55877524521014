<template>
    <span class="f-select">
        <slot
          name="top"
          v-bind="slotProps"
        >
            <label :for="id">{{ label }}</label>
        </slot>
        <div
          :id="id"
          ref="select"
          v-bind="selectProps"
          :data-name="selectProps.name"
          :aria-invalid="isInvalid"
          :aria-describedby="ariaDescribedBy"
          class="select inp"
          :class="inpClasses"
          tabindex="0"
          @click="onClick"
          @focus="onFocus"
          @focusout="onFocusOut"
        >
            {{ selectValue.label }}
            <div
              v-if="dropdownOpened"
              class="select-dropdown"
              @click="onDropdownClicked"
            >
                <div
                  v-for="item in data"
                  :key="item.value"
                  :data-label="item.label"
                  :data-value="item.value"
                  :disabled="item.disabled"
                  class="select-dropdown-option"
                  :class="{ chosen: item.value == selectValue.value }"
                >
                    {{ item.label }}
                </div>
            </div>
        </div>
        <slot
          name="bottom"
          v-bind="slotProps"
        ></slot>
    </span>
</template>

<script>
import { helpersMixin } from '@/mixins/helpers';
import { selectMixin } from '@/mixins/select';
import { getUniqueId } from '@/utils';
import events from '../../../mixins/events';

export default {
  name: 'FSelect',

  mixins: [events, selectMixin, helpersMixin],

  props: {
    /**
     * Data for select element
     *
     * @type {[{value: string|number, label: string, disabled: boolean}]}
     */
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    /** Custom validator function */
    validator: {
      type: Function,
      default: null,
    },
    /**
     * Size of select 'large' | 'small'
     *
     * @type {('large' | 'small')}
     */
    selectSize: {
      type: String,
      default: '',
    },

    /** Recovered or chosen value displayed in select */
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      val: this.value,
      isInvalid: this.invalid,
      errmsgslot: 'bottom',
      ariaDescribedBy: null,
      dropdownOpened: false,
      selectValue: {
        label: '',
        value: this.value,
      },
    };
  },

  computed: {
    hasScroll() {
      return this.data.length > 6;
    },

    inpClasses() {
      return {
        invalid: this.isInvalid,
        large: this.selectSize === 'large',
        small: this.selectSize === 'small',
        disabled: this.disabled,
      };
    },

    slotProps() {
      return {
        showErrorMessage: this.isInvalid,
        showInfoMessage: this.showInfoMessage,
      };
    },

    showInfoMessage() {
      return this.hideInfoOnError ? !this.isInvalid : true;
    },
  },

  watch: {
    // props value
    value(_val) {
      this.val = _val;
    },

    isInvalid() {
      this.setAriaDescribedBy();
    },
  },

  created() {
    this.getLabelByValue(this.val);
  },

  mounted() {
    this.setAriaDescribedBy();
  },

  methods: {
    /**
     * Set aria-describedby attribute according to `isInvalid` property if FMessage child component exists.
     */
    setAriaDescribedBy() {
      const eSelect = this.$refs.select;
      let fMessage;

      if (this.isInvalid) {
        fMessage = this.getFMessage('error');
      } else {
        fMessage = this.getFMessage('info');
        // eSelect.setCustomValidity('');
        // this.ariaDescribedBy = null;
      }

      if (fMessage) {
        // set custom error message
        if (this.isInvalid) {
          eSelect.setCustomValidity(fMessage.getMessage());
        } else {
          eSelect.setCustomValidity('');
        }

        const id = getUniqueId();
        fMessage.$el.id = id;
        this.ariaDescribedBy = id;
      } else {
        this.ariaDescribedBy = null;
      }
    },

    async validate(_setError) {
      if (this.validator) {
        const result = this.validator(this.val);

        if (result instanceof Promise) {
          const value = await result;
          this.isInvalid = !value;
        } else {
          this.isInvalid = !result;
        }

        if (_setError) {
          this.setAriaDescribedBy();
        }
      }
    },

    /**
     * Get FMessage child component by type.
     *
     * @param {string} _type
     * @return {null|*|Vue}
     */
    getFMessage(_type) {
      const fMessages = this.findChildrenByName('f-message', true);
      let fMessage = null;

      for (let i = 0, len1 = fMessages.length; i < len1; i++) {
        fMessage = fMessages[i];
        if (fMessage && fMessage.$props.type === _type) {
          break;
        }
      }

      return fMessage;
    },

    onClick(_event) {
      const event = {
        ..._event,
        name: this.selectProps.name,
        customSelectValue: this.selectValue.value.trim(),
      };

      this.emitCustomEvent('change', event);

      this.validate();
    },

    onFocus() {
      this.dropdownOpened = true;
    },

    onFocusOut() {
      this.dropdownOpened = false;
    },

    /**
     * @param {Event} _event
     */
    onDropdownClicked({ target }) {
      if (this.dropdownOpened) {
        if (target.closest('.select-dropdown-option')) {
          this.selectValue = {
            label: target.dataset.label,
            value: target.dataset.value,
          };
        }
        this.dropdownOpened = false;
        // убираем фокус с селекта после выбора значения
        this.$refs.select.blur();
      }
    },

    /**
     * Восттанавливает выбранное значение селекта при инициализации
     *
     * @param {string | number} value
     *
     */
    getLabelByValue(value) {
      this.data.forEach((item) => {
        if (item.value == value) {
          this.selectValue.label = item.label;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
