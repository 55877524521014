import Staking from '@/views/Staking/Staking.vue';
import AccountSendErc20 from '@/views/AccountSendErc20/AccountSendErc20.vue';
import AccountApproveErc20 from '@/views/AccounApproveErc20/AccountApproveErc20.vue';
import Eip from '@/views/Eip/Eip.vue';
import EipWelcome from '@/views/EipWelcome/EipWelcome.vue';
import EipSendTransaction from '@/views/EipSendTransaction/EipSendTransaction.vue';
import EipSelectAccounts from '@/views/EipSelectAccounts/EipSelectAccounts.vue';
import Gov from '@/views/Gov/Gov.vue';
import GovHome from '@/views/GovHome/GovHome.vue';
import GovProposalDetail from '@/components/GovProposalDetail/GovProposalDetail.vue';
import GovProposalConfirmation from '@/components/GovProposalConfirmation/GovProposalConfirmation.vue';
import GovCancelVoteConfirmation from '@/components/GovCancelVoteConfirmation/GovCancelVoteConfirmation.vue';
import FSwap from '@/views/FSwap/FSwap.vue';
import NotFound from './views/NotFound/NotFound.vue';
import Home from './views/Home/Home.vue';
import Welcome from './views/Welcome/Welcome.vue';
import CreateAccount from './views/CreateAccount/CreateAccount.vue';
import RestoreAccount from './views/RestoreAccount/RestoreAccount.vue';
import Account from './views/Account/Account.vue';
import Dashboard from './views/Dashboard/Dashboard.vue';
import AccountSend from './views/AccountSend.vue';
import AccountReceive from './views/AccountReceive.vue';
// import AccountStake from './views/AccountStake.vue';
import LedgerAccounts from './views/LedgerAccounts/LedgerAccounts.vue';
import AccountHistory from './views/AccountHistory/AccountHistory.vue';
import Settings from './views/Settings/Settings.vue';
import Wallet from './views/Wallet/Wallet.vue';
import TransactionSuccessMessageView from './views/TransactionSuccessMessageView/TransactionSuccessMessageView.vue';
import TransactionRejectMessageView from './views/TransactionRejectMessageView/TransactionRejectMessageView.vue';
import DefiFTrade from './views/DefiFTrade/DefiFTrade.vue';
import DefiFTradeCBR from './views/DefiFTradeCBR/DefiFTradeCBR.vue';
import DefiFTradeConfirmation from './views/DefiFTradeConfirmation/DefiFTradeConfirmation.vue';
import FSwapCBR from './views/FSwapCBR/FSwapCBR.vue';

export const routes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    children: [
      {
        name: 'welcome',
        path: '',
        component: Welcome,
      },
      {
        name: 'create-account',
        path: '/account/create',
        component: CreateAccount,
      },
      {
        name: 'restore-account',
        path: '/account/restore',
        component: RestoreAccount,
      },
      {
        name: 'ledger-accounts',
        path: '/ledger-accounts',
        component: LedgerAccounts,
      },
    ],
  },
  {
    name: 'eip',
    path: '/',
    component: Eip,
    children: [
      {
        name: 'eip-welcome',
        path: '/eip-welcome',
        component: EipWelcome,
      },
      {
        name: 'eip-select-accounts',
        path: '/eip-select-accounts/:site',
        component: EipSelectAccounts,
      },
      {
        name: 'eip-send-transaction',
        path: '/eip-send-transaction/:id',
        component: EipSendTransaction,
      },
    ],
  },
  {
    name: 'wallet',
    path: '/',
    component: Wallet,
    children: [
      {
        name: 'account',
        path: '/account/:address',
        component: Account,
        children: [
          {
            name: 'account-history',
            path: '',
            component: AccountHistory,
            meta: { dontScrollToTop: true },
          },
          {
            name: 'account-send',
            path: 'send',
            component: AccountSend,
            meta: { dontScrollToTop: true },
          },
          {
            name: 'account-send-erc20',
            path: 'send',
            component: AccountSendErc20,
            meta: { dontScrollToTop: true },
          },
          {
            name: 'account-approve-erc20',
            path: 'approve',
            component: AccountApproveErc20,
            meta: { dontScrollToTop: true },
          },
          {
            name: 'account-receive',
            path: 'receive',
            component: AccountReceive,
            meta: { dontScrollToTop: true },
          },
          /*
                    {
                        name: 'account-stake',
                        path: 'stake',
                        component: AccountStake,
                        meta: { dontScrollToTop: true },
                    },
                    */
        ],
      },
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
      },
      {
        name: 'staking',
        path: '/staking/:address',
        component: Staking,
      },
      // 2-way swap for wNETS
      {
        name: 'fswap',
        path: '/wNETS/:address',
        component: FSwap,
        children: [
          {
            name: 'defi-ftrade',
            path: '',
            component: DefiFTrade,
          },
          {
            name: 'defi-ftrade-confirmation',
            path: 'confirmation',
            component: DefiFTradeConfirmation,
          },
          {
            name: 'defi-ftrade-transaction-success-message',
            path: 'confirmation/success',
            component: TransactionSuccessMessageView,
          },
          {
            name: 'defi-ftrade-transaction-reject-message',
            path: 'confirmation/reject',
            component: TransactionRejectMessageView,
          },
        ],
      },
      // 1-way swap for CBR
      {
        name: 'fswap-CBR',
        path: '/CBR/:address',
        component: FSwapCBR,
        children: [
          {
            name: 'defi-ftrade-c-b-r',
            path: '',
            component: DefiFTradeCBR,
          },

          {
            name: 'defi-ftrade-c-b-r-confirmation',
            path: 'confirmation',
            component: DefiFTradeConfirmation,
          },
          {
            name: 'defi-ftrade-c-b-r-transaction-success-message',
            path: 'confirmation/success',
            component: TransactionSuccessMessageView,
          },
          {
            name: 'defi-ftrade-c-b-r-transaction-reject-message',
            path: 'confirmation/reject',
            component: TransactionRejectMessageView,
          },
        ],
      },
      {
        name: 'gov',
        path: '/gov/:address',
        component: Gov,
        children: [
          {
            name: 'gov-home',
            path: '',
            component: GovHome,
          },
          {
            name: 'gov-proposal-detail',
            path: 'proposal-detail/:proposalId?/:governanceId?',
            component: GovProposalDetail,
          },
          {
            name: 'gov-proposal-confirmation',
            path: 'proposal-detail/:proposalId?/:governanceId?/confirmation',
            component: GovProposalConfirmation,
          },
          {
            name: 'gov-proposal-transaction-success-message',
            path: 'proposal-detail/:proposalId?/:governanceId?/confirmation/success',
            component: TransactionSuccessMessageView,
          },
          {
            name: 'gov-proposal-transaction-reject-message',
            path: 'proposal-detail/:proposalId?/:governanceId?/confirmation/reject',
            component: TransactionRejectMessageView,
          },
          {
            name: 'gov-cancel-vote-confirmation',
            path: 'proposal-detail/:proposalId?/:governanceId?/confirmation',
            component: GovCancelVoteConfirmation,
          },
          {
            name: 'gov-cancel-vote-transaction-success-message',
            path: 'proposal-detail/:proposalId?/:governanceId?/confirmation/success',
            component: TransactionSuccessMessageView,
          },
          {
            name: 'gov-cancel-vote-transaction-reject-message',
            path: 'proposal-detail/:proposalId?/:governanceId?/confirmation/reject',
            component: TransactionRejectMessageView,
          },
        ],
      },
      {
        name: 'settings',
        path: '/settings',
        component: Settings,
      },
    ],
  },
  {
    name: 'not-found',
    path: '*',
    component: NotFound,
  },
];
