<template>
  <header class="account-header">
    <info-box class="account-info-box"/>
  </header>
</template>

<script>
import InfoBox from '../InfoBox/InfoBox.vue';

export default {
  components: { InfoBox },
};
</script>

<style lang="scss">
@import 'style';
</style>
