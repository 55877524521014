var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wallet-assets-list-dt"},[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"f-card-off":"","columns":_vm.columns,"items":_vm.items,"first-m-v-column-width":"6"},scopedSlots:_vm._u([{key:"column-asset",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-crypto-symbol',{attrs:{"token":item}})],1)]):[_c('f-crypto-symbol',{attrs:{"token":item}})]]}},{key:"column-actions-account",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('router-link',{staticClass:"action",attrs:{"to":{ name: 'account-send-erc20', params: { token: Object.assign({}, item) } }}},[_vm._v(" Send ")])],1),_c('div',{staticClass:"col break-word"},[_c('router-link',{staticClass:"action",attrs:{"to":{ name: 'account-approve-erc20', params: { token: Object.assign({}, item) } }}},[_vm._v(" Approve ")])],1)]):[_c('router-link',{staticClass:"action",attrs:{"to":{ name: 'account-send-erc20', params: { token: Object.assign({}, item) } }}},[_vm._v(" Send ")]),_c('router-link',{staticClass:"action",attrs:{"to":{ name: 'account-approve-erc20', params: { token: Object.assign({}, item) } }}},[_vm._v(" Approve ")])]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }