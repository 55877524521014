export default function govInstruction() {
  return `
    <h3 class="gov-instruction-window__title-3">NETSBO-Governance</h3>
    <h4 class="gov-instruction-window__title-4">Deploying Proposal contract</h4>
    <p class="gov-instruction-window__paragraph">
      Deploy<span class="gov-instruction-window__code">**proposal/ExecutableProposal.sol**</span>
      contract with constructor arguments:
    </p>
    <ul class="list-marker-dash gov-instruction-window__deploy-list">
      <li><span class="gov-instruction-window__code">**__name**</span> — Proposal name.</li>
      <li><span class="gov-instruction-window__code">**__description**</span>
          — Proposal description.Explain what you want to do by this proposal.
      </li>
      <li><span class="gov-instruction-window__code">**__start**</span>
          — In how many seconds will the voting start.
      </li>
      <li><span class="gov-instruction-window__code">**__minEnd**</span>
          — In how many seconds after start can the voting close.</li>
      <li><span class="gov-instruction-window__code">**__maxEnd**</span>
          — In how many seconds after start will the voting be closed.
      </li>
      <li><span class="gov-instruction-window__code">**__destination**</span>
          — Address of a contract that will be called by the governance after successful voting.
      </li>
      <li><span class="gov-instruction-window__code">**__data**</span>
          — Encoded function interface and arguments of the destination contract. Use
          <span class="gov-instruction-window__code">***web3.encodeFunctionSignature***</span>
          to get the <span class="gov-instruction-window__code">__data.</span>
      </li>
    </ul>
    <p class="gov-instruction-window__paragraph">Your proposal parameters should follow <b>template requirements</b>.
        Call <span class="gov-instruction-window__code">\`ProposalTemplates.get({proposalType})\`</span>
        to see requirements for this proposal type.
    </p>
    <ul class="no-markers gov-instruction-window__requirements-list">
      <li>
          <span class="gov-instruction-window__code--number">0</span>
          Non-executable proposal
      </li>
      <li>
          <span class="gov-instruction-window__code--number">1</span>
          Proposal with <span class="gov-instruction-window__code">call</span> function
      </li>
      <li>
          <span class="gov-instruction-window__code--number">2</span>
          Proposal with <span class="gov-instruction-window__code">delegatecall</span> function
      </li>
    </ul>
    <h4 class="gov-instruction-window__title-4">Adding your Proposal for Voting</h4>
    <ol class="wide-markers gov-instruction-window__requirements-list">
      <li>Copy address of the deployed proposal contract</li>
      <li>
          Set transaction <span class="gov-instruction-window__code">value</span> to proposal creation fee
          (<span class="gov-instruction-window__code">\`Governance.proposalFee()\`</span>)
      </li>
      <li>
          Call <span class="gov-instruction-window__code">\`Governance.createProposal()\`</span>
          function with the copied proposal address
      </li>
    </ol>
    <div class="gov-instruction-window__attention-block">
      If you saw  <span class="gov-instruction-window__code">Error</span> message,
      compare your contract params to proposal template requirements.
    </div>
  `;
}
