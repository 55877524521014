<template>
  <nav class="dashboard-actions-box actions-box">
    <ul class="no-markers">
      <li
        v-for="btn in btns"
        :key="btn.action"
      >
        <span
          class="action"
          @click="onActionBtnClick(btn.action)"
        >
          <button class="btn large same-size round actions-box__btn" :class="btn.btnClass">
            <icon
              :data="require(`@/assets/svg/${btn.icon}.svg`)"
              :fill="btn.fillSvg"
              width="20"
              height="20"
              aria-hidden="true"
            />
          </button>
          <span class="link-label" :class="btn.labelClass">{{  btn.label  }}</span>
        </span>
      </li>
    </ul>

    <create-account-window ref="createAccountWindow"/>
    <restore-account-window ref="restoreAccountWindow"/>
    <connect-wallet-window ref="connectWalletWindow"/>
  </nav>
</template>

<script>
import ConnectWalletWindow from '@/components/windows/ConnectWalletWindow/ConnectWalletWindow.vue';
import CreateAccountWindow from '../windows/CreateAccountWindow/CreateAccountWindow.vue';
import RestoreAccountWindow from '../windows/RestoreAccountWindow/RestoreAccountWindow.vue';

export default {
  name: 'DashboardActionsBox',

  components: {
    ConnectWalletWindow,
    RestoreAccountWindow,
    CreateAccountWindow,
  },

  data() {
    return {
      btns: [
        {
          action: 'connect',
          label: 'Connect Wallet',
          fillSvg: true,
          icon: 'connect',
          btnClass: 'ledger-accounts-btn',
          labelClass: 'ledger-accounts-color',
        },
        {
          action: 'create',
          label: 'Create Wallet',
          fillSvg: true,
          icon: 'wallet',
          btnClass: 'create-account-btn',
          labelClass: 'create-account-color',
        },
        {
          action: 'restore',
          label: 'Restore Wallet',
          fillSvg: true,
          icon: 'key',
          btnClass: 'restore-account-btn',
          labelClass: 'restore-account-color',
        },
      ],
    };
  },

  methods: {
    onActionBtnClick(action) {
      switch(action) {
        case 'connect':
          this.$refs.connectWalletWindow.show();
          break;
        case 'create':
          this.$refs.createAccountWindow.show();
          break;
        case 'restore':
          this.$refs.restoreAccountWindow.show();
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
