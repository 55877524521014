<template>
  <div class="transaction-confirmation">
    <tx-confirmation
      :tx="tx"
      confirmation-comp-name="transaction-confirmation"
      :send-button-label="btnLabel"
      password-label="Please enter your wallet password to send the transaction"
      :on-send-transaction-success="onSendTransactionSuccess"
      @change-component="onChangeComponent"
    >
      <h2 class="cont-with-back-btn">
        <span v-if="token.address">
          {{  tokenAction }} {{ tokenSymbol }} - Confirmation <span class="f-steps"><b> 2</b> / 2</span>
        </span>
        <span v-else>
          Send Aria NETS - Confirmation <span class="f-steps"><b>3</b> / 3</span>
        </span>
        <button
          type="button"
          class="btn light dark"
          @click="onBackBtnClick"
        >
          Back
        </button>
      </h2>

      <div class="transaction-info">
        <div class="row no-collapse">
          <div class="col-3 f-row-label">Send To</div>
          <div class="col break-word">
            {{ sendToAddress }}
            <span
              v-show="sendToAddressBalance || sendToAccountName"
              class="f-row-label"
              style="display: inline-block;"
            >
              (
              <template v-if="token.address">
                <f-token-value
                  :value="sendToAddressBalance"
                  :token="token"
                />
              </template>
              <template v-else-if="sendToAddressBalance"> {{ sendToAddressBalance }} NETS </template>
              <template v-if="sendToAccountName">, {{ sendToAccountName }} </template> )
            </span>
          </div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Send From</div>
          <div class="col break-word">
            {{ currentAccount.address }}
            <span
              class="f-row-label"
              style="display: inline-block;"
            >
              <template v-if="token.address">
                ( <f-token-value
                :value="maxRemainingErc20TokenBalance"
                :token="token"
              />
              </template>
              <template v-else> ( {{ toFTM(currentAccount.balance) }} NETS </template>
              <template v-if="currentAccount.name">, {{ currentAccount.name }}</template> )
            </span>
          </div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Amount</div>
          <div class="col">{{ txData.amount }}</div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Memo</div>
          <div class="col">{{ txData.memo }}</div>
        </div>

        <template v-if="sendDirection !== 'OperaToOpera'">
          <f-message
            type="warning"
            class="align-center"
          >
            All bridge transactions incur a fee of {{ minFTMToTransfer }} NETS, deducted from the transfer
            amount.
          </f-message>
          <f-message
            type="info"
            class="big"
          >
            You will receive <b>{{ txData.amount - minFTMToTransfer }} NETS</b>
          </f-message>
          <br/>
        </template>

        <!--
        <div class="row no-collapse">
            <div class="col-3 f-row-label">Fee</div>
            <div class="col">{{ txData.fee }}</div>
        </div>
        -->
      </div>

      <template #window-content>
        <ol class="f-data-layout">
          <li>
            <div class="row no-collapse">
              <div class="col-3 f-row-label">Send To</div>
              <div class="col break-word">
                {{ txData.opera_address }}
                <span
                  v-show="sendToAddressBalance"
                  class="f-row-label"
                >
                  <template v-if="sendToAddressBalance">
                    ( {{ toFTM(sendToAddressBalance.balance) }} NETS )
                  </template>
                </span>
              </div>
            </div>
          </li>
          <li>
            <div class="row no-collapse">
              <div class="col-3 f-row-label">Send From</div>
              <div class="col break-word">
                {{ currentAccount.address }}
                <span class="f-row-label">
                  <template v-if="currentAccount.name"><br/></template>
                  ( {{ toFTM(currentAccount.balance) }} NETS
                  <template v-if="currentAccount.name">, {{ currentAccount.name }}</template> )
                </span>
              </div>
            </div>
          </li>
          <li>
            <div class="row no-collapse">
              <div class="col-3 f-row-label">Amount</div>
              <div class="col">{{ txData.amount }}</div>
            </div>
          </li>
          <li>
            <div class="row no-collapse">
              <div class="col-3 f-row-label">Max Fee</div>
              <div class="col">{{ txData.fee }}</div>
            </div>
          </li>
        </ol>
        <f-message
          v-if="sendDirection === 'OperaToEthereum'"
          type="warning"
          class="align-center"
        >
          All bridge transactions incur a fee of {{ minFTMToTransfer }} NETS, deducted from the transfer
          amount.
          <br/>
          You will receive {{ txData.amount - minFTMToTransfer }} NETS
          <br/>
        </f-message>
      </template>
    </tx-confirmation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import erc20Utils from 'fantom-ledgerjs/src/erc20-utils';
import FTokenValue from '@/components/core/FTokenValue/FTokenValue.vue';
import FMessage from '@/components/core/FMessage/FMessage.vue';
import sfcUtils from 'fantom-ledgerjs/src/sfc-utils';
import { findFirstFocusableDescendant } from '../../utils/aria';
import { Web3 } from '../../plugins/fantom-web3-wallet';
import { toFTM } from '../../utils/transactions';
import { formatNumberByLocale } from '../../filters';
import TxConfirmation from '../TxConfirmation/TxConfirmation.vue';
import appConfig from '../../../app.config';

export default {
  components: {
    FMessage,
    FTokenValue,
    TxConfirmation,
  },

  props: {
    // transaction data from SendTransactionForm
    txData: {
      type: Object,
      default() {
        return {};
      },
    },
    /** @type {DefiToken} */
    token: {
      type: Object,
      default() {
        return {};
      },
    },
    approveERC20: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sendToAddress: '',
      dTxData: this.txData,
      tx: {},
      minFTMToTransfer: appConfig.bnbridgeApi.minFTMToTransfer,
    };
  },

  computed: {
    ...mapGetters(['currentAccount', 'sendDirection', 'getAccountByAddress']),

    btnLabel() {
      return this.approveERC20 ? 'Approve' : 'Send';
    },

    tokenAction() {
      return this.approveERC20 ? 'Approve' : 'Send';
    },

    /**
     * @return {string}
     */
    tokenSymbol() {
      const { token } = this;

      return token.address ? this.$defi.getTokenSymbol(token) : 'FTM';
    },

    /**
     * @return {number}
     */
    maxRemainingErc20TokenBalance() {
      const { token } = this;

      return this.$defi.fromTokenValue(token.balanceOf || token.availableBalance, token) || 0;
    },

    sendToAccountName() {
      const account = this.sendToAddress ? this.getAccountByAddress(this.sendToAddress) : null;

      return account ? account.name : '';
    },
  },

  asyncComputed: {
    async sendToAddressBalance() {
      const { sendDirection } = this;
      let balance = 0;
      let data;

      if (this.token.address && this.sendToAddress) {
        data = await this.$defi.fetchERC20TokenAvailableBalance(this.sendToAddress, this.token.address);

        if (data) {
          balance = this.$defi.fromTokenValue(data, this.token);
        }
      } else if (sendDirection === 'OperaToOpera') {
        data = await this.$fWallet.getBalance(this.txData.opera_address);
        balance = this.toFTM(data.balance);
      } else if (sendDirection === 'OperaToBinance') {
        data = await this.$bnb.getBNBBalances(this.txData.bnb_address);
        balance = `BNB address: ${this.txData.bnb_address}, ${formatNumberByLocale(data.balance)}`;
      } else if (sendDirection === 'OperaToEthereum') {
        data = await this.$bnb.getETHBalance(this.txData.eth_address);
        balance = `ETH address: ${this.txData.eth_address}, ${formatNumberByLocale(data)}`;
      }

      return balance;
    },
  },

  created() {
    /** Data for token swap. */
    this._swapTokenData = null;
  },

  mounted() {
    if (!this.currentAccount.isLedgerAccount) {
      const el = findFirstFocusableDescendant(this.$el);
      if (el) {
        el.focus();
      }
    }

    // this.setSendToAddress();
  },

  /**
   * Called when component is activated through `keep-alive`.
   */
  activated() {
    this.dTxData = this.txData;
    this.setSendToAddress();
  },

  methods: {
    async setSendToAddress() {
      const { sendDirection } = this;
      let data;
      let stData = null;

      if (sendDirection === 'OperaToOpera') {
        this.sendToAddress = this.txData.opera_address;
      } else if (sendDirection === 'OperaToBinance') {
        stData = {
          direction: sendDirection,
          bnbAddress: this.txData.bnb_address,
        };
      } else if (sendDirection === 'OperaToEthereum') {
        stData = {
          direction: sendDirection,
          ethAddress: this.txData.eth_address,
        };
      }

      if (stData) {
        data = await this.$bnb.swapToken(stData);

        this._swapTokenData = {
          from_opera_address: this.currentAccount.address,
          ...this.txData,
          ...data,
        };
        this.sendToAddress = data.opera_address;
        this.dTxData.opera_address = data.opera_address;

        // console.log('_swapTokenData', this._swapTokenData);
      }

      this.setTx();
    },

    async setTx() {
      const from = this.currentAccount ? this.currentAccount.address : '';
      const { dTxData } = this;
      const fWallet = this.$fWallet;
      const { token } = this;

      if (!dTxData.opera_address) {
        return;
      }

      if (token.address) {
        if (this.approveERC20) {
          const tokenAddress = this.tokenSymbol === 'CBR' ? appConfig.cbrContract : this.token.address;
          const amountToApprove = this.tokenSymbol === 'CBR' ? parseInt(dTxData.amount) : parseFloat(dTxData.amount);
          const approveTx = sfcUtils.approveTx(
            amountToApprove * (10 ** this.token.decimals),
            tokenAddress,
            dTxData.address,
          );
          this.tx = await fWallet.getSFCTransactionToSign(
            approveTx,
            this.currentAccount.address,
          );
          // Можно вызвать при разработке в качестве проверки
          // this.checkAllowance(this.token.address,this.currentAccount.address, dTxData.address);
        } else {
          const amount = parseFloat(dTxData.amount) >= this.maxRemainingErc20TokenBalance
            ? token.balanceOf || token.availableBalance
            : Web3.utils.toHex(this.$defi.toTokenValue(dTxData.amount, token));
          this.tx = await fWallet.getDefiTransactionToSign(
            erc20Utils.erc20TransferTx(
              token.address,
              fWallet.toChecksumAddress(dTxData.opera_address),
              amount,
            ),
            this.currentAccount.address,
          );
        }
      } else {
        this.tx = await fWallet.getTransactionToSign({
          value: Web3.utils.toHex(Web3.utils.toWei(dTxData.amount)),
          from,
          to: fWallet.toChecksumAddress(dTxData.opera_address),
          memo: dTxData.memo,
        });
      }
    },
    /**
     * Если нужно проверить апрув
     * Правильное кол-во можно получить только после отправки транзакции,
     * повторив в интерфейсе еще раз все шаги
     * (это даст необходимые данные для метода)
     */
    async checkAllowance(tokenAddress, ownerAddress, allowedToAddress) {
      const allowanceData = await erc20Utils.getAllowance(appConfig.rpc, tokenAddress, ownerAddress, allowedToAddress);
      console.log('=>(TransactionConfirmation.vue:364) this.token', this.token.decimals);
      console.log('=>(TransactionConfirmation.vue:361) allowanceData', allowanceData / (10 ** this.token.decimals));
    },

    onSendTransactionSuccess(_data) {
      if (this._swapTokenData) {
        this.$emit('change-component', {
          to: 'transaction-completing',
          from: 'transaction-confirmation',
          data: {
            tx: _data.sendTransaction.hash,
            ...this._swapTokenData,
          },
        });
      } else {
        this.$emit('change-component', {
          to: 'transaction-success-message',
          from: 'transaction-confirmation',
          data: {
            tx: _data.sendTransaction.hash,
            continueTo: 'account-history',
          },
        });
      }
    },

    /**
     * Re-target `'change-component'` event.
     *
     * @param {object} _data
     */
    onChangeComponent(_data) {
      this.$emit('change-component', _data);
    },

    onBackBtnClick() {
      this.$emit('change-component', {
        to: 'send-transaction-form',
        from: 'transaction-confirmation',
      });
    },

    toFTM,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
