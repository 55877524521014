<template>
  <div class="dashboard-header">
    <h1>Home</h1>
    <header>
      <info-box class="dashboard-info-box"/>
    </header>
  </div>
</template>

<script>
import InfoBox from '../InfoBox/InfoBox.vue';

export default {
  name: 'DashboardHeader',

  components: { InfoBox },
};
</script>

<style lang="scss">
@import 'style';
</style>
