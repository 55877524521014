<template>
  <div class="stake-confirmation">
    <tx-confirmation
      :tx="tx"
      confirmation-comp-name="stake-confirmation"
      :password-label="passwordLabel"
      :send-button-label="sendBtnLabel"
      :on-send-transaction-success="onSendTransactionSuccess"
      @change-component="onChangeComponent"
    >
      <h2 class="cont-with-back-btn">
        <span>
          Delegate CBR - Confirmation
          <span
            class="f-steps"
          >
            <b>{{ step }}</b> / 3
          </span>
        </span>
        <button
          type="button"
          class="btn light dark"
          @click="onBackBtnClick"
        >
          Back
        </button>
      </h2>

      <div class="transaction-info">
        <div class="row no-collapse">
          <div class="col-3 f-row-label">Validator</div>
          <div class="col break-word">{{ stakeData.name }}, {{ formatHexToInt(stakeData.id) }}</div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Send From</div>
          <div class="col break-word">
            {{ currentAccount.address }}
            <span class="f-row-label no-break-word">
              ( {{ stakeData.cbrBalance }} CBR
              <template v-if="currentAccount.name">, {{ currentAccount.name }}</template> )
            </span>
          </div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Amount</div>
          <div class="col break-word">{{ stakeData.amount }}</div>
        </div>
      </div>

      <template #window-content>
        <ledger-confirmation-content
          :to="stakeData.tx.to"
          :amount="stakeData.amount"
        />
      </template>
    </tx-confirmation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import sfcUtils from 'fantom-ledgerjs/src/sfc-utils';
import { formatHexToInt } from '@/filters';
import TxConfirmation from '../TxConfirmation/TxConfirmation.vue';
import LedgerConfirmationContent from '../LedgerConfirmationContent/LedgerConfirmationContent.vue';
import { toFTM } from '../../utils/transactions';
import appConfig from '../../../app.config';

export default {
  name: 'StakeConfirmation',

  components: {
    LedgerConfirmationContent,
    TxConfirmation,
  },

  props: {
    /** Data sent from StakeForm component. Info about transaction, validator and amount of CBR. */
    stakeData: {
      type: Object,
      default() {
        return {};
      },
    },
    /** Validator info. */
    stakerInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    /** Name of previous component. */
    previousComponent: {
      type: String,
      default: 'staking-info',
    },
    /** In hex */
    stakerId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tx: {},
      step: 2,
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    passwordLabel() {
      return this.step === 2
        ? 'Please enter your wallet password to approve staking of your CBR'
        : 'Please enter your wallet password to delegate your CBR';
    },

    sendBtnLabel() {
      return this.step === 2 ? 'Approve' : 'Delegate';
    },
  },

  mounted() {
    this.setTx();
  },

  methods: {
    async setTx() {
      const {
        amount,
        validatorId,
      } = this.stakeData.tx;
      if (this.step === 2) {
        const approveTx = sfcUtils.approveTx(amount, this.stakeData.cbrContractAddress, appConfig.sfcContractAddress);
        this.tx = await this.$fWallet.getSFCTransactionToSign(approveTx, this.currentAccount.address);
      } else if (this.step === 3) {
        const delegationTx = sfcUtils.createDelegationTx(amount, validatorId, this.stakeData.cbrContractAddress);
        this.tx = await this.$fWallet.getSFCTransactionToSign(delegationTx, this.currentAccount.address);
      }
    },

    onSendTransactionSuccess(_data) {
      const stakerId = this.stakerId || this.stakeData.id;

      if (this.step === 2) {
        // * если прошел approve, т.е 2й шаг, перейти на следующий шаг
        this.step = 3;
        this.setTx();
      } else if (this.step === 3) {
        this.$emit('change-component', {
          to: 'transaction-success-message',
          from: 'stake-confirmation',
          data: {
            tx: _data.sendTransaction.hash,
            successMessage: 'Delegation Successful',
            continueTo: 'staking-info',
            continueToParams: { stakerId },
          },
        });
      }
    },

    onBackBtnClick() {
      this.$emit('change-component', {
        to: 'stake-form',
        from: 'stake-confirmation',
        data: {
          stakerInfo: this.stakerInfo,
          previousComponent: this.previousComponent,
          stakerId: this.stakerId,
        },
      });
    },

    /**
     * Re-target `'change-component'` event.
     *
     * @param {object} _data
     */
    onChangeComponent(_data) {
      this.$emit('change-component', _data);
    },

    toFTM,
    formatHexToInt,
  },
};
</script>
