<template>
  <div class="remove-account-window">
    <f-window
      ref="win"
      modal
      title="Remove Wallet"
      style="max-width: 560px;"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      @window-hide="onWindowHide"
    >
      <div class="align-center">
        Are you sure you want to remove wallet <span class="break-word">{{ dAccount.address }}</span> ?
      </div>
      <br/>
      <f-message
        type="warning"
        with-icon
      >
        Removing a wallet clears it from local storage. You will not be able to access it again unless you
        restore via mnemonic phrase, keystore file or private key. This action is irreversible.
      </f-message>
      <br/>
      <div class="align-center form-buttons">
        <button
          class="btn large secondary btn-second"
          @click="$refs.win.hide()"
        >Cancel
        </button>
        <button
          class="btn large"
          @click="onRemoveBtnClick"
        >Remove
        </button>
      </div>
    </f-window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FWindow from '../../core/FWindow/FWindow.vue';
import { REMOVE_ACCOUNT_BY_ADDRESS } from '../../../store/actions.type';
import {
  DEACTIVATE_ACTIVE_ACCOUNT,
  SET_ACTIVE_ACCOUNT_ADDRESS,
  SET_ACTIVE_ACCOUNT_BY_ADDRESS,
} from '../../../store/mutations.type';
import FMessage from '../../core/FMessage/FMessage.vue';

export default {
  name: 'RemoveAccountWindow',

  components: {
    FMessage,
    FWindow,
  },

  props: {
    account: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      dAccount: this.currentAccount,
      lastExistingAccountAddress: '',
    };
  },

  computed: {
    ...mapGetters(['currentAccount', 'accounts']),
  },

  watch: {
    account(_value) {
      this.dAccount = _value;
    },
  },

  created() {
    this.dAccount = this.currentAccount;
  },

  methods: {
    show() {
      this.$refs.win.show();
    },

    /**
     * @param {string} _address
     */
    setActiveAccount(_address) {
      this.$store.commit(DEACTIVATE_ACTIVE_ACCOUNT);
      this.$store.commit(SET_ACTIVE_ACCOUNT_BY_ADDRESS, _address);
      this.$store.commit(SET_ACTIVE_ACCOUNT_ADDRESS, _address);
    },
    /**
     * Remove account by address.
     *
     * @param {string} _address
     */
    async removeAccount(_address) {
      const activeAccountRemoved = await this.$store.dispatch(REMOVE_ACCOUNT_BY_ADDRESS, _address);
      if (this.accounts.length === 0) {
        this.$router.replace({ path: '/' });
      } else if (activeAccountRemoved) {
        const lastAccount = this.accounts[this.accounts.length - 1];
        this.lastExistingAccountAddress = lastAccount.address;
        this.$emit('account-removed', this.lastExistingAccountAddress);

        if (this.$route.name !== 'dashboard') {
          this.$router.replace({ name: 'dashboard' });
          this.setActiveAccount(lastAccount.address);
        }
      }
    },

    onRemoveBtnClick() {
      this.$refs.win.hide('fade-leave-active');
      this.removeAccount(this.currentAccount.address);
    },

    /**
     * Re-target `'window-hide'` event.
     *
     * @param {object} _data
     */
    onWindowHide(_data) {
      this.$emit('window-hide', _data);
    },
  },
};
</script>

<style scoped></style>
