<template>
  <div
    class="mnemonic-phrase"
    tabindex="-1"
  >
    <h2>Your mnemonic phrase</h2>

    <f-message
      type="info"
      with-icon
    >
      Please backup the text below on paper or copy and keep it somewhere secret and safe.
    </f-message>

    <div
      class="copy-mnemonic"
      :class="{ 'copy-mnemonic--in-dark-mode': isDarkMode }"
    >
      <f-copy-button
        :text="account.mnemonic.trim()"
        tooltip="Copy phrase to clipboard"
        :default-icon-size="16"
        class="btn light same-size round copy-mnemonic__btn"
        :class="{ 'white-btn': isDarkMode }"
      >
        <icon
          data="@/assets/svg/copy-bordered.svg"
          width="20"
          height="20"
          aria-hidden="true"
        />
        <template #popover-text>
          Phrase copied to clipboard.
        </template>
      </f-copy-button>
      <span class="copy-mnemonic__info">
                Copy mnemonic phrase to clipboard
            </span>
    </div>

    <ul
      v-if="dMnemonicArray.length"
      class="phrase-list no-markers"
      aria-label="mnemonic phrase - list of words"
    >
      <li
        v-for="(item, index) in dMnemonicArray"
        :key="`mn${index}`"
        tabindex="0"
        :aria-label="item"
      >
        <span
          class="num"
          aria-hidden="true"
        >{{ index + 1 }}</span> {{ item }}
      </li>
    </ul>

    <div class="footer">
      <button
        class="btn large"
        @click="onSubmitButClick"
      >I wrote down my recovery key
      </button> &nbsp;
      <button
        class="btn secondary large btn-second"
        @click="onPrivateKeyButClick"
      >View your private key
      </button>
    </div>
  </div>
</template>

<script>
import FMessage from '../core/FMessage/FMessage.vue';
import FCopyButton from '../core/FCopyButton/FCopyButton.vue';

export default {
  components: {
    FMessage,
    FCopyButton,
  },

  props: {
    // {privateKey: string, mnemonic: string, keystore: EncryptedKeystoreV3Json}
    account: {
      type: Object,
      default() {
        return {
          privateKey: '',
          mnemonic: '',
          keystore: null,
        };
      },
    },
  },

  data() {
    return {
      dMnemonicArray: this.$fWallet.getMnemonicArray(this.account.mnemonic),
    };
  },

  // mounted() {
  //     setTimeout(() => {
  //         this.$el.focus();
  //     }, 10);
  // },

  computed: {
    isDarkMode() {
      return this.$store.state.darkMode;
    },
  },

  methods: {
    onSubmitButClick() {
      this.$emit('change-component', {
        detail: {
          from: 'mnemonic-phrase',
          data: { account: this.account },
        },
      });
    },

    onPrivateKeyButClick() {
      alert(this.account.privateKey);
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
