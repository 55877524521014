<template>
  <div class="delegation-lock-confirmation">
    <tx-confirmation
      :tx="tx"
      confirmation-comp-name="delegation-lock-confirmation"
      send-button-label="Lock"
      password-label="Please enter your wallet password to lock delegation"
      :on-send-transaction-success="onSendTransactionSuccess"
      @change-component="onChangeComponent"
    >
      <h2 class="cont-with-back-btn">
                <span>
                    Lock Delegation - Confirmation <span class="f-steps"><b>2</b> / 2</span>
                </span>
        <button
          type="button"
          class="btn light"
          @click="onBackBtnClick"
        >Back
        </button>
      </h2>

      <div class="transaction-info">
        <div class="row no-collapse">
          <div class="col-3 f-row-label">Validator</div>
          <div class="col break-word">{{ validatorName }}, {{ stakerId }}</div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">From</div>
          <div class="col break-word">
            {{ currentAccount.address }}
            <span class="f-row-label">( {{ toFTM(currentAccount.balance) }} NETS )</span>
          </div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Lock Delegation Until</div>
          <div class="col break-word">{{ lockedUntil }} ({{ parseSeconds(lockDuration) }})</div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Lock Amount</div>
          <div class="col break-word">{{ toLockAmount }} CBR</div>
        </div>
      </div>

      <template #window-content>
        <ledger-confirmation-content
          :to="tx.to"
          :amount="0"
        />
      </template>
    </tx-confirmation>
  </div>
</template>

<script>
import LedgerConfirmationContent from '@/components/LedgerConfirmationContent/LedgerConfirmationContent.vue';
import TxConfirmation from '@/components/TxConfirmation/TxConfirmation.vue';
import { mapGetters } from 'vuex';
import sfcUtils from 'fantom-ledgerjs/src/sfc-utils';
import { toFTM } from '@/utils/transactions';
import { toHex } from '@/utils/big-number';
import { parseSeconds } from '@/utils/time';
import { formatHexToInt } from '@/filters';

export default {
  name: 'DelegationLockConfirmation',

  components: {
    LedgerConfirmationContent,
    TxConfirmation,
  },

  props: {
    /** Staker id in hex format */
    stakerId: {
      type: String,
      default: '',
    },
    /** Lock period in seconds */
    lockDuration: {
      type: Number,
      default: 14 * 24 * 60 * 60, // 14 days
    },

    /** Formatted date (or dare and time), when lock period ends */
    lockedUntil: {
      type: String,
      default: '',
    },

    /** Delegation amount to be locked */
    toLockAmount: {
      type: Number,
      default: 0,
    },

    /** Total delegation amount (in hex) */
    delegationAmountHex: {
      type: String,
      default: '',
    },

    /** Minimal lock time in seconds */
    minLockTime: {
      type: Number,
      default: 14 * 24 * 60 * 60, // 14 days
    },
  },

  data() {
    return {
      tx: {},
      validator: null,
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    validatorName() {
      const { validator } = this;

      return validator && validator.stakerInfo && validator.stakerInfo.name
        ? validator.stakerInfo.name
        : 'Unknown';
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.validator = await this.$fWallet.getStakerById(this.stakerId);

      this.setTx();
    },

    async setTx() {
      const stakerId = formatHexToInt(this.stakerId);

      if (this.lockDuration >= this.minLockTime) {
        this.tx = await this.$fWallet.getSFCTransactionToSign(
          sfcUtils.lockupDelegationTx(stakerId, this.lockDuration, toHex(this.toLockAmount)),
          this.currentAccount.address,
        );
      }
    },

    onSendTransactionSuccess(_data) {
      this.$emit('change-component', {
        to: 'transaction-success-message',
        from: 'delegation-lock-confirmation',
        data: {
          tx: _data.sendTransaction.hash,
          successMessage: 'Delegation Lock Successful',
          continueTo: 'staking-info',
          continueToParams: {
            stakerId: this.stakerId,
          },
        },
      });
    },

    onBackBtnClick() {
      this.$emit('change-component', {
        to: 'delegation-lock',
        from: 'delegation-lock-confirmation',
        data: {
          stakerId: this.stakerId,
        },
      });
    },

    /**
     * Re-target `'change-component'` event.
     *
     * @param {object} _data
     */
    onChangeComponent(_data) {
      this.$emit('change-component', _data);
    },

    toFTM,
    parseSeconds,
  },
};
</script>
