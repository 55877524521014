<template>
  <div id="app">
    <!--        <top-disclaimer />-->
    <!--            <router-view :key="$route.path"></router-view>-->

    <!--        <router-view aria-live="polite"></router-view>-->
    <router-view></router-view>

    <!--
    <f-header></f-header>
    <main>
        <router-view></router-view>
    </main>
    <f-footer></f-footer>
-->

    <f-breakpoints @f-breakpoint-change="onFBreakpointChange">
      <f-breakpoint
        value="576px"
        code="small"
      ></f-breakpoint>
      <f-breakpoint
        value="600px"
        code="data-table-mobile-view"
      ></f-breakpoint>
      <f-breakpoint
        value="768px"
        code="menu-mobile"
      ></f-breakpoint>
      <f-breakpoint
        value="769px"
        code="medium"
      ></f-breakpoint>
      <f-breakpoint
        value="870px"
        code="account-transaction-list-dt-mobile-view"
      ></f-breakpoint>
      <f-breakpoint
        value="890px"
        code="pairs-list-mobile-view"
      ></f-breakpoint>
      <f-breakpoint
        value="992px"
        code="large"
      ></f-breakpoint>
      <f-breakpoint
        value="1100px"
        code="validator-list-dt-mobile-view"
      ></f-breakpoint>
    </f-breakpoints>
    <f-aria-alert/>
    <f-network-status/>
    <metamask-account-picker-window
      ref="metamaskAccountPickerWindow"
      :metamask-account="dMetamaskAccount"
    />
  </div>
</template>

<script>
import FNetworkStatus from '@/components/core/FNetworkStatus/FNetworkStatus.vue';
import MetamaskAccountPickerWindow
  from '@/components/metamask/MetamaskAccountPickerWindow/MetamaskAccountPickerWindow.vue';
import { mapGetters } from 'vuex';
import FBreakpoint from './components/core/FBreakpoints/FBreakpoint.vue';
import FBreakpoints from './components/core/FBreakpoints/FBreakpoints.vue';
import {
  PUSH_BNBRIDGE_PENDING_REQUEST,
  SET_BREAKPOINT,
  // SET_CURRENCY,
  SET_FRACTION_DIGITS,
  SET_DARK_MODE,
  // SET_TOKEN_PRICE,
  SHIFT_BNBRIDGE_PENDING_REQUEST,
  REMOVE_BNBRIDGE_PENDING_REQUEST,
} from './store/mutations.type';
import FAriaAlert from './components/core/FAriaAlert/FAriaAlert.vue';
import { filtersOptions } from './filters';
import { eventBusMixin } from './mixins/event-bus';

export default {
  name: 'App',

  components: {
    MetamaskAccountPickerWindow,
    FNetworkStatus,
    FAriaAlert,
    FBreakpoint,
    FBreakpoints,
  },

  mixins: [eventBusMixin],

  data() {
    return {
      dMetamaskAccount: '',
    };
  },

  computed: {
    /*
        ...mapState('metamask', {
            metamaskAccount: 'account',
        }),
        */

    ...mapGetters(['getAccountByAddress']),
  },

  /*
    watch: {
        metamaskAccount(_account) {
            console.log(_account, this.getAccountByAddress(_account));
        },
    },
    */

  created() {
    // const useDarkColorScheme = window.matchMedia('(prefers-color-scheme: dark)');

    this.darkMode(this.$store.state.darkMode);

    // useDarkColorScheme.addEventListener('change', (_event) => this.darkMode(_event.matches));

    // filtersOptions.currency = this.$store.state.currency;
    filtersOptions.fractionDigits = this.$store.state.fractionDigits;
    // this.setTokenPrice(this.$store.state.currency);

    this.$bnb.setFSTRequestPushCallback((_request) => {
      this.onFSTRequestPush(_request);
    });
    this.$bnb.setFSTRequestDoneCallback((_request) => {
      this.onFSTRequestDone(_request);
    });
    this.$bnb.setFSTRequestCancelCallback((_request) => {
      this.onFSTRequestCancel(_request);
    });
    this.$bnb.setFSTPendingRequests([...this.$store.state.bnbridgePendingRequests]);
    this.$bnb.processFSTPendingRequests();
  },

  methods: {
    // * is not used for now
    // async setTokenPrice(_currency = filtersOptions.currency) {
    //     console.log('_currency: ', _currency);
    //     const tokenPrice = await this.$fWallet.getTokenPrice(_currency);
    //     console.log('tokenPrice: ', tokenPrice);

    //     filtersOptions.tokenPrice = tokenPrice;

    //     this.$store.commit(SET_TOKEN_PRICE, tokenPrice);
    // },

    /**
     * @param {string} _currency
     */
    // * is not used for now
    // async setCurrency(_currency) {
    //     if (_currency) {
    //         filtersOptions.currency = _currency;
    //         this.$store.commit(SET_CURRENCY, _currency);
    //         await this.setTokenPrice(_currency);
    //     }
    // },

    /**
     * @param {int} _fractionDigits
     */
    setFractionDigits(_fractionDigits) {
      if (_fractionDigits) {
        filtersOptions.fractionDigits = _fractionDigits;
        this.$store.commit(SET_FRACTION_DIGITS, _fractionDigits);
      }
    },

    /**
     * @param {boolean} _on
     */
    setDarkMode(_on) {
      this.$store.commit(SET_DARK_MODE, _on);

      this.darkMode(_on);
    },

    /**
     * @param {boolean} _on
     */
    darkMode(_on) {
      const { documentElement } = document;

      documentElement.classList.add('theme-transition');

      if (_on) {
        documentElement.classList.add('dark-theme');
      } else {
        documentElement.classList.remove('dark-theme');
      }

      setTimeout(() => {
        documentElement.classList.remove('theme-transition');
      }, 250);
    },

    /**
     * @param {string} _account Metamask account.
     */
    showMetamaskAccountPickerWindow(_account) {
      if (_account) {
        this.dMetamaskAccount = this.$fWallet.toChecksumAddress(_account);
      }
      this.$refs.metamaskAccountPickerWindow.show();
    },

    onFBreakpointChange(_event) {
      this.$store.commit(SET_BREAKPOINT, _event.detail);
    },

    /**
     * @param {FSTRequest} _request
     */
    onFSTRequestPush(_request) {
      // console.log('onFSTRequestPush', _request);
      this.$store.commit(PUSH_BNBRIDGE_PENDING_REQUEST, _request);
    },

    /**
     * @param {FSTRequest} _request
     */
    onFSTRequestDone(_request) {
      // console.log('onFSTRequestDone', _request);
      this._eventBus.emit('fst-request-done', _request);
      this.$store.commit(SHIFT_BNBRIDGE_PENDING_REQUEST);
    },

    /**
     * @param {FSTRequest} _request
     */
    onFSTRequestCancel(_request) {
      this.$store.commit(REMOVE_BNBRIDGE_PENDING_REQUEST, _request);
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/main';
</style>
