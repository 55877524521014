<template>
  <div class="view-dashboard">
    <dashboard-header/>

    <h2 class="h1">
      Wallets <span class="f-records-count">({{ accounts.length }})</span>
    </h2>
    <account-list edit-mode/>

    <h2 class="h1">
      Contacts <span class="f-records-count">({{ contacts.length }})</span>
    </h2>

    <contact-list edit-mode/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DEACTIVATE_ACTIVE_ACCOUNT,
  SET_ACTIVE_ACCOUNT_ADDRESS,
  SET_ACTIVE_ACCOUNT_BY_ADDRESS,
} from '@/store/mutations.type';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader.vue';
import AccountList from '../../components/AccountList/AccountList.vue';
import ContactList from '../../components/ContactList/ContactList.vue';

export default {
  name: 'Dashboard',

  components: {
    ContactList,
    AccountList,
    DashboardHeader,
  },

  computed: {
    ...mapGetters(['accounts', 'contacts']),
  },

  created() {
    // this.checkAccountsLength();
  },

  methods: {
    /** If a user is on this page,
     * he must either have at least one connected  wallet
     * or be redirected to welcome page
     */
    checkAccountsLength() {
      if (!this.accounts.length) {
        this.$router.push({
          name: 'welcome',
        });
      }
      if (this.accounts.length === 1) {
        this.setActiveAccount(this.accounts[0].address);
      }
    },

    /**
     * @param {string} _address
     */
    setActiveAccount(_address) {
      this.$store.commit(DEACTIVATE_ACTIVE_ACCOUNT);
      this.$store.commit(SET_ACTIVE_ACCOUNT_BY_ADDRESS, _address);
      this.$store.commit(SET_ACTIVE_ACCOUNT_ADDRESS, _address);
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
