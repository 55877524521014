import { Tree } from './utils/tree';

/**
 * App structure (incomplete) used for navigation and view transitions.
 *
 * @type {WalletAppStructureNode[]}
 */
const appStructure = [
  {
    id: 'wallet',
    _c: [
      // 2-way swap for wNETS
      {
        id: 'defi-ftrade',
        _c: [
          {
            id: 'defi-ftrade-confirmation',
            _c: [
              {
                id: 'defi-ftrade-transaction-success-message',
                _c: [
                  {
                    id: 'defi-ftrade-confirmation2',
                    _c: [
                      { id: 'defi-ftrade-transaction-success-message2' },
                      { id: 'defi-ftrade-transaction-reject-message2' },
                    ],
                  },
                ],
              },
              { id: 'defi-ftrade-transaction-reject-message' },
            ],
          },
        ],
      },
      // 1-way swap for CBR
      {
        id: 'defi-ftrade-c-b-r',
        _c: [
          {
            id: 'defi-ftrade-c-b-r-confirmation',
            _c: [
              {
                id: 'defi-ftrade-c-b-r-transaction-success-message',
                _c: [
                  {
                    id: 'defi-ftrade-c-b-r-confirmation2',
                    _c: [
                      { id: 'defi-ftrade-c-b-r-transaction-success-message2' },
                      { id: 'defi-ftrade-c-b-r-transaction-reject-message2' },
                    ],
                  },
                ],
              },
              { id: 'defi-ftrade-c-b-r-transaction-reject-message' },
            ],
          },
        ],
      },
      {
        id: 'gov',
        _c: [
          {
            id: 'gov-home',
            _c: [
              {
                id: 'gov-proposal-detail',
                _c: [
                  {
                    id: 'gov-proposal-confirmation',
                    _c: [
                      {
                        id: 'gov-proposal-transaction-success-message',
                      },
                      {
                        id: 'gov-proposal-transaction-reject-message',
                      },
                    ],
                  },
                  {
                    id: 'gov-cancel-vote-confirmation',
                    _c: [
                      {
                        id: 'gov-cancel-vote-transaction-success-message',
                      },
                      {
                        id: 'gov-cancel-vote-transaction-reject-message',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

/** @type {Tree} */
export const appStructureTree = new Tree(appStructure);

export function getAppParentNode(_id) {
  const node = appStructureTree.get(_id);

  return node ? node._p : null;
}

export function getAppNodeParents(_id) {
  return appStructureTree.getParents(_id);
}
