<template>
  <div class="contact-detail-form">
    <el-form
      ref="form"
      :model="form"
      class="center-form"
      @submit.native.prevent="onFormSubmit"
    >
      <fieldset>
        <legend class="not-visible">Contact Detail</legend>
        <div class="form-body">
        </div>
      </fieldset>
      <el-form-item>
        <f-input
          v-if="action === 'new'"
          ref="eAddress"
          v-model="form.address"
          type="text"
          label="Address"
          field-size="large"
          name="address"
          validate-on-input
          :validator="checkAddress"
        >
          <template #bottom="sProps">
            <f-message
              v-show="sProps.showErrorMessage"
              type="error"
              role="alert"
              with-icon
            >
              {{ addressErrorMsg }}
            </f-message>
          </template>
        </f-input>
        <template v-else>
          <span class="form-label">Address</span>
          <div class="break-word">
            <b style="padding-right: 16px;">{{ contactData.address }}</b>
            <f-copy-button
              :text="contactData.address"
              tooltip="Copy address to clipboard"
              :hide-popover-after="3100"
              class="btn large light same-size round"
            >
              <template #popover-text>
                Address copied to clipboard.
              </template>
            </f-copy-button>
            <button
              class="btn large light same-size round"
              title="Show QR Code"
              type="button"
              @click.prevent="$refs.qrWindow.show()"
            >
              <icon
                data="@/assets/svg/monochrome/Options/QR.svg"
                width="20"
                height="20"
                aria-hidden="true"
              />
            </button>
          </div>
          <br/>
        </template>
      </el-form-item>
      <el-form-item>
        <f-input
          v-model="form.name"
          type="text"
          label="Name"
          field-size="large"
          name="name"
          validate-on-input
          :validator="checkName"
        >
          <template #bottom="sProps">
            <f-message
              v-show="sProps.showErrorMessage"
              type="error"
              role="alert"
              with-icon
            >
              This field cannot be blank
            </f-message>
          </template>
        </f-input>
      </el-form-item>
      <el-form-item v-if="action === 'new'" label="Blockchain">
        <!--        Временно скрыто до реализации бриджа в приложении-->
        <!--        <el-select v-model="form.blockchain" class="large" placeholder="">-->
        <!--          <el-option-->
        <!--            v-for="item in $fWallet.blockchains"-->
        <!--            :key="item.value"-->
        <!--            :label="item.label"-->
        <!--            :value="item.value"-->
        <!--          />-->
        <!--        </el-select>        -->
        <el-select
          v-model="form.blockchain"
          class="large contact-detail-form__select"
          placeholder=""
          name="blockchain"
        >
          <el-option
            v-for="item in blockchainList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <template v-else>
        <span class="form-label">Blockchain</span>
        <div class="break-word blockchain-label-text">
          {{ $fWallet.getBlockchainLabel(contactData.blockchain) }}
        </div>
      </template>
      <el-form-item>
        <f-input
          v-model="form.order"
          type="number"
          autocomplete="off"
          min="1"
          :max="maxOrder.toString(10)"
          step="1"
          label="Order"
          field-size="large"
          name="order"
          validate-on-input
          :validator="checkOrder"
        >
          <template #bottom="sProps">
            <f-message
              v-show="sProps.showErrorMessage"
              type="error"
              role="alert"
              with-icon
            >
              Value must be between 1 and {{ maxOrder }}
            </f-message>
          </template>
        </f-input>
      </el-form-item>
      <div class="align-center form-buttons">
        <a
          v-if="action === 'edit'"
          href="#"
          class="btn large secondary btn-second"
          @click.prevent="onRemoveContactBtnClick"
        >
          Remove Contact...
        </a>
        <button
          type="submit"
          class="btn large btn-dark"
        >
          Save
        </button>
      </div>
    </el-form>

    <remove-contact-window
      ref="confirmationWindow"
      :contact-address="address"
      @contact-removed="onContactRemoved"
    />

    <q-r-code-window
      ref="qrWindow"
      :address="contactData.address"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Resolution from '@unstoppabledomains/resolution';
import FForm from '../core/FForm/FForm.vue';
import FInput from '../core/FInput/FInput.vue';
import FMessage from '../core/FMessage/FMessage.vue';
import { helpersMixin } from '../../mixins/helpers';
import FCopyButton from '../core/FCopyButton/FCopyButton.vue';
import QRCodeWindow from '../windows/QRCodeWindow/QRCodeWindow.vue';
import FSelect from '../core/FSelect/FSelect.vue';
import RemoveContactWindow from '../windows/RemoveContactWindow/RemoveContactWindow.vue';

// const resolution = new Resolution();

/**
 * @mixes helpersMixin
 */
export default {
  name: 'ContactDetailForm',

  components: {
    RemoveContactWindow,
    FSelect,
    QRCodeWindow,
    FCopyButton,
    FMessage,
    FInput,
    FForm,
  },

  mixins: [helpersMixin],

  props: {
    /**
     * Contact data
     *
     * @type {WalletContact}
     */
    contactData: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    /**
     * Type of action with contact.
     *
     * @type {WalletContactAction}
     */
    action: {
      type: String,
      default: 'new',
      validator(_value) {
        return ['new', 'add', 'edit'].indexOf(_value) !== -1;
      },
    },
  },

  data() {
    return {
      address: '',
      /** @type {WalletBlockchain} */
      selectedBlockchain: 'fantom',
      addressErrorMsg: '',
      resolvedAddress: null,
      blockchainList: [
        {
          value: 'fantom',
          label: 'Netsbo Aria',
        },
      ],
      form: {
        address: '',
        name: '',
        blockchain: 'fantom',
        order: '',
      },
    };
  },

  computed: {
    ...mapGetters(['contacts', 'getContactAndIndexByAddress', 'getAccountAndIndexByAddress']),

    /**
     * @return {string}
     */
    contactName() {
      return this.contactData.name || `Contact ${this.contacts.length + 1}`;
    },

    /**
     * @return {string}
     */
    contactOrder() {
      let order = this.contactData.order || -1;
      const { action } = this;

      if (order === -1 && this.contactData.address) {
        const { index } = this.getContactAndIndexByAddress(this.contactData.address);
        order = index + 1;
      }

      if (action === 'new' || action === 'add') {
        order = this.contacts.length + 1;
      }

      if (order < 1) {
        order = 1;
      }

      return order.toString(10);
    },

    maxOrder() {
      const len = this.contacts.length;
      const { action } = this;

      return action === 'new' || action === 'add' ? len + 1 : len;
    },
  },

  watch: {
    selectedBlockchain() {
      const { eAddress } = this.$refs;

      if (eAddress && this.address) {
        eAddress.validate();
      }
    },
  },

  created() {
    this.form.name = this.contactName;
    this.form.order = this.contactOrder;
  },

  mounted() {
    this.address = this.contactData.address || '';
    this.selectedBlockchain = this.contactData.blockchain || this.form.blockchain;
  },

  methods: {
    /**
     * @param {string} _value
     * @return {boolean}
     */
    checkName(_value) {
      return !!_value.trim();
    },

    /**
     * @param {string} _value
     * @return {boolean}
     */
    async checkAddress(_value) {
      const { blockchain } = this.form;
      const { action } = this;

      if (action !== 'new' && action !== 'add') {
        return true;
      }

      this.addressErrorMsg = '';

      if (!_value.trim()) {
        this.addressErrorMsg = 'This field cannot be blank';
        return false;
      }

      const address = _value;

      /** Скрыто в NSB-111, пока не разберемся, как применять этот плагин */
      // if (blockchain === 'fantom') {
      //   if (!resolution.isSupportedDomainInNetwork(_value)) {
      //     this.addressErrorMsg = 'This domain is not supported in network';
      //     return false;
      //   }
      //   try {
      //     this.resolvedAddress = address = await resolution.multiChainAddr(_value, 'FTM', 'OPERA');
      //   } catch (e) {
      //     console.log('Domain resolution failed', e);
      //   }
      // }

      if (!this.$fWallet.isValidAddress(address, blockchain)) {
        if (blockchain === 'fantom') {
          this.addressErrorMsg = 'Not valid Netsbo address';
        } else if (blockchain === 'ethereum') {
          this.addressErrorMsg = 'Not valid ethereum address';
        } else if (blockchain === 'binance') {
          this.addressErrorMsg = 'Not valid binance address';
        }
        return false;
      }
      if (
        this.getContactAndIndexByAddress(address).index !== -1 ||
        (blockchain === 'fantom' && this.getAccountAndIndexByAddress(address).index !== -1)
      ) {
        this.addressErrorMsg = 'Address already exists';
        return false;
      }

      return true;
    },

    /**
     * @param {string} _value
     * @return {boolean}
     */
    checkOrder(_value) {
      let ok = false;
      const value = parseInt(_value);
      const contactsLen = this.contacts.length;
      const { action } = this;
      const max = action === 'new' || action === 'add' ? contactsLen + 1 : contactsLen;

      if (!isNaN(value)) {
        ok = value > 0 && value <= max;
      }

      return ok;
    },

    onFormSubmit() {
      const order = parseInt(this.form.order);
      const address =
        this.resolvedAddress || // resolved from domain name during checkAddress
        this.form.address || // filled in form
        this.address; // form opened with

      if (this.checkName(this.form.name) && this.checkOrder(this.form.order) && this.checkAddress(this.form.address)) {
        this.$emit('contact-detail-form-data', {
          blockchain: this.form.blockchain,
          name: this.form.name,
          order,
          address,
        });
      }
    },

    onRemoveContactBtnClick() {
      this.$refs.confirmationWindow.show();
    },

    onContactRemoved() {
      const parentWindow = this.findParentByName('f-window');

      if (parentWindow) {
        parentWindow.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.blockchain-label-text {
  margin-bottom: 25px;
}

.contact-detail-form {
  &__select {
    margin-bottom: 16px;
  }
}
</style>

