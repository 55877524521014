<template>
  <div
    class="withdraw-request-list-dt"
    @click="onClick"
  >
    <template v-if="!withdrawRequestListError">
      <f-data-table
        :columns="columns"
        :items="sortedItems"
        first-m-v-column-width="6"
        fixed-header
        sticky0
        f-card-off
        no-f-l-padding
        class="f-data-table-body-bg-color"
      >
        <template v-slot:column-withdrawal="{ value, item, column }">
          <div
            v-if="column"
            class="row no-collapse no-vert-col-padding"
          >
            <div class="col-6 f-row-label">{{ column.label }}</div>
            <div class="col break-word">
              <template v-if="value">{{ formatDate(timestampToDate(value), false, true) }}</template>
              <template v-else>
                <button
                  :disabled="!!canNotWithdraw(item.createdTime) || !canWithdraw(item)"
                  class="btn withdraw-btn"
                  :data-item-id="item.id"
                >
                  {{ withdrawBtnLabel(item.createdTime) }}
                </button>
              </template>
            </div>
          </div>
          <template v-else>
            <template v-if="value">{{ formatDate(timestampToDate(value), false, true) }}</template>
            <template v-else>
              <button
                :disabled="!!canNotWithdraw(item.createdTime) || !canWithdraw(item) || disableWithdraw"
                class="btn withdraw-btn"
                :data-item-id="item.id"
              >
                {{ withdrawBtnLabel(item.createdTime) }}
              </button>
            </template>
          </template>
        </template>
      </f-data-table>
    </template>
    <template v-else>
      <div class="query-error">{{ withdrawRequestListError }}</div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  formatHexToInt, formatDate, prepareTimestamp, timestampToDate,
} from '@/filters';
import FDataTable from '../core/FDataTable/FDataTable.vue';
import { sortByHex } from '../../utils/array-sorting';

export default {
  name: 'WithdrawRequestList',

  components: { FDataTable },

  props: {
    /**
     * Data and action.
     * Actions:
     * '' - replace items
     * 'append' - append new items
     */
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    disableWithdraw: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      withdrawRequestListError: '',
      columns: [
        {
          name: 'amount',
          label: 'Amount (CBR)',
          formatter: (_value) => formatHexToInt(_value),
          width: '234px',
        },
        {
          name: 'undelegation_time',
          label: 'Undelegation Time',
          itemProp: 'createdTime',
          formatter: (_value) => formatDate(timestampToDate(_value), false, true),
        },
        {
          name: 'withdrawal',
          label: 'Withdrawal',
          itemProp: 'withdrawTime',
        },
      ],
      sfcConfig: {},
    };
  },

  computed: {
    pendingPartialUndelegations() {
      let count = 0;

      this.sortedItems.forEach((_item) => {
        if (!_item.withdrawBlock) {
          count++;
        }
      });

      return count > 1;
    },

    sortedItems() {
      const { items } = this;
      return items.sort(sortByHex('createdTime', 'desc'));
    },
  },

  async created() {
    this.sfcConfig = await this.$fWallet.getSFCConfig();
  },

  methods: {
    /**
     * Get label for withdraw button.
     *
     * @param {string} _timestamp
     * @return {string}
     */
    withdrawBtnLabel(_timestamp) {
      return this.canNotWithdraw(_timestamp) || 'Withdraw';
    },

    /**
     * Check if amount is available to withdraw.
     *
     * @param {string} _timestamp
     * @return {string}
     */
    canNotWithdraw(_timestamp) {
      const start = dayjs(this.prepareTimestamp(_timestamp))
        .utc();
      const { withdrawalPeriodTime } = this.sfcConfig;
      const end = withdrawalPeriodTime ? start.add(withdrawalPeriodTime.num, 'second') : null;
      const now = dayjs()
        .utc();

      if (end && now.diff(end) < 0) {
        return end.from(now);
      }
      return '';
    },

    /**
     * Check if amount is available to withdraw.
     *
     * @param {object} _withdrawRequest
     * @return {boolean}
     */
    canWithdraw(_withdrawRequest) {
      return (!this.pendingPartialUndelegations || !_withdrawRequest.final) && !_withdrawRequest.withdrawBlock;
    },

    onClick(_event) {
      const eWithdrawBtn = _event.target.closest('.withdraw-btn');

      if (eWithdrawBtn) {
        const id = eWithdrawBtn.getAttribute('data-item-id');
        const withdrawRequest = this.sortedItems.find((_item) => _item.id === id);

        if (withdrawRequest && this.canWithdraw(withdrawRequest)) {
          this.$emit('withdraw-request-selected', withdrawRequest);
        }
      }
    },

    formatDate,
    timestampToDate,
    prepareTimestamp,
  },
};
</script>
