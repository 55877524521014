<template>
  <div class="account-settings-window">
    <f-window
      ref="win"
      modal
      style="max-width: 600px;"
      title="Wallet Settings"
      class="double-body-padding c-footer_"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      @window-hide="onWindowHide"
    >
      <account-settings-form
        :account-address="accountAddress"
        :account-order="accountOrder"
        @account-settings-form-data="onAccountSettingsFormData"
        @account-settings-form-acoount-removed="onAccountRemoved"
      />
    </f-window>
  </div>
</template>

<script>
import FWindow from '../../core/FWindow/FWindow.vue';
import AccountSettingsForm from '../../forms/AccountSettingsForm.vue';

export default {
  name: 'AccountSettingsWindow',

  components: {
    AccountSettingsForm,
    FWindow,
  },

  props: {
    accountAddress: {
      type: String,
      required: true,
    },
    accountOrder: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      lastExistingAccountAddress: '',
    };
  },

  methods: {
    show() {
      this.$refs.win.show();
    },

    /**
     * Called when `AccountSettingsForm` is submited.
     */
    onAccountSettingsFormData() {
      this.$refs.win.hide('fade-leave-active');
    },

    /**
     * Re-target `'window-hide'` event, show if an account was deleted
     *
     * @param {object} _data
     */
    onWindowHide(_data) {
      const data = {
        ..._data,
        accountAddress: this.accountAddress,
      };
      this.$emit('window-hide', data, this.lastExistingAccountAddress);
    },

    /**
     * If active account removed, save last existing account address
     *
     * @param {string} lastExistingAccountAddress
     */
    onAccountRemoved(lastExistingAccountAddress) {
      this.lastExistingAccountAddress = lastExistingAccountAddress;
      this.$emit('window-account-removed', lastExistingAccountAddress);
    },
  },
};
</script>
