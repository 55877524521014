<template>
  <nav class="account-actions-box actions-box">
    <ul class="no-markers">
      <li
        v-for="btn in btns"
        :key="btn.routeName"
      >
        <span
          class="action"
          @click="onActionBtnClick(btn.routeName)"
        >
          <span
            class="btn large same-size round btn-dark-circle actions-box__btn"
            :class="[`${btn.name}-btn`, { 'btn-active': $route.name === btn.routeName }]"
          >
            <icon
              :data="require(`@/assets/svg/${btn.name}.svg`)"
              width="20"
              height="20"
              :fill="btn.fillSvg"
              aria-hidden="true"
            />
          </span>
          <span class="link-label">{{ btn.label || uppercaseFirstChar(btn.name) }}</span>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { uppercaseFirstChar } from '@/utils/index';

export default {
  components: {},

  data() {
    return {
      btns: [
        {
          name: 'wallet',
          fillSvg: false,
          routeName: 'account-history',
        },
        {
          name: 'send',
          fillSvg: true,
          routeName: 'account-send',
        },
        {
          name: 'receive',
          fillSvg: true,
          routeName: 'account-receive',
        },
        // {
        //     name: 'stake',
        //     fillSvg: true,
        //     routeName: 'account-stake',
        //     label: 'Staking',
        // },
      ],
    };
  },

  methods: {
    // * redirect to a route on btn click
    onActionBtnClick(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({
          name: routeName,
        });
      }
    },

    uppercaseFirstChar,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
