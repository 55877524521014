<template>
  <div class="check-password-form">
    <f-form
      center-form
      @f-form-submit="$emit('f-form-submit', $event)"
    >
      <fieldset class="">
        <legend class="not-visible">Confirm Transaction</legend>
        <div class="form-body">
          <f-password-field
            v-if="showPasswordField && showPwdField"
            :label="passwordLabel"
            field-size="large"
            autocomplete="off"
            name="pwd"
            :validator="checkPassword"
            validate-on-input
          >
            <template #bottom="sProps">
              <f-message
                v-show="sProps.showErrorMessage"
                type="error"
                role="alert"
                with-icon
              >
                Type a password
              </f-message>
            </template>
          </f-password-field>

          <div class="align-center form-buttons">
            <div v-if="errorMessage">
              <f-message
                type="error"
                with-icon
              >{{ errorMessage }}
              </f-message>
              <br/>
            </div>
            <div v-if="notEnoughCurrency">
              <f-message
                type="warning"
                with-icon
                class="align-left"
              >
                Warning: Not enough available {{ currency }} for gas fees. Transfer {{ currency }} to
                your address to proceed.
              </f-message>
              <br/>
            </div>

            <template v-if="!waiting">
              <button
                v-if="showCancelButton"
                type="button"
                class="btn secondary large break-word btn-second"
                style="max-width: 100%;"
                @click="onCancelButtonClick"
              >
                {{ cancelButtonLabel }}
              </button>
              <button
                type="submit"
                class="btn large break-word"
                style="max-width: 100%;"
                :disabled="notEnoughCurrency || disabledSubmit"
              >
                {{ sendButtonLabel }}
              </button>

              <!-- <details>
                  <summary>Gas Settings</summary>
                  <div class="gas-info">
                      Estimated Gas:
                      <f-placeholder :content-loaded="!!gasLimit" :replacement-num-chars="4">
                          {{ formatNumberByLocale(parseInt(gasLimit, 16), 0) }}
                      </f-placeholder>
                  </div>
              </details>-->
            </template>
            <pulse-loader
              v-else
              color="#1969ff"
            ></pulse-loader>
          </div>
        </div>
      </fieldset>
    </f-form>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { mapGetters } from 'vuex';
import { formatNumberByLocale } from '@/filters';
import FForm from '../core/FForm/FForm.vue';
import FPasswordField from '../core/FPasswordField/FPasswordField.vue';
import FMessage from '../core/FMessage/FMessage.vue';

export default {
  name: 'TransactionConfirmationForm',

  components: {
    FMessage,
    FPasswordField,
    FForm,
    PulseLoader,
  },

  props: {
    showPasswordField: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    passwordLabel: {
      type: String,
      default: 'Please enter your wallet password to send the transaction',
    },
    sendButtonLabel: {
      type: String,
      default: 'Send',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    /** Transaction's gas limit */
    gasLimit: {
      type: String,
      default: '',
    },
    /** */
    waiting: {
      type: Boolean,
      default: false,
    },
    /** */
    disabledSubmit: {
      type: Boolean,
      default: false,
    },
    /** */
    tmpPwdCode: {
      type: String,
      default: '',
    },
    /** Currency of the transaction - NETS | CBR | wNETS | WNETS */
    currency: {
      type: String,
      default: 'NETS',
    },
  },

  data() {
    return {
      gasPrice: '',
      showPwdField: true,
      notEnoughCurrency: false,
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),
  },

  created() {
    this._tid = -1;

    this.showPwdField = !this.$fWallet.pwdStorage.isSet(this.tmpPwdCode);

    this.$fWallet.getGasPrice()
      .then((_gasPrice) => {
        this.gasPrice = _gasPrice;
        this.notEnoughNETS();
      });

    if (this.showPasswordField && !this.showPwdField) {
      this._tid = setTimeout(() => {
        this.$fWallet.pwdStorage.clear();
        this.showPwdField = true;
      }, 30000);
    }
  },

  beforeDestroy() {
    if (this._tid > -1) {
      clearTimeout(this._tid);
      this._tid = -1;
    }
  },

  methods: {
    /**
     * @return {Boolean}
     */
    notEnoughNETS() {
      const { currentAccount } = this;
      let price = 0;

      if (this.gasPrice && currentAccount) {
        price = this.$fWallet.getRemainingBalance(currentAccount.balance, this.gasPrice, this.gasLimit);
      }

      this.notEnoughCurrency = price <= 0;

      return price <= 0;
    },

    checkPassword(_value) {
      return _value && _value.length > 0;
    },

    onCancelButtonClick() {
      this.$emit('cancel-button-click');
    },

    formatNumberByLocale,
  },
};
</script>
