/**
 * Устанавливает публично доступную ссылку на локально хранимые иконки токенов.
 * Дает возможность корректно отображать иконки ,в частности, в Метамаске.
 * Иконки должны храниться в папке public
 *
 * @param {DefiTokens} tokens - array of objects
 */
export function setTokensURL(tokens) {
  tokens.forEach((token) => {
    switch (token.symbol) {
      case 'CBR':
        token.logoUrl = `${window.location.origin}/img/tokens/CBR.svg`;
        token.logoURL = `${window.location.origin}/img/tokens/CBR.svg`;
        break;
      case 'wNETS':
        token.logoUrl = `${window.location.origin}/img/tokens/wNETS.svg`;
        token.logoURL = `${window.location.origin}/img/tokens/wNETS.svg`;
        break;
      case 'NETS':
        token.logoUrl = `${window.location.origin}/img/tokens/NETS.svg`;
        token.logoURL = `${window.location.origin}/img/tokens/NETS.svg`;
        break;
      default:
        token.logoUrl = `${window.location.origin}/img/tokens/erc20.svg`;
        token.logoURL = `${window.location.origin}/img/tokens/erc20.svg`;
    }
  });
}

/**
 * Валидация в разделе стейкинга введенного в поле ввода кол-ва CBR
 *
 * @param {string} amount - amount of CBR in input, not parsedFloat or parsedInt yet!
 * @param {int} balance - user's balance of CBR. Only in stakeForm
 * @param {number} max - max allowed input value
 * @param {string} action - stake | undelegate | lock - performed action in the form
 * @param {string} validator - name of the chosen validator. Only in stakeForm
 * @return {string} - error message info
 */
export function checkCBRAmount(amount, balance, max, action, validator) {
  if (isNaN(parseFloat(amount))) {
    return 'Invalid amount';
  }
  if (action === 'stake') {
    if (balance < 0) {
      return 'You have no balance left';
    }
    if (parseInt(amount) > balance) {
      return `You can stake max ${balance} CBR`;
    }
    if (max && parseInt(amount) > max) {
      return `Staking limit reached. You can stake max ${max} CBR on validator ${validator}`;
    }
  }
  if (!amount || parseInt(amount) < 1 || !Number.isInteger(parseFloat(amount))) {
    return `You can ${action} only integer amount, not less than 1 CBR`;
  }
  if (max && parseInt(amount) > max) {
    return `You can ${action} max ${max} CBR`;
  }

  // * amount is correct
  return '';
}

/**
 * Валидация input number, не должно появиться число типа 076
 * (если ввел точку или запятую на русском, она не появится в инпуте)
 *
 * @param {number} amount - введенное в инпут кол-во
 * @return {string} - либо исправленное кол-во, либо просто кол-во, приведенное к строке
 */
export function validateNumberInput(amount) {
  const match = amount.toString()
    .match(/^0\d/g);
  if (match) {
    return amount.substring(1);
  }
  return amount.toString();
}
