<template>
  <div class="unstake-confirmation">
    <tx-confirmation
      :tx="tx"
      confirmation-comp-name="unstake-confirmation"
      send-button-label="Undelegate"
      password-label="Please enter your wallet password to undelegate your CBR"
      :on-send-transaction-success="onSendTransactionSuccess"
      :tmp-pwd-code="tmpPwdCode"
      @change-component="onChangeComponent"
    >
      <h2 class="cont-with-back-btn">
        <span>
          Undelegate CBR - Confirmation
          <span class="f-steps">
            <template v-if="!isLocked"><b>2</b> / 2</template>
            <template v-else><b>3</b> / 3</template>
          </span>
        </span>
        <button
          type="button"
          class="btn light dark"
          @click="onBackBtnClick"
        >Back
        </button>
      </h2>

      <div class="transaction-info">
        <div class="row no-collapse">
          <div class="col-3 f-row-label">Validator</div>
          <div class="col break-word">{{ stakerName }}, {{ formatHexToInt(accountInfo.stakerInfo.id) }}</div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">From</div>
          <div class="col break-word">
            {{ currentAccount.address }}
            <span class="f-row-label no-break-word">( {{ toFTM(currentAccount.balance) }} NETS )</span>
          </div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Undelegate Amount</div>
          <div class="col break-word">{{ dAmount }}</div>
        </div>

        <f-message
          v-if="amountDiff > 0"
          type="warning"
        > {{ amountDiff }} CBR slashing applied
        </f-message>
      </div>

      <template #window-content>
        <ledger-confirmation-content
          :to="tx.to"
          :to-unstake-amount="0"
        />
      </template>
    </tx-confirmation>
  </div>
</template>

<script>
import FMessage from '@/components/core/FMessage/FMessage.vue';
import { mapGetters } from 'vuex';
import sfcUtils from 'fantom-ledgerjs/src/sfc-utils';
import { formatHexToInt } from '@/filters';
import LedgerConfirmationContent from '../LedgerConfirmationContent/LedgerConfirmationContent.vue';
import TxConfirmation from '../TxConfirmation/TxConfirmation.vue';
import { toFTM } from '../../utils/transactions';
import { getRandomInt } from '../../utils';

export default {
  name: 'UnstakeConfirmation',

  components: {
    FMessage,
    LedgerConfirmationContent,
    TxConfirmation,
  },

  props: {
    /** `accountInfo` object from `UnstakeNETS` component. */
    accountInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    /** Amount of CBR tokens to unstake */
    toUnstakeAmount: {
      type: Number,
      default: 1,
    },
    /** Amount of CBR tokens to unlock. (hex number) */
    toUnlockAmount: {
      type: String,
      default: '',
    },
    /** In hex format */
    stakerId: {
      type: String,
      default: '',
    },
    /** */
    tmpPwdCode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tx: {},
      dAmount: this.toUnstakeAmount,
      amountDiff: 0,
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    /**
     * Returns `true` if delegetion is still locked.
     *
     * @return {boolean}
     */
    isLocked() {
      const { accountInfo } = this;

      return (accountInfo && accountInfo.delegation && accountInfo.delegation.isDelegationLocked) || false;
    },

    stakerName() {
      return this.accountInfo && this.accountInfo.stakerInfo && this.accountInfo.stakerInfo.stakerInfo
        ? this.accountInfo.stakerInfo.stakerInfo.name
        : 'Unknown';
    },
  },

  mounted() {
    this.setTx();
  },

  methods: {
    async setTx() {
      const stakerId = formatHexToInt(this.stakerId);
      const unlockedAmount = await this.$fWallet.fetchUnlockedAmount(this.currentAccount.address, this.stakerId);

      // toUnstakeAmount is bigger than unlocked amount
      if (formatHexToInt(unlockedAmount) && this.toUnstakeAmount > formatHexToInt(unlockedAmount)) {
        this.amountDiff = this.toUnstakeAmount - formatHexToInt(unlockedAmount);
      }

      this.tx = await this.$fWallet.getSFCTransactionToSign(
        sfcUtils.prepareToWithdrawDelegationPartTx(getRandomInt(), stakerId, parseInt(this.toUnstakeAmount)),
        this.currentAccount.address,
      );
    },

    onSendTransactionSuccess(_data) {
      this.$emit('change-component', {
        to: 'transaction-success-message',
        from: 'unstake-confirmation',
        data: {
          tx: _data.sendTransaction.hash,
          successMessage: 'Undelegation Successful',
          continueTo: 'staking-info',
          continueToParams: {
            stakerId: this.stakerId,
          },
        },
      });
    },

    onBackBtnClick() {
      this.$emit('change-component', {
        to: 'unstake-n-e-t-s',
        from: 'unstake-confirmation',
        data: {
          accountInfo: this.accountInfo,
          stakerId: this.stakerId,
        },
      });
    },

    /**
     * Re-target `'change-component'` event.
     *
     * @param {object} _data
     */
    onChangeComponent(_data) {
      this.$emit('change-component', _data);
    },

    toFTM,
    formatHexToInt,
  },
};
</script>
