<template>
  <div
    class="contact-list"
    :class="{ 'edit-mode': editMode }"
    @click="onContactListClick"
    @keyup="onContactListKeyup"
  >
    <ul class="no-markers">
      <li
        v-for="(contact, index) in cContacts"
        :key="contact.address"
        @mouseover="onContactCardMouseOver(index, $event.target)"
      >
        <f-card hover>
          <h3
            slot="title"
            class="title"
            :data-address="contact.address"
          >
            <span class="row no-collapse align-items-start">
                <span class="col">
                    <span class="address-col">
                        <account-name
                          v-if="pickMode"
                          :account="contact"
                          class="value clickable"
                        />
                        <a
                          v-else
                          class="value clickable"
                          :href="addressUrl(contact)"
                          target="_blank"
                        >
                            <account-name :account="contact"/>
                        </a>
                    </span>
                    <span class="label">
                        {{ $fWallet.getBlockchainLabel(contact.blockchain) }}
                    </span>
                </span>
                <div class="action-btns">
                    <f-copy-button
                      :id="`contact-copy-btn-${index}`"
                      :custom-id="`contact-copy-btn-${index}`"
                      :text="contact.address"
                      :default-icon-size="16"
                      class="btn light same-size round btn-copy"
                    >
                        <template #popover-text>
                            Address copied to clipboard.
                        </template>
                    </f-copy-button>

                    <button
                      v-if="editMode"
                      :id="`contact-edit-btn-${index}`"
                      class="btn large_ light same-size round btn-edit"
                      :data-address="contact.address"
                      :data-index="index"
                    >
                        <icon
                          data="@/assets/svg/monochrome/Options/Edit.svg"
                          width="16"
                          height="16"
                          aria-hidden="true"
                        />
                    </button>
                </div>
            </span>
          </h3>
        </f-card>
      </li>
    </ul>

    <p
      v-if="editMode"
      class="add-contact"
    >
      <button
        class="btn large btn-dark"
        type="button"
        @click="onAddContactBtnClick"
      >
        <icon
          data="@/assets/svg/plus.svg"
          width="16"
          height="16"
          aria-hidden="true"
        />
        New Contact
      </button>
    </p>

    <contact-detail-window
      v-if="editMode"
      ref="contactDetailWindow"
      :action="contactAction"
      :contact-data="contactData"
      @contact-detail-form-data="onContactDetailFormData"
    />
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="popoverAttachId"
      :attach-position="popoverAttachPosition"
      :preferred-attach-position="popoverAttachPosition"
      :attach-margin="[1, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FWindow from '../core/FWindow/FWindow.vue';
import FCard from '../core/FCard/FCard.vue';
import AccountName from '../AccountName/AccountName.vue';
import FCopyButton from '../core/FCopyButton/FCopyButton.vue';
import ContactDetailWindow from '../windows/ContactDetailWindow/ContactDetailWindow.vue';
import { ADD_CONTACT, UPDATE_CONTACT } from '../../store/actions.type';
import { isAriaAction } from '../../utils/aria';
import appConfig from '../../../app.config';

export default {
  name: 'ContactList',

  components: {
    ContactDetailWindow,
    FCopyButton,
    AccountName,
    FCard,
    FWindow,
  },

  props: {
    /** Show edit icon and 'new contact' button. */
    editMode: {
      type: Boolean,
      default: false,
    },
    /** Emit 'contact-picked' event when whole contact element is clicked. */
    pickMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Filter contacts by given blockchain.
     *
     * @type {WalletBlockchain}
     */
    filterByBlockchain: {
      type: String,
      default: '',
      validator(_value) {
        return !_value || ['fantom', 'ethereum', 'binance'].indexOf(_value) !== -1;
      },
    },
  },

  data() {
    return {
      /**
       * Type of action with contact.
       *
       * @type {WalletContactAction}
       */
      contactAction: 'new',
      /** @type {WalletContact} */
      contactData: {},
      popoverCreated: false,
      popoverAttachId: '',
      popoverAttachPosition: '',
      popoverText: '',
    };
  },

  computed: {
    ...mapGetters(['contacts', 'getContactAndIndexByAddress', 'getContactsByBlockchain']),

    cContacts() {
      if (this.filterByBlockchain) {
        return this.getContactsByBlockchain(this.filterByBlockchain);
      }

      return this.contacts;
    },
  },

  methods: {
    /**
     * @param {Event} _event
     */
    getContactByEvent(_event) {
      const elem = _event.target.closest('[data-address]');

      return this.getContactAndIndexByAddress(elem ? elem.getAttribute('data-address') : '');
    },

    addressUrl(_contact) {
      const { address } = _contact;
      let url = '';

      switch (_contact.blockchain) {
        case 'fantom':
          url = `${appConfig.explorerUrl}address/${address}`;
          break;
        case 'ethereum':
          url = `${appConfig.ethereumExplorerUrl}address/${address}`;
          break;
        case 'binance':
          url = `${appConfig.binanceExplorerUrl}address/${address}`;
          break;
      }

      return url;
    },

    /**
     * @param {Event} _event
     */
    onContactListClick(_event) {
      this.hidePopover();
      if (
        !this.onEditContactButtonClick(_event) &&
        !this.editMode &&
        this.pickMode &&
        !_event.target.closest('.btn')
      ) {
        const contact = this.getContactByEvent(_event);

        if (contact.contact) {
          this.$emit('contact-picked', contact.contact.address);
        }
      }
    },

    /**
     * Shows or hides popovers for Edit and Copy buttons on hover
     * @param {string} _index - index of hovered contact card
     * @param {Event: target} _target
     */
    onContactCardMouseOver(_index, _target) {
      if (!isNaN(_index) && _index > -1) {
        const editBtnId = `#contact-edit-btn-${_index}`;
        const copyBtnId = `#contact-copy-btn-${_index}`;
        if (_target.closest(editBtnId)) {
          this.showPopover(editBtnId, 'Edit contact', 'top');
        }
        if (_target.closest(copyBtnId)) {
          this.showPopover(copyBtnId, 'Copy address to clipboard', 'bottom');
        }
        if (!_target.closest(editBtnId) && !_target.closest(copyBtnId)) {
          this.hidePopover();
        }
      }
    },

    /**
     * Shows popovers for Edit and Copy buttons on hover
     * @param {string} _id - id of button that is hovered
     * @param {string} _text - text of the popover
     * @param {string} _position - position of the popover
     * @param {Event: target} _target
     */
    showPopover(id, text, position) {
      if (text) {
        this.popoverAttachId = id;
        this.popoverAttachPosition = position;
        this.popoverText = text;
        this.popoverCreated = true;
      }
    },

    //* * Hides popovers for Edit and Copy buttons on hover */
    hidePopover() {
      this.popoverCreated = false;
    },

    /**
     * @param {Event} _event
     */
    onEditContactButtonClick(_event) {
      const contact = this.getContactByEvent(_event);

      if (contact.contact && _event.target.closest('.btn-edit')) {
        this.contactAction = 'edit';
        this.contactData = {
          ...contact.contact,
          order: contact.index + 1,
        };

        this.$refs.contactDetailWindow.show();

        return true;
      }

      return false;
    },

    onAddContactBtnClick() {
      this.contactAction = 'new';
      this.contactData = {};

      this.$refs.contactDetailWindow.show();
    },

    /**
     * Called when `ContactSettingsForm` is submited.
     *
     * @param {object} _data
     */
    onContactDetailFormData(_data) {
      if (this.contactAction === 'new') {
        this.$store.dispatch(ADD_CONTACT, _data);
      } else if (this.contactAction === 'edit') {
        this.$store.dispatch(UPDATE_CONTACT, _data);
      }
    },

    /**
     * @param {KeyboardEvent} _event
     */
    onContactListKeyup(_event) {
      if (this.pickMode && isAriaAction(_event) && !_event.target.closest('.btn')) {
        const contact = this.getContactByEvent(_event);

        if (contact.contact) {
          this.$emit('contact-picked', contact.contact.address);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
