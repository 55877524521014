<template>
  <div class="defi-ftrade-confirmation">
    <tx-confirmation
      v-if="hasCorrectParams"
      :tx="tx"
      card-off
      :send-button-label="sendButtonLabel"
      :password-label="passwordLabel"
      :on-send-transaction-success="onSendTransactionSuccess"
      @change-component="onChangeComponent"
    >
      <h1 class="with-back-btn">
        <f-back-button
          v-if="!params.steps || params.step === 1"
          :route-name="backButtonRoute"
          :params="{ fromToken: params.fromToken, toToken: params.toToken }"
        />
        Confirmation
        <template v-if="params.steps">({{ params.step }}/{{ params.steps }})</template>
      </h1>

      <div class="confirmation-info">
        <template>
          You're trading
          <span class="price no-break-word">
            {{ params.fromValue.toFixed($defi.getTokenDecimals(params.fromToken)) }} {{ fromTokenSymbol }}
          </span>
          &#10141;
          <span
            v-if="params.toToken.symbol !== 'CBR'"
            class="price no-break-word"
          >
            {{ params.toValue.toFixed($defi.getTokenDecimals(params.toToken)) }} {{ toTokenSymbol }}
          </span>
          <span
            v-else
            class="price no-break-word"
          >
            {{ params.toValue }} {{ toTokenSymbol }}
          </span>
        </template>
      </div>

      <template #window-content>
        <ledger-confirmation-content
          :to="tx.to"
          :amount="0"
        />
      </template>
    </tx-confirmation>
    <template v-else>
      <f-message
        type="info"
        role="alert"
        class="big"
      > Trade tokens first, please.
      </f-message>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import wftmUtils from 'fantom-ledgerjs/src/wftm-utils';
import TxConfirmation from '../../components/TxConfirmation/TxConfirmation.vue';
import LedgerConfirmationContent from '../../components/LedgerConfirmationContent/LedgerConfirmationContent.vue';
import { Web3 } from '../../plugins/fantom-web3-wallet';
import { toFTM } from '../../utils/transactions';
import FBackButton from '../../components/core/FBackButton/FBackButton.vue';
import { getAppParentNode } from '../../app-structure';
import FMessage from '../../components/core/FMessage/FMessage.vue';
import { lowercaseFirstChar } from '../../utils';
import appConfig from '../../../app.config';

export default {
  name: 'DefiFTradeConfirmation',

  components: {
    FMessage,
    FBackButton,
    LedgerConfirmationContent,
    TxConfirmation,
  },

  data() {
    return {
      priceDecimals: 6,
      tx: {},
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    compName() {
      return this.$route.fullPath.includes('CBR') ? 'defi-ftrade-c-b-r' : 'defi-ftrade';
    },

    /**
     * @return {{fromValue: number, toValue: number, fromToken: DefiToken, toToken: DefiToken}}
     */
    params() {
      const { $route } = this;

      return $route && $route.params ? $route.params : {};
    },

    hasCorrectParams() {
      return !!this.params.fromValue;
    },

    sendButtonLabel() {
      return 'Submit';
    },

    passwordLabel() {
      return 'Please enter your wallet password to trade your tokens';
    },

    fromTokenSymbol() {
      return this.$defi.getTokenSymbol(this.params.fromToken);
    },

    toTokenSymbol() {
      return this.$defi.getTokenSymbol(this.params.toToken);
    },

    backButtonRoute() {
      const parentNode = getAppParentNode(`${this.compName}-confirmation`);

      return parentNode ? parentNode.id : '';
    },
  },

  created() {
    if (!this.hasCorrectParams) {
      // * redirect to <this.compName>
      setTimeout(() => {
        this.$router.replace({ name: this.compName });
      }, 3000);
    } else {
      this.setTx();
    }
  },

  methods: {
    async setTx() {
      const { params } = this;
      const { fromToken } = params;
      const { toToken } = params;
      let txToSign;

      if (!fromToken || !toToken) {
        return;
      }

      if (fromToken.symbol === 'NETS' && toToken.symbol === 'CBR') {
        txToSign = wftmUtils.exchangeCoreToToken(appConfig.cbrSwapContract, params.fromValue.toString());
        // * hex is required in fWallet getEstimateGas
        txToSign.value = Web3.utils.toHex(txToSign.value);
      } else if (fromToken.symbol === 'NETS' && lowercaseFirstChar(toToken.symbol) === 'wNETS') {
        txToSign = wftmUtils.defiWrapFtm(
          toToken.address,
          Web3.utils.toHex(this.$defi.shiftDecPointRight(params.toValue.toString(), toToken.decimals)),
        );
      } else if (lowercaseFirstChar(fromToken.symbol) === 'wNETS' && toToken.symbol === 'NETS') {
        const amount = Web3.utils.toHex(
          this.$defi.shiftDecPointRight(params.fromValue.toString(), toToken.decimals),
        );

        txToSign = wftmUtils.defiUnwrapFtm(
          fromToken.address,
          params.max || this.$defi.compareBN(amount, fromToken.availableBalance) === 1
            ? fromToken.availableBalance
            : amount,
        );
      }

      if (txToSign) {
        this.tx = await this.$fWallet.getDefiTransactionToSign(txToSign, this.currentAccount.address);
      }
    },

    onSendTransactionSuccess(_data) {
      const params = {
        tx: _data.sendTransaction.hash,
        title: 'Success',
        continueTo: this.compName,
      };
      const transactionSuccessComp = `${this.compName}-transaction-success-message`;

      this.$router.replace({
        name: transactionSuccessComp,
        params,
      });
    },

    /**
     * Re-target `'change-component'` event.
     *
     * @param {object} _data
     */
    onChangeComponent(_data) {
      const transactionRejectComp = `${this.compName}-transaction-reject-message`;

      if (_data.to === 'transaction-reject-message') {
        this.$router.replace({
          name: transactionRejectComp,
          params: {
            continueTo: this.compName,
            continueToParams: {
              fromToken: { ...this.params.fromToken },
              toToken: { ...this.params.toToken },
            },
          },
        });
      }
    },

    toFTM,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
