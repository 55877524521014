<template>
  <div class="tx-confirmation">
    <f-card
      class="f-card-double-padding f-data-layout"
      :class="[windowMode ? 'column-layout column-layout--body-footer' : '']"
      :off="cardOff"
    >
      <div :class="{ 'center-v': windowMode }">
        <slot></slot>
      </div>

      <div>
        <ledger-message
          :error="error"
          @ledger-status-code="onLedgerStatusCode"
        />

        <transaction-confirmation-form
          v-if="!hideTxForm"
          :error-message="errorMsg"
          :show-password-field="!currentAccount.isLedgerAccount && !currentAccount.isMetamaskAccount"
          :password-label="passwordLabel"
          :send-button-label="sendButtonLabel"
          :waiting="waiting"
          :disabled-submit="disabledSubmit"
          :gas-limit="dGasLimit"
          :tmp-pwd-code="tmpPwdCode"
          :cancel-button-label="cancelButtonLabel"
          :show-cancel-button="showCancelButton"
          :currency="currency"
          @f-form-submit="onFFormSubmit"
          @cancel-button-click="$emit('cancel-button-click', $event)"
        />
      </div>
    </f-card>

    <f-window
      v-if="currentAccount.isLedgerAccount"
      ref="confirmationWindow"
      modal
      title="Transaction Confirmation"
      style="max-width: 800px;"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
    >
      <!--                <icon data="@/assets/svg/nano-s-confirm-tx.svg" width="300" height="91" />-->
      <div class="align-center"><img
        src="img/nano-s-confirm-tx.png"
        alt="fantom nano device"
      /><br/><br/></div>

      <p class="align-center">Please confirm this transaction on your Ledger device:</p>

      <slot name="window-content"></slot>
    </f-window>

    <f-window
      v-if="currentAccount.isMetamaskAccount"
      ref="metamaskNoticeWindow"
      modal
      title="Notice"
      class="double-body-padding"
      style="max-width: 560px;"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
    >
      <div class="align-center">
        <div v-if="!$metamask.isInstalled()">
          Metamask is not installed.
        </div>
        <div v-else-if="!$metamask.isCorrectChainId()">
          Please, select Aria chain in Metamask.
        </div>
        <div v-else-if="metamaskAccount !== currentAccount.address">
          Please, select account <b>{{ currentAccount.address }}</b> in Metamask.
        </div>
      </div>
    </f-window>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { changeData } from '@/api';
import sendTransactionMutation from '@/api/mutations/transactions/sendTransaction';
import FCard from '../core/FCard/FCard.vue';
import FWindow from '../core/FWindow/FWindow.vue';
import LedgerMessage from '../LedgerMessage/LedgerMessage.vue';
import TransactionConfirmationForm from '../forms/TransactionConfirmationForm.vue';
import { U2FStatus } from '../../plugins/fantom-nano';
import { UPDATE_ACCOUNT_BALANCE } from '../../store/actions.type';
import appConfig from '../../../app.config';

/**
 * Base component for other 'transaction confirmation and send' components.
 */
export default {
  name: 'TxConfirmation',

  components: {
    TransactionConfirmationForm,
    LedgerMessage,
    FWindow,
    FCard,
  },

  props: {
    /** Transaction object to send */
    tx: {
      type: Object,
      default() {
        return null;
      },
      required: true,
    },
    /** Name of confirmation component in cebab case */
    confirmationCompName: {
      type: String,
      default: '',
    },
    /** Label for button in TransactionConfirmationForm component */
    sendButtonLabel: {
      type: String,
      default: '',
    },
    /** Label for button in TransactionConfirmationForm component */
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    /** Label for password field in TransactionConfirmationForm component */
    passwordLabel: {
      type: String,
      default: '',
    },
    /**
     * Function called when transaction was successful
     * @param {object} _data
     */
    onSendTransactionSuccess: {
      type: Function,
      default: null,
    },
    /** Don't render card */
    cardOff: {
      type: Boolean,
      default: false,
    },
    /** Set temporary password */
    setTmpPwd: {
      type: Boolean,
      default: false,
    },
    /** Component is placed in FWindow */
    windowMode: {
      type: Boolean,
      default: false,
    },
    /** Hide transaction form */
    hideTxForm: {
      type: Boolean,
      default: false,
    },
    /** */
    tmpPwdCode: {
      type: String,
      default: '',
    },
    /** Count of usage of temporary password */
    tmpPwdCount: {
      type: Number,
      default: 1,
    },
    /** Currency of the transaction - NETS | CBR | wNETS | WNETS */
    currency: {
      type: String,
      default: 'NETS',
    },
  },

  data() {
    return {
      errorMsg: '',
      error: null,
      waiting: false,
      disabledSubmit: true,
      dGasLimit: '',
    };
  },

  computed: {
    ...mapState('metamask', {
      metamaskAccount: 'account',
      metamaskChainId: 'chainId',
    }),

    ...mapGetters(['currentAccount']),
  },

  watch: {
    metamaskAccount() {
      if (this.areMetamaskParamsOk()) {
        this.$refs.metamaskNoticeWindow.hide();
      } else {
        this.$refs.metamaskNoticeWindow.show();
      }
    },

    metamaskChainId() {
      if (this.areMetamaskParamsOk()) {
        this.$refs.metamaskNoticeWindow.hide();
      } else {
        this.$refs.metamaskNoticeWindow.show();
      }
    },

    tx() {
      this.init();
    },
  },

  methods: {
    async init() {
      this.dGasLimit = this.tx.gasLimit;
      this.disabledSubmit = false;
    },

    sendTransaction(_rawTransaction) {
      changeData(sendTransactionMutation, {
        tx: _rawTransaction,
      })
        .then((_data) => {
          const { hash } = _data.sendTransaction;
          this.$fWallet.waitForTransaction(hash)
            .then((txReceipt) => {
              if (txReceipt && this.onSendTransactionSuccess) {
                this.onSendTransactionSuccess(_data);
              }
            });
        })
        .catch((_error) => {
          this.errorMsg = _error;
        });
    },

    async onFFormSubmit(_event) {
      const { currentAccount } = this;
      const fWallet = this.$fWallet;
      const { pwd } = _event.detail.data;
      let rawTx = null;
      _event.detail.data.pwd = '';

      if (currentAccount && this.tx && fWallet.isValidAddress(this.tx.to)) {
        this.tx.nonce = await fWallet.getTransactionCount(currentAccount.address);
        this.tx.nonce = `0x${this.tx.nonce.toString(16)}`;
        this.tx.chainId = appConfig.chainId;

        if (!this.tx.gas) {
          this.errorMsg = this.tx._error || 'Transaction Error';
          return;
        }

        delete this.tx._error;

        if (currentAccount.keystore) {
          delete this.tx.gasLimit;

          if (pwd || fWallet.pwdStorage.isSet(this.tmpPwdCode)) {
            try {
              rawTx = await fWallet.signTransaction(
                this.tx,
                currentAccount.keystore,
                pwd,
                this.tmpPwdCode,
              );

              if (this.setTmpPwd && this.tmpPwdCount > 0) {
                fWallet.pwdStorage.set(pwd, this.tmpPwdCount, this.tmpPwdCode);
              }
            } catch (_error) {
              console.error(_error);
              this.errorMsg = _error.toString();
              // this.errorMsg = 'Invalid password';
            }
          }
        } else if (currentAccount.isLedgerAccount) {
          delete this.tx.gas;

          try {
            this.$refs.confirmationWindow.show();

            rawTx = await this.$fNano.signTransaction(
              this.tx,
              currentAccount.accountId,
              currentAccount.addressId,
            );

            this.$refs.confirmationWindow.hide('fade-leave-active');
          } catch (_error) {
            this.error = _error;
            this.$refs.confirmationWindow.hide();
            // this.errorMsg = _error.toString();
          }
        } else if (currentAccount.isMetamaskAccount) {
          if (this.areMetamaskParamsOk()) {
            const from = currentAccount.address;
            const { to } = this.tx;

            this.waiting = true;
            const txHash = await this.$metamask.signTransaction({ ...this.tx }, currentAccount.address);
            // * ждем, пока транзакция пройдет до конца в ММ
            const txReceipt = await this.$fWallet.waitForTransaction(txHash);
            if (this.onSendTransactionSuccess && txHash && txReceipt) {
              this.onSendTransactionSuccess({
                sendTransaction: {
                  hash: txHash,
                  from,
                  to,
                },
              });
            }
          } else {
            this.$refs.metamaskNoticeWindow.show();
          }

          this.waiting = false;
        }

        if (rawTx) {
          this.sendTransaction(rawTx);

          setTimeout(() => {
            this.$store.dispatch(UPDATE_ACCOUNT_BALANCE);
          }, 3000);
        }
      }
    },

    areMetamaskParamsOk() {
      return (
        this.$metamask.isInstalled() &&
        this.metamaskAccount.toLowerCase() === this.currentAccount.address.toLowerCase() &&
        this.$metamask.isCorrectChainId()
      );
    },

    /**
     * Triggered on 'ledger-status-code' event.
     *
     * @param {string} _code
     */
    onLedgerStatusCode(_code) {
      if (_code === U2FStatus.USER_REJECTED_REQUESTED_ACTION) {
        this.$emit('change-component', {
          to: 'transaction-reject-message',
          from: this.confirmationCompName,
        });
      }
    },
  },
};
</script>
