<template>
    <span
      class="f-back-button"
      @click="onClick"
    >
        <slot>
            <button
              :id="id"
              class="btn light large same-size round"
              @mouseenter="showPopover"
              @mouseleave="hidePopover"
            >
                <icon
                  data="@/assets/svg/arrow-left.svg"
                  aria-hidden="true"
                />
            </button>
        </slot>
        <f-window
          v-if="popoverCreated"
          custom-class="blue-popover"
          popover
          visible
          position="absolute"
          :attach-to="`#${id}`"
          attach-position="auto"
          preferred-attach-position="bottom"
          :attach-margin="[1, 4, 4, 4]"
          :with-header="false"
          animation-in="scale-center-enter-active"
          animation-out="scale-center-leave-active"
          style="width: auto; max-width: 360px;"
        >
            <slot name="popover-text">{{ popoverText }}</slot>
        </f-window>
    </span>
</template>

<script>
import FWindow from '../FWindow/FWindow.vue';
import { getUniqueId } from '../../../utils';

export default {
  name: 'FBackButton',

  components: { FWindow },

  props: {
    routeName: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      id: getUniqueId(),
      popoverCreated: false,
      popoverAttachPosition: 'bottom',
      popoverText: 'Back',
    };
  },

  methods: {
    onClick() {
      if (this.routeName) {
        this.$router.replace({
          name: this.routeName,
          params: this.params,
        });
      }
    },

    /**
     * Shows popover for back button on hover
     */
    showPopover() {
      this.popoverCreated = true;
    },

    /**
     *  Hides popover for back button on hover
     */
    hidePopover() {
      this.popoverCreated = false;
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
