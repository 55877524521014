<template>
  <div class="delegation-lock">
    <f-card class="f-card-double-padding f-data-layout">
      <h2 class="align-left cont-with-back-btn">
                <span>
                    Lock Delegation <span
                  v-if="canLockDelegation"
                  class="f-steps"
                ><b>1</b> / 2</span>
                </span>
        <button
          type="button"
          class="btn light dark"
          @click="onPreviousBtnClick"
        >Back
        </button>
      </h2>

      <div class="delegation-lock-body">
        <f-placeholder
          v-if="canLockDelegation"
          :content-loaded="canLockDelegation"
          block
          style="min-height: 200px;"
        >
          <div class="defi-price-input">
            <f-auto-resize-input
              ref="lockDaysInputAR"
              min-width="48px"
            >
              <input
                :id="`lock-days-${id}`"
                ref="lockDaysInput"
                :value="lockTimeInputValue"
                type="number"
                placeholder="0"
                step="1"
                :min="minLockTime"
                :max="maxLockTime"
                class="text-input no-style"
                @change="onLockTimeInputChange"
                @input="onLockTimeInputInput"
                @focus="onLockTimeInputFocus"
                @keydown="onLockTimeInputKeydown"
              />
            </f-auto-resize-input>
            <label
              :for="`lock-days-${id}`"
              class="days-label"
            >{{ lockTimeMeasurement }}</label>
          </div>

          <div class="f-slider-wrap">
            <f-slider
              ref="slider"
              v-model="lockTimeSliderValue"
              :step="sliderStep.toString()"
              :min="minLockTime.toString()"
              :max="maxLockTime.toString()"
              use-lower-fill-bar
              clickable-labels
              :labels="sliderLabels"
            >
              <template #top="sProps">
                <label
                  :for="sProps.inputId"
                  class="not-visible"
                >slider label</label>
              </template>
            </f-slider>
          </div>

          <f-input
            v-model="toLockAmount"
            label="Amount"
            field-size="large"
            type="number"
            autocomplete="off"
            min="1"
            step="1"
            name="amount"
            class="to-lock-amount"
            :validator="checkAmount"
            validate-on-input
          >
            <template #top="sProps">
              <div class="input-label-layout">
                <label :for="sProps.inputId">{{ sProps.label }}</label>
                <button
                  type="button"
                  class="btn light small"
                  @click="onEntireDelegationClick"
                >
                  Entire Delegation
                </button>
              </div>
            </template>
            <template #bottom="sProps">
              <f-message
                v-show="sProps.showErrorMessage"
                type="error"
                role="alert"
                with-icon
              >
                {{ toLockAmountErrMsg }}
              </f-message>
            </template>
          </f-input>
        </f-placeholder>

        <f-message
          v-if="lockedUntilDate"
          type="info"
          role="alert"
          class="big"
        >
          Delegation will be locked until <b>{{ lockedUntilDate }}</b>
        </f-message>
        <f-message
          v-if="message"
          type="info"
          role="alert"
          class="big"
        >
          {{ message }}
        </f-message>

        <div class="form-buttons align-center">
          <button
            v-if="canLockDelegation"
            type="submit"
            class="btn large"
            :disabled="!canLockDelegation || !valueIsCorrect || !!toLockAmountErrMsg"
            @click="onSubmit"
          >
            Ok, lock
          </button>
        </div>
      </div>
    </f-card>
  </div>
</template>

<script>
import FCard from '@/components/core/FCard/FCard.vue';
import FInput from '@/components/core/FInput/FInput.vue';
import FMessage from '@/components/core/FMessage/FMessage.vue';
import FAutoResizeInput from '@/components/core/FAutoResizeInput/FAutoResizeInput.vue';
import FSlider from '@/components/core/FSlider/FSlider.vue';
import FPlaceholder from '@/components/core/FPlaceholder/FPlaceholder.vue';
import { mapGetters, mapState } from 'vuex';
import { getUniqueId } from '@/utils';
import { formatDate, timestampToDate, formatHexToInt } from '@/filters';
import { parseSeconds, convertToSeconds } from '@/utils/time';
import { checkCBRAmount, validateNumberInput } from '@/utils/defi-tokens';

export default {
  name: 'DelegationLock',

  components: {
    FPlaceholder,
    FSlider,
    FAutoResizeInput,
    FMessage,
    FCard,
    FInput,
  },

  props: {
    /**
     * Staker id in hex format
     */
    stakerId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      delegation: null,
      validator: null,
      // * error message when can't lock
      message: '',
      lockedUntilDate: '',
      // * v-model for slider - in seconds
      lockTimeSliderValue: '',
      // * value of input
      lockTimeInputValue: '',
      // *  in sfcConfig minLockupDuration. Default is 14 days
      sfcMinLock: 14 * 24 * 60 * 60,
      // * v-model for choosing amount for locking
      toLockAmount: '',
      amountDelegated: 0,
      unlockedAmount: 0,
      toLockAmountErrMsg: '',
      // * label near input. Depends on the minLockTime
      lockTimeMeasurement: 'days',
      defaultParsedMinLockTime: 14,
      id: getUniqueId(),
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    ...mapState(['breakpoints']),

    /**
     * Минимальный период lock - число без единиц измерения
     * @return {number}
     */
    minLockTime() {
      return this.sfcMinLock;
    },

    /**
     * Максимальный период lock - число без единиц измерения
     * @return {number}
     */
    maxLockTime() {
      return this.validatorLockedUntil - this.now();
    },

    /**
     * Минимальный период lock - число и единицы измерения
     * Используется в сообщении об ощибке, когда нельзя lock
     * @return {string} - e.g. 1 day
     */
    minLockTimeMsg() {
      return parseSeconds(this.sfcMinLock);
    },

    /**
     * Максимальный период lock - число и единицы измерения
     * Используется в sliderLabels
     * @return {string} - e.g. 1 day
     */
    maxLockTimeMsg() {
      return parseSeconds(this.validatorLockedUntil - this.now());
    },

    /**
     * @return {boolean}
     */
    canLockDelegation() {
      /**
       * первая часть условия показывает,
       * не кончается ли у самого валидатора доступный период для lock раньше, чем sfcMinLock
       */
      return this.validatorLockedUntil - this.now() > this.sfcMinLock && this.minLockTime < this.maxLockTime;
    },

    /**
     * Проверка на корректность введенного в поле ввода числа.
     * Число из инпута должно совпадать с откорректированным числом
     * @return {boolean}
     */
    valueIsCorrect() {
      const seconds = convertToSeconds(this.lockTimeInputValue, this.lockTimeMeasurement.trim());
      return seconds === this.correctLockTimesInputValue(seconds);
    },

    /**
     * Время, до которого сам валидатор принимает lock
     * @return {number} Timestamp.
     */
    validatorLockedUntil() {
      return this.validator ? formatHexToInt(this.validator.lockedUntil) : 0;
    },

    /**
     * Время, до которого delegation is locked, выбранный пользователем период
     * @return {number} Timestamp.
     */
    delegationLockedUntil() {
      return this.delegation ? formatHexToInt(this.delegation.lockedUntil) : 0;
    },

    /**
     * @return {[string, string]}
     */
    sliderLabels() {
      return [this.minLockTimeMsg, this.maxLockTimeMsg];
    },

    /**
     * Вычисление шага слайдера на основе текущих единиц измерения
     * @return {number} - step in seconds
     */
    sliderStep() {
      return convertToSeconds(1, this.lockTimeMeasurement.trim());
    },
  },

  watch: {
    // * value in seconds
    lockTimeSliderValue(_value, _oldValue) {
      if (_value !== _oldValue) {
        const parsedTimeObj = parseSeconds(parseInt(_value), true, false);
        this.lockTimeInputValue = parsedTimeObj ? parsedTimeObj.time : this.defaultParsedMinLockTime;
        this.lockTimeMeasurement = parsedTimeObj ? parsedTimeObj.measurement : 'days';
        this.updateARInput();
        this.updateLockTimeInputColor(
          convertToSeconds(this.lockTimeInputValue, this.lockTimeMeasurement.trim()),
        );
        this.updateMessage();
      }
    },

    breakpoints() {
      this.updateARInput();
    },
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      // * fetch and set delegation and validator
      const data = await Promise.all([
        this.$fWallet.fetchDelegation(this.currentAccount.address, this.stakerId),
        this.$fWallet.getStakerById(this.stakerId),
      ]);
      const sfcConfig = await this.$fWallet.getSFCConfig();

      this.sfcMinLock = sfcConfig.minLockupDuration.num;

      [this.delegation, this.validator] = data;

      this.amountDelegated = formatHexToInt(this.delegation.amountDelegated);
      this.unlockedAmount = formatHexToInt(this.delegation.unlockedAmount);
      this.toLockAmount = this.amountDelegated.toString(10);

      this.lockTimeSliderValue = this.minLockTime.toString();
      const parsedTimeObj = parseSeconds(this.sfcMinLock, true, false);
      this.lockTimeInputValue = parsedTimeObj ? parsedTimeObj.time : this.defaultParsedMinLockTime;
      this.lockTimeMeasurement = parsedTimeObj ? parsedTimeObj.measurement : 'days';
      this.updateMessage();

      this.$nextTick(() => {
        this.updateARInput();
      });
    },

    /**
     * Validator for `amount` input field.
     *
     * @param {String} _value
     * @return {Boolean}
     */
    checkAmount(_value) {
      // не допускаем наличия числа типа 076
      this.toLockAmount = validateNumberInput(this.toLockAmount);
      this.toLockAmountErrMsg = checkCBRAmount(_value, '', this.unlockedAmount, 'lock');

      return !this.toLockAmountErrMsg;
    },

    updateMessage() {
      this.message = '';
      this.lockedUntilDate = '';

      if (!this.canLockDelegation) {
        if (this.minLockTime >= this.maxLockTime && this.maxLockTime > 0) {
          this.message = 'You have reached maximum time to lock delegation.';
        } else {
          // * вторая часть предложения говорит о том,
          //* что время лока не "влезает" до того времени, когда валидатор может принимать лок
          this.message = `Validator doesn't lock delegations or lock time is less than ${this.minLockTimeMsg}.`;
        }
      } else if (this.valueIsCorrect) {
        this.lockedUntilDate = this.lockedUntil();
      }
    },

    updateARInput() {
      const eInput = this.$refs.lockDaysInputAR;

      if (eInput) {
        this.$nextTick(() => {
          eInput.update();
        });
      }
    },

    /**
     * Установить значение в слайдере при вводе в инпут.
     *
     * @param {number} _value - in seconds
     */
    updateLockTimeSliderValue(_value) {
      this.lockTimeSliderValue = _value.toString();
    },

    /**
     * Get current timestamp in seconds.
     *
     * @return {number}
     */
    now() {
      return Math.floor(new Date().getTime() / 1000);
    },

    /**
     * Date in message above the slider. Time is shown if measurement is not in days
     * @return {string} Formatted date.
     */
    lockedUntil() {
      const lockTimeSeconds = convertToSeconds(this.lockTimeInputValue, this.lockTimeMeasurement.trim());
      const isDaysMeasurement =
        this.lockTimeMeasurement.trim() === 'days' || this.lockTimeMeasurement.trim() === 'day';
      return formatDate(timestampToDate(this.now() + lockTimeSeconds), false, !isDaysMeasurement);
    },

    /**
     * При вводе в инпут показать цветом правильность ввода. Введенное значение при этом не исправляется
     * @param {number} _value - in seconds
     */
    updateLockTimeInputColor(_value) {
      const eInput = this.$refs.lockDaysInput;

      if (eInput) {
        if (_value !== this.correctLockTimesInputValue(_value)) {
          eInput.classList.add('invalid');
        } else {
          eInput.classList.remove('invalid');
        }
      }
    },

    /**
     * Проверка, что введенное число больше minLockTime и меньше maxLockTime
     * @param {number} _value - must be in seconds, comes from input
     * @return {number} - closest correct value, either min or max, in seconds
     */
    correctLockTimesInputValue(_value) {
      const correctedTime = Math.min(Math.max(_value, this.minLockTime), this.maxLockTime);
      return correctedTime;
    },

    /**
     * При коррекции correctLockTimesInputValue также корректирукется label возле инпута с названием единиц измерения
     * @param {number} _value - must be in seconds, from correctLockTimesInputValue
     * @return {string} - name of measurement
     */
    correctLockTimeMeasurement(_value) {
      const parsedFromSecondsObj = parseSeconds(_value, true, false);
      return parsedFromSecondsObj ? parsedFromSecondsObj.measurement : 'days';
    },

    /**
     * @param {InputEvent} _event
     */
    onLockTimeInputChange(_event) {
      const correctedTime = this.correctLockTimesInputValue(
        convertToSeconds(_event.target.value, this.lockTimeMeasurement.trim()),
      );
      const convertedTimeObj = parseSeconds(correctedTime, true, false);
      this.lockTimeInputValue = convertedTimeObj ? convertedTimeObj.time : this.defaultParsedMinLockTime;
      this.lockTimeMeasurement = convertedTimeObj ? convertedTimeObj.measurement : 'days';
      this.updateLockTimeInputColor(correctedTime);
      this.updateLockTimeSliderValue(correctedTime);
      this.updateMessage();
    },

    /**
     * @param {InputEvent} _event
     */
    onLockTimeInputInput(_event) {
      const timeInSeconds = convertToSeconds(_event.target.value, this.lockTimeMeasurement.trim());
      this.lockTimeInputValue = _event.target.value;
      this.updateLockTimeInputColor(timeInSeconds);
    },

    /**
     * @param {InputEvent} _event
     */
    onLockTimeInputFocus(_event) {
      _event.target.select();
    },

    /**
     * Prevent typing '+' or '-'.
     * @param {KeyboardEvent} _event
     */
    onLockTimeInputKeydown(_event) {
      if (_event.key === '+' || _event.key === '-') {
        _event.preventDefault();
      }
    },
    /**
     * При клике EntireDelegation в инпуте выбора кол-ва для лок
     */
    onEntireDelegationClick() {
      this.toLockAmount = this.amountDelegated.toString();
    },

    onSubmit() {
      const toLockAmount = parseInt(this.toLockAmount);
      if (this.canLockDelegation && this.valueIsCorrect) {
        const lockDuration = parseInt(this.lockTimeSliderValue);

        this.$emit('change-component', {
          to: 'delegation-lock-confirmation',
          from: 'delegation-lock',
          data: {
            stakerId: this.stakerId,
            lockDuration,
            lockedUntil: this.lockedUntilDate,
            toLockAmount,
            delegationAmountHex: this.delegation.amountDelegated,
            minLockTime: this.minLockTime,
          },
        });
      }
    },

    onPreviousBtnClick() {
      this.$emit('change-component', {
        to: 'staking-info',
        from: 'delegation-lock',
        data: {
          stakerId: this.stakerId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
