import { apolloClient } from '@/plugins/apollo-provider';

/**
 * Fetches queries
 * @param {query} query -query wrapped in qgl
 * @param {object} variables
 */
export default async function fetchData(query, variables = {}) {
  try {
    const data = await apolloClient.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });

    return data && data.data ? data.data : {};
  } catch (error) {
    console.error('Error in fetchData: ', error);
    return {};
  }
}

/**
 * Mutates data
 * @param {mutation} query -mutation wrapped in qgl
 * @param {object} variables
 */
export async function changeData(mutation, variables) {
  try {
    const data = await apolloClient.mutate({
      mutation,
      variables,
    });

    return data && data.data ? data.data : {};
  } catch (error) {
    console.error('Error in changeData: ', error);
    return {};
  }
}
