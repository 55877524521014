<template>
  <div class="claim-rewards-confirmation">
    <tx-confirmation
      :tx="tx"
      confirmation-comp-name="claim-rewards-confirmation"
      send-button-label="Claim Rewards"
      password-label="Please enter your wallet password to claim rewards"
      :on-send-transaction-success="onSendTransactionSuccess"
      @change-component="onChangeComponent"
    >
      <h2 class="cont-with-back-btn">
        <span>Claim Rewards</span>
        <button
          type="button"
          class="btn light"
          @click="onBackBtnClick"
        >Back
        </button>
      </h2>

      <div class="transaction-info">
        <div class="row no-collapse">
          <div class="col-3 f-row-label">Validator</div>
          <div class="col break-word">
            <f-placeholder
              :content-loaded="!!dAccountInfo.stakerId"
              :replacement-num-chars="14"
            >
              <template v-if="dAccountInfo.stakerId">
                {{ dAccountInfo.stakerInfo.stakerInfo.name }}, {{ dAccountInfo.stakerId }}
              </template>
            </f-placeholder>
          </div>
        </div>

        <div class="row no-collapse">
          <div class="col-3 f-row-label">Amount</div>
          <div class="col break-word">
            <f-placeholder
              :content-loaded="!!dAccountInfo.stakerId"
              :replacement-num-chars="14"
            >
              <template v-if="dAccountInfo.stakerId">
                {{ toFTM(dAccountInfo.delegation.pendingRewards.amount) }} NETS
              </template>
            </f-placeholder>
          </div>
        </div>
      </div>

      <template #window-content>
        <ledger-confirmation-content
          :to="tx.to"
          :amount="0"
        />
      </template>
    </tx-confirmation>
  </div>
</template>

<script>
import FPlaceholder from '@/components/core/FPlaceholder/FPlaceholder.vue';
import { toFTM } from '@/utils/transactions';
import { mapGetters } from 'vuex';
import sfcUtils from 'fantom-ledgerjs/src/sfc-utils';
import { SFC_CLAIM_MAX_EPOCHS } from '@/plugins/fantom-web3-wallet';
import { formatHexToInt } from '@/filters';
import fetchData from '@/api';
import delgationByAddressAndStakerQuery from '@/api/queries/staking/delgationByAddressAndStaker';
import LedgerConfirmationContent from '../LedgerConfirmationContent/LedgerConfirmationContent.vue';
import TxConfirmation from '../TxConfirmation/TxConfirmation.vue';

export default {
  name: 'ClaimRewardsConfirmation',

  components: {
    FPlaceholder,
    LedgerConfirmationContent,
    TxConfirmation,
  },

  props: {
    /** `accountInfo` object from `StakingInfo` component. */
    accountInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    /***/
    stakerId: {
      type: String,
      default: '',
    },
    /***/
    fromDelegationList: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tx: {},
      dAccountInfo: this.accountInfo,
      delegatedLimit: 0,
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),
  },

  // activated() {
  async mounted() {
    if (!this.accountInfo.stakerId) {
      await this.loadDelegationInfo();
    }

    await this.setTx();
  },

  methods: {
    async setTx() {
      this.tx = await this.$fWallet.getSFCTransactionToSign(
        sfcUtils.claimDelegationRewardsTx(SFC_CLAIM_MAX_EPOCHS, parseInt(this.stakerId, 16)),
        this.currentAccount.address,
      );
    },

    async loadDelegationInfo() {
      const data = await Promise.all([this.fetchDelegation(), this.fetchStakerInfo()]);

      this.dAccountInfo = {
        stakerId: parseInt(this.stakerId, 16),
        delegation: data[0],
        stakerInfo: data[1],
      };
    },

    /**
     * Fetch delegation by staker id and current account address.
     */
    async fetchDelegation() {
      const data = await fetchData(delgationByAddressAndStakerQuery, {
        address: this.currentAccount.address,
        staker: this.stakerId,
      });

      return data.delegation || {};
    },

    async fetchStakerInfo() {
      const stakerInfo = this.stakerId ? await this.$fWallet.getStakerById(this.stakerId) : null;

      if (stakerInfo && !stakerInfo.stakerInfo) {
        stakerInfo.stakerInfo = {
          name: 'Unknown',
        };
      }

      return stakerInfo;
    },

    onSendTransactionSuccess(_data) {
      this.$emit('change-component', {
        to: 'transaction-success-message',
        from: 'claim-rewards-confirmation',
        data: {
          tx: _data.sendTransaction.hash,
          successMessage: 'Claiming Rewards Successful',
          continueTo: 'staking-info',
          continueToParams: {
            stakerId: this.stakerId,
          },
        },
      });
    },

    /**
     * Re-target `'change-component'` event.
     *
     * @param {object} _data
     */
    onChangeComponent(_data) {
      this.$emit('change-component', _data);
    },

    onBackBtnClick() {
      this.$emit('change-component', {
        // to: this.fromDelegationList ? 'delegations-info' : 'staking-info',
        to: 'staking-info',
        from: 'claim-rewards-confirmation',
        data: {
          stakerId: this.stakerId,
        },
      });
    },

    toFTM,
    formatHexToInt,
  },
};
</script>
