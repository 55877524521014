<template>
  <div>
    <account-list target-window="_blank"/>

    <div
      v-if="!accounts.length"
      class="narrow-container"
    >
      <router-link
        to="/"
        class="btn large w100p"
        target="_blank"
      >
        Start using Netsbo PWA Wallet
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountList from '../../components/AccountList/AccountList.vue';

export default {
  name: 'Popup',

  components: {
    AccountList,
  },

  computed: {
    ...mapGetters(['accounts']),
  },
};
</script>
