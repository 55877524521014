<template>
  <div class="account-settings-form">
    <f-form
      center-form
      @f-form-submit="onFormSubmit"
    >
      <fieldset>
        <legend class="not-visible">Settings for wallet {{ accountName }}</legend>

        <div class="form-body">
          <span class="form-label">Address</span>
          <div class="break-word">
            <b style="padding-right: 16px;">{{ accountAddress }}</b>
            <f-copy-button
              :text="accountAddress"
              :hide-popover-after="3100"
              class="btn large light same-size round"
            >
              <template #popover-text>
                Address copied to clipboard.
              </template>
            </f-copy-button>
            <button
              id="account-settings-form__qr-btn"
              class="btn large light same-size round"
              type="button"
              @click.prevent="$refs.qrWindow.show()"
              @mouseenter="showPopover('#account-settings-form__qr-btn', 'Show QR Code')"
              @mouseleave="hidePopover"
            >
              <icon
                data="@/assets/svg/monochrome/Options/QR.svg"
                width="20"
                height="20"
                aria-hidden="true"
              />
            </button>
          </div>
          <br/>

          <f-input
            :value="accountName"
            type="text"
            label="Name"
            field-size="large"
            name="name"
            validate-on-input
            :validator="checkName"
          >
            <template #bottom="sProps">
              <f-message
                v-show="sProps.showErrorMessage"
                type="error"
                role="alert"
                with-icon
              >
                This field cannot be blank
              </f-message>
            </template>
          </f-input>

          <f-input
            :value="cAccountOrder"
            type="number"
            autocomplete="off"
            min="1"
            :max="accounts.length.toString(10)"
            step="1"
            label="Order"
            field-size="large"
            name="order"
            validate-on-input
            :validator="checkOrder"
          >
            <template #bottom="sProps">
              <f-message
                v-show="sProps.showErrorMessage"
                type="error"
                role="alert"
                with-icon
              >
                Value must be between 1 and {{ accounts.length }}
              </f-message>
            </template>
          </f-input>

          <div class="align-center form-buttons">
            <a
              href="#"
              class="btn large secondary btn-second"
              @click.prevent="onRemoveAccountBtnClick"
            >
              Remove Wallet...
            </a>
            <button
              type="submit"
              class="btn large btn-dark"
            >Save
            </button>
          </div>
        </div>
      </fieldset>
    </f-form>

    <remove-account-window
      ref="confirmationWindow"
      :account="cAccount"
      @account-removed="onAccountRemoved"
    />

    <q-r-code-window
      ref="qrWindow"
      :address="cAccountAddress"
    />
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="popoverAttachId"
      :attach-position="popoverAttachPosition"
      :preferred-attach-position="popoverAttachPosition"
      :attach-margin="[1, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FWindow from '../core/FWindow/FWindow.vue';
import FForm from '../core/FForm/FForm.vue';
import FInput from '../core/FInput/FInput.vue';
import FMessage from '../core/FMessage/FMessage.vue';
import FCopyButton from '../core/FCopyButton/FCopyButton.vue';
import QRCodeWindow from '../windows/QRCodeWindow/QRCodeWindow.vue';
import RemoveAccountWindow from '../windows/RemoveAccountWindow/RemoveAccountWindow.vue';
import { UPDATE_ACCOUNT } from '../../store/actions.type';
import { helpersMixin } from '../../mixins/helpers';

/**
 * @mixes helpersMixin
 */
export default {
  name: 'AccountSettingsForm',

  components: {
    RemoveAccountWindow,
    QRCodeWindow,
    FCopyButton,
    FMessage,
    FInput,
    FForm,
    FWindow,
  },

  mixins: [helpersMixin],

  props: {
    accountAddress: {
      type: String,
      required: true,
    },
    accountOrder: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      account: {},
      popoverCreated: false,
      popoverAttachId: '',
      popoverAttachPosition: '',
      popoverText: '',
    };
  },

  computed: {
    ...mapGetters(['getAccountByAddress', 'accounts', 'getAccountAndIndexByAddress']),

    /**
     * @return {string}
     */
    accountName() {
      let name = '';
      if (this.account) {
        name = this.account.name || this.account.address;
      }
      return name;
    },

    /**
     * @return {string}
     */
    cAccountOrder() {
      let order = this.accountOrder;

      if (order === -1) {
        const { index } = this.getAccountAndIndexByAddress(this.accountAddress);
        order = index + 1;
      }

      return order.toString(10);
    },

    cAccountAddress() {
      return this.account ? this.account.address : '';
    },

    cAccount() {
      return this.account;
    },
  },

  created() {
    this.account = this.getAccountByAddress(this.accountAddress);
  },

  methods: {
    /**
     * @param {string} _value
     * @return {boolean}
     */
    checkName(_value) {
      return !!_value.trim();
    },

    /**
     * @param {string} _value
     * @return {boolean}
     */
    checkOrder(_value) {
      let ok = false;
      const value = parseInt(_value);

      if (!isNaN(value)) {
        ok = value > 0 && value <= this.accounts.length;
      }

      return ok;
    },

    /**
     * Shows popover for QR button on hover
     * @param {string} _id - id of button that is hovered
     * @param {string} _text - text of the popover
     * @param {string} _position - position of the popover
     */
    showPopover(id, text, position = 'bottom') {
      if (text) {
        this.popoverAttachId = id;
        this.popoverAttachPosition = position;
        this.popoverText = text;
        this.popoverCreated = true;
      }
    },

    /**
     * Hides popover for QR button on hover
     */
    hidePopover() {
      this.popoverCreated = false;
    },

    /**
     * @param {{detail: {data: {}}}} _event
     */
    onFormSubmit(_event) {
      const { data } = _event.detail;
      const { name } = data;
      const order = parseInt(data.order);
      let changed = false;

      if (this.checkName(name) && this.checkOrder(order)) {
        const adName = this.account.name || this.accountAddress;

        changed = adName !== name || parseInt(this.cAccountOrder) !== order;

        if (changed) {
          this.$store.dispatch(UPDATE_ACCOUNT, {
            address: this.accountAddress,
            name,
            order,
          });
        }

        this.$emit('account-settings-form-data', {
          name,
          order,
          changed,
        });
      }
    },

    onRemoveAccountBtnClick() {
      this.$refs.confirmationWindow.show();
    },

    onAccountRemoved(lastExistingAccountAddress) {
      const parentWindow = this.findParentByName('f-window');

      if (parentWindow) {
        parentWindow.hide();
      }
      this.$emit('account-settings-form-acoount-removed', lastExistingAccountAddress);
    },
  },
};
</script>
