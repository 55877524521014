<template>
  <div class="unstake-ftm">
    <f-card class="f-card-double-padding f-data-layout">
      <f-form
        ref="form"
        center-form
        @f-form-submit="onFormSubmit"
      >
        <legend class="h2">
          <div class="cont-with-back-btn">
                        <span>
                            Undelegate CBR <span class="f-steps"><b>1</b> / {{ isLocked ? '3' : '2' }}</span>
                        </span>
            <button
              type="button"
              class="btn light dark"
              @click="onPreviousBtnClick"
            >Back
            </button>
          </div>
        </legend>

        <div class="form-body">
          <h3>The withdrawal of your delegated tokens will take {{ withdrawPeriod }}</h3>
          <h3
            v-if="isLocked"
            class="orange-color"
            style="padding-top: 0;"
          >
            {{ lockedAmount }} CBR of {{ formatHexToInt(accountInfo.delegation.amountDelegated) }} CBR are
            still locked.
            <template v-if="cUnlockPenalty > 0">
              You will lose {{ cUnlockPenalty }} CBR by undelegating before the lock expiration.
            </template>
          </h3>

          <f-input
            v-model="amount"
            label="Amount"
            field-size="large"
            type="number"
            autocomplete="off"
            min="1"
            step="1"
            name="amount"
            :validator="checkAmount"
            validate-on-input
          >
            <template #top="sProps">
              <div class="input-label-layout">
                <label :for="sProps.inputId">{{ sProps.label }}</label>
                <button
                  type="button"
                  class="btn light small"
                  @click="onEntireDelegationClick"
                >
                  Entire Delegation
                </button>
              </div>
            </template>
            <template #bottom="sProps">
              <f-message
                v-show="sProps.showErrorMessage"
                type="error"
                role="alert"
                with-icon
              >
                {{ amountErrMsg }}
              </f-message>
            </template>
          </f-input>

          <div class="form-buttons align-center">
            <button
              type="submit"
              class="btn large"
              :class="{ 'orange-btn': cUnlockPenalty > 0 }"
            >
              Ok, undelegate
            </button>
          </div>
        </div>
      </f-form>
    </f-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';
import { toHex } from '@/utils/big-number';
import { parseSeconds } from '@/utils/time';
import { prepareTimestamp, formatHexToInt } from '@/filters';
import { checkCBRAmount, validateNumberInput } from '../../utils/defi-tokens';
import FMessage from '../core/FMessage/FMessage.vue';
import FInput from '../core/FInput/FInput.vue';
import FForm from '../core/FForm/FForm.vue';
import FCard from '../core/FCard/FCard.vue';

export default {
  name: 'UnstakeNETS',

  components: {
    FMessage,
    FInput,
    FForm,
    FCard,
  },

  props: {
    /** `accountInfo` object from `StakingInfo` component, includes delegation, stakerInfo. lockedAmount */
    accountInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    /** Staker id in hex */
    stakerId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      amountErrMsg: '',
      // * v-model, amount to undelegate
      amount: '',
      /**
       *  Comes from graphql. Can be zero, if there were no pending rewards from lock at the moment of undelegating
       *  @type {hex}
       * */
      unlockPenalty: '',
      /** @type {hex}  - free, not locked CBR - */
      unlockedAmount: '',
      /** @type {hex}  -  amount locked before undelegating */
      // * in days or minutes or seconds
      withdrawPeriod: '7 days',
      delegation: {},
      sfcConfig: {},
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    // * сколько максимум можно undelegate - кол-во amountDelegated
    undelegateMax() {
      return this.accountInfo ? formatHexToInt(this.accountInfo.delegation.amountDelegated) : 0;
    },

    /**
     * Returns `true` if delegetion is still locked.
     *
     * @return {boolean}
     */
    isLocked() {
      const { accountInfo } = this;
      return (accountInfo && accountInfo.delegation && accountInfo.delegation.isDelegationLocked) || false;
    },

    // * какое кол-во CBR потеряем за преждевременный unlock
    cUnlockPenalty() {
      return (this.unlockPenalty && formatHexToInt(this.unlockPenalty)) || -1;
    },

    // * сколько нужно разблокировать
    cToUnlockAmount() {
      return this.amount >= this.lockedAmount ? this.lockedAmount : 0;
    },

    lockedAmount() {
      return this.accountInfo && this.accountInfo.lockedAmount ? this.accountInfo.lockedAmount : 0;
    },
  },

  watch: {
    amount: {
      // * _value - amount of CBR delegated
      async handler(_value) {
        if (_value && this.isLocked) {
          this.unlockPenalty = await this.fetchUnlockedPenalty(parseInt(_value));
        }
      },
      immediate: true,
    },
  },

  async created() {
    if (this.isLocked) {
      this.unlockedAmount = await this.$fWallet.fetchUnlockedAmount(this.currentAccount.address, this.stakerId);
    }

    this.setMaxUndelegation();
    this.calculateWithhdrawPeriod();
  },

  methods: {
    checkAmount(_value) {
      // * не допускаем наличия числа типа 076
      this.amount = validateNumberInput(this.amount);
      this.amountErrMsg = checkCBRAmount(_value, '', this.undelegateMax, 'undelegate');
      return !this.amountErrMsg;
    },

    async calculateWithhdrawPeriod() {
      this.sfcConfig = await this.$fWallet.getSFCConfig();

      this.withdrawPeriod = parseSeconds(this.sfcConfig.withdrawalPeriodTime.num);
    },

    /**
     * Calculate amount that needs to be unlocked and fetch penalty, if this amount > 0
     */
    async fetchUnlockedPenalty() {
      try {
        // * unlockedAmount - free, not locked tokens, fetched from fWallet

        if (this.cToUnlockAmount > 0) {
          const data = await this.$apollo.query({
            query: gql`
                            query GetUnlockedAmount($address: Address!, $staker: BigInt!, $amount: BigInt!) {
                                delegation(address: $address, staker: $staker) {
                                    unlockPenalty(amount: $amount)
                                }
                            }
                        `,
            variables: {
              address: this.currentAccount.address,
              staker: this.stakerId,
              amount: toHex(this.cToUnlockAmount),
            },
            fetchPolicy: 'network-only',
          });

          return data && data.data.delegation ? data.data.delegation.unlockPenalty : '0x0';
        }
      } catch (_error) {
        console.error(_error);
        return '';
      }
    },

    onPreviousBtnClick() {
      this.$emit('change-component', {
        to: 'staking-info',
        from: 'unstake-n-e-t-s',
        data: {
          stakerId: this.stakerId,
        },
      });
    },

    onFormSubmit(_event) {
      const amount = parseInt(_event.detail.data.amount);

      this.$emit('change-component', {
        to: this.cToUnlockAmount > 0 ? 'delegation-unlock-confirmation' : 'unstake-confirmation',
        from: 'unstake-n-e-t-s',
        data: {
          accountInfo: this.accountInfo,
          toUnstakeAmount: amount,
          toUnlockAmount: this.cToUnlockAmount.toString(),
          stakerId: this.stakerId,
          stakerName: this.stakerName,
        },
      });
    },

    setMaxUndelegation() {
      this.amount = this.undelegateMax.toString();
    },

    onEntireDelegationClick() {
      this.setMaxUndelegation();
    },

    prepareTimestamp,
    formatHexToInt,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
