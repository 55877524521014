import dayjs from 'dayjs';

const tRE = /(\d)([dwmy])/;

/**
 * @param {string} _code Value + (d|w|m|y)
 * @returns {{unit: string, value: number}|null}
 */
export function parseTimeCode(_code) {
  const match = tRE.exec(_code ? _code.toLowerCase() : '');

  if (match && match.length === 3) {
    return {
      value: parseInt(match[1]),
      unit: match[2],
    };
  }

  return null;
}

/**
 * Get time span in unix timestamp.
 *
 * @param {string} _code Value + (d|w|m|y)
 * @param {*} [_ends] Dayjs
 * @param {boolean} [_utc]
 * @returns {(number|number)[]}
 */
export function getTimeSpan(_code, _ends, _utc = true) {
  const timeCode = parseTimeCode(_code);
  let startDateTS = 0;
  const endDate = _utc ? dayjs.utc(_ends) : dayjs(_ends);

  if (timeCode) {
    if (timeCode.unit === 'w') {
      startDateTS = endDate.subtract(timeCode.value, 'week')
        .startOf('day')
        .unix();
    } else if (timeCode.unit === 'm') {
      startDateTS = endDate.subtract(timeCode.value, 'month')
        .startOf('day')
        .unix();
    } else if (timeCode.unit === 'y') {
      startDateTS = endDate.subtract(timeCode.value, 'year')
        .startOf('day')
        .unix();
    }
  }

  return [startDateTS, endDate.unix()];
}

/**
 * @return {dayjs.Dayjs}
 */
export function nowDJS() {
  return dayjs();
}

/**
 * Просклонять по падежам единицы измерения времени, в завивимости от языка
 * @param {string} - название единицы измерения в ед. ч
 * @param {number} - кол-во времени
 * @param {string} - язык сайта
 * @return {string} - слово с учетом окончания
 */
export function declineTimeMeasurements(word, amount, lang = 'en') {
  switch (lang) {
    case 'en':
      if (amount === 1) {
        return word;
      }
      if (amount === 0 || amount > 1) {
        return `${word}s `;
      }
      break;
  }
}

/**
 * Get time in days from time in seconds.
 *
 * @param {number} seconds time in seconds
 * @param {boolean} roundTime
 *  do not include zero time, round to the biggest time in one type of measurement (e.g, return time in hours)
 * @param {boolean} fullTimeInString return the rounded time in a string with time and measurement or as an object
 * @returns {string | {time: number, measurement: string}}
 *  either a string with number and measerement name (e. g. 1 day or an object
 */
export function parseSeconds(seconds, roundTime = true, fullTimeInString = true) {
  const min = seconds / 60;
  const hour = min / 60;
  const days = Math.floor(hour / 24);
  const daysObj = {
    time: days,
    measurement: `${declineTimeMeasurements('day', days)}`,
  };

  const hours = Math.floor(hour % 24);

  const hoursObj = {
    time: hours,
    measurement: `${declineTimeMeasurements('hour', hours)}`,
  };

  const minutes = Math.floor(min % 60);
  const minutesObj = {
    time: minutes,
    measurement: `${declineTimeMeasurements('minute', minutes)}`,
  };

  const secs = Math.floor(seconds % 60);
  const secondsObj = {
    time: secs,
    measurement: `${declineTimeMeasurements('second', secs)}`,
  };

  const allTimes = {
    days: daysObj,
    hours: hoursObj,
    minutes: minutesObj,
    seconds: secondsObj,
  };

  if (roundTime) {
    for (const key in allTimes) {
      const value = allTimes[key];
      if (value.time > 0) {
        return fullTimeInString ? `${value.time} ${value.measurement}` : value;
      }
    }
  } else {
    return allTimes;
  }
}

/**
 * Get time in seconds from time in days, hours or  minutes.
 *
 * @param {number} amount time days, hours or  minutes.
 * @param {string} measurement name of measurement - days, hours or  minutes.
 * @returns {number} - time in seconds
 */
export function convertToSeconds(amount, measurement) {
  switch (measurement) {
    case 'days':
      return amount * 24 * 60 * 60;
    case 'day':
      return amount * 24 * 60 * 60;
    case 'hours':
      return amount * 60 * 60;
    case 'hour':
      return amount * 60 * 60;
    case 'minutes':
      return amount * 60;
    case 'minute':
      return amount * 60;
  }
}

/**
 * Get time in milliseconds from time in days, hours or  minutes.
 *
 * @param {number} amount time days, hours or  minutes.
 * @param {string} measurement name of measurement - days, hours or  minutes.
 * @returns {number} - time in milliseconds
 */
export function convertToMilliSeconds(amount, measurement) {
  switch (measurement) {
    case 'days':
      return amount * 24 * 60 * 60 * 1000;
    case 'day':
      return amount * 24 * 60 * 60 * 1000;
    case 'hours':
      return amount * 60 * 60 * 1000;
    case 'hour':
      return amount * 60 * 60 * 1000;
    case 'minutes':
      return amount * 60 * 1000;
    case 'minute':
      return amount * 60 * 1000;
  }
}
