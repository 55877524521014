<template>
  <div
    ref="doc"
    class="stake-ftm"
    tabindex="0"
  >
    <f-card class="f-card-double-padding f-data-layout">
      <h2 class="cont-with-back-btn">
        <span>Staking</span>
        <template v-if="stakerId">
          <a
            href="#"
            class="btn light break-word dark"
            style="max-width: 100%;"
            aria-label="Go to previous form"
            @click.prevent="onPreviousBtnClick"
          >
            Back
          </a>
        </template>
      </h2>

      <div class="row no-vert-col-padding collapse-md">
        <div class="col">
          <div class="row no-collapse">
            <div class="col f-row-label">Delegated</div>
            <div class="col">
              <f-placeholder
                :content-loaded="!!delegation"
                block
                :replacement-num-chars="10"
              >
                {{ formatHexToInt(delegation.amountDelegated) || 0 }} CBR
              </f-placeholder>
            </div>
          </div>
          <div class="row no-collapse">
            <div class="col f-row-label">Pending Rewards</div>
            <div class="col">
              <f-placeholder
                :content-loaded="!!delegation"
                block
                :replacement-num-chars="10"
              >
                <template v-if="delegation && pendingRewards"> {{ pendingRewards }} NETS</template>
                <template v-else>0 NETS</template>
              </f-placeholder>
            </div>
          </div>
          <div class="row no-collapse">
            <div class="col f-row-label">Claimed Rewards</div>
            <div class="col">
              <f-placeholder
                :content-loaded="!!delegation"
                block
                :replacement-num-chars="10"
              >
                <template v-if="delegation"> {{ claimedRewards }} NETS</template>
                <template v-else>0 NETS</template>
              </f-placeholder>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="row no-collapse">
            <div class="col f-row-label">Validator</div>
            <div class="col">
              <f-placeholder
                :content-loaded="!!stakerInfo"
                block
                :replacement-num-chars="10"
              >
                <a
                  :href="`${explorerUrl}validator/${stakerInfo.stakerAddress}`"
                  target="_blank"
                >
                  {{ stakerName }}
                </a>
              </f-placeholder>
            </div>
          </div>
          <div class="row no-collapse">
            <div class="col f-row-label">Validator Id</div>
            <div class="col">
              <f-placeholder
                :content-loaded="!!stakerInfo"
                block
                :replacement-num-chars="10"
              >
                <template v-if="stakerInfo">{{ formatHexToInt(stakerInfo.id) || '-' }}</template>
              </f-placeholder>
            </div>
          </div>
          <div class="row no-collapse">
            <div class="col f-row-label">Delegation Time</div>
            <div class="col">
              <f-placeholder
                :content-loaded="!!delegation"
                block
                :replacement-num-chars="10"
              >
                {{
                  delegation.createdTime && delegation.createdTime !== '0x0'
                    ? formatDate(timestampToDate(delegation.createdTime), false, true)
                    : '-'
                }}
              </f-placeholder>
            </div>
          </div>
          <!-- lock and unlock are not used for now -->
          <!-- <div class="row no-collapse">
              <div class="col f-row-label">Locked amount</div>
              <div class="col">
                  <f-placeholder :content-loaded="!!delegation" block :replacement-num-chars="10">
                      <template v-if="delegation && lockedUntil !== '0x0'"> {{ lockedAmount }} CBR </template>
                      <template v-else>0 CBR</template>
                  </f-placeholder>
              </div>
          </div>
          <div class="row no-collapse">
              <div class="col f-row-label">Unlock Date</div>
              <div class="col">
                  <f-placeholder :content-loaded="!!delegation" block :replacement-num-chars="10">
                      <f-message
                          v-if="lockedUntil !== '0x0'"
                          :type="lockedUntilMessageType"
                          style="margin-top: 0; padding-top: 0;"
                      >
                          {{ formatDate(timestampToDate(lockedUntil), false, true) }}
                      </f-message>
                      <template v-else>-</template>
                  </f-placeholder>
              </div>
          </div> -->
        </div>
      </div>

      <div class="row">
        <div class="col align-center">
          <div class="form-buttons">
            <template v-if="delegation">
              <template>
                <button
                  v-show="canClaimRewards"
                  class="btn large"
                  :disabled="!canClaimRewards"
                  @click="claimRewards()"
                >
                  Claim Rewards
                </button>
                <button
                  v-show="canUndelegate"
                  class="btn large"
                  :class="{ 'orange-btn': orangeBtn }"
                  :disabled="!canUndelegate"
                  @click="undelegate()"
                >
                  Undelegate
                </button>
                <!-- ! is not used now -->
                <!-- <button
                    v-show="canLockDelegation"
                    class="btn large"
                    :disabled="!canLockDelegation"
                    @click="lockDelegation()"
                >
                    Lock Delegation
                </button> -->
                <!-- Эпохи пока не привязаны к стейкингу -->
                <!-- <f-message
                    v-if="!canUndelegate && canClaimRewards"
                    type="info"
                    with-icon
                    class="align-left"
                >
                    You can claim rewards for a maximum of {{ claimMaxEpochs }} epochs at once (use
                    repeatedly if needed).
                </f-message> -->
              </template>
              <template v-if="!canClaimRewards && delegation.amountDelegated !== '0x0'">
                <f-message
                  type="info"
                  with-icon
                >
                  You will be able to claim rewards as soon as there will be some pending rewards
                </f-message>
              </template>
            </template>
            <details>
              <summary>Advanced Functions</summary>
              <p>
                <button
                  class="btn large secondary btn-second"
                  @click="claimRewards()"
                >
                  Claim Rewards (Unconditioned)
                </button>
              </p>
            </details>
          </div>
        </div>
      </div>
    </f-card>

    <f-card
      v-if="withdrawRequests.length"
      class="f-card-double-padding account-main-content-mt"
    >
      <h2>Undelegation History</h2>

      <withdraw-request-list
        :items="withdrawRequests"
        @withdraw-request-selected="onWithdrawRequestSelected"
      />
    </f-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SFC_CLAIM_MAX_EPOCHS } from '@/plugins/fantom-web3-wallet';
import { parseSeconds, convertToMilliSeconds } from '@/utils/time';
import {
  formatHexToInt, timestampToDate, formatDate, numToFixed,
} from '@/filters';
import FPlaceholder from '@/components/core/FPlaceholder/FPlaceholder.vue';
import { toFTM } from '../../utils/transactions';
import FMessage from '../core/FMessage/FMessage.vue';
import WithdrawRequestList from '../data-tables/WithdrawRequestList.vue';
import FCard from '../core/FCard/FCard.vue';
import appConfig from '../../../app.config';

export default {
  name: 'StakingInfo',

  components: {
    FPlaceholder,
    FMessage,
    WithdrawRequestList,
    FCard,
  },

  props: {
    /** Staker id in hex format */
    stakerId: {
      type: String,
      default: '',
    },
    /** Name of previous component. */
    previousComponent: {
      type: String,
      default: 'delegations-info',
    },
  },

  data() {
    return {
      // * всегда true
      isFluidStakingActive: true,
      lockedUntil: '',
      lockedAmount: 0,
      isDelegationLocked: false,
      explorerUrl: appConfig.explorerUrl2,
      claimMaxEpochs: SFC_CLAIM_MAX_EPOCHS,
      delegation: {},
      stakerInfo: {},
      pendingRewards: 0,
      // * Data for WithdrawRequestList.
      withdrawRequests: [],
      pendingRewardsIntervalID: 0,
      withdrawRequestsIntervalID: 0,
      withdrawIntervalDelay: 1 * 24 * 60 * 60 * 1000,
    };
  },

  computed: {
    ...mapGetters(['currentAccount']),

    stakerName() {
      return this.stakerInfo && this.stakerInfo.stakerInfo ? this.stakerInfo.stakerInfo.name : 'Unknown';
    },

    claimedRewards() {
      return this.delegation ? toFTM(this.delegation.claimedReward) : numToFixed(0, 2);
    },

    canClaimRewards() {
      return (
        this.delegation && parseInt(this.pendingRewards) > 0 && this.delegation.amountDelegated !== '0x0'
        /* this.delegation.amountDelegated !== this.delegation.amountInWithdraw */
      );
    },

    canUndelegate() {
      return (
        // * после нажатия на undelegate станет true - this.delegation.amountDelegated === '0x0'
        this.delegation && this.delegation.amountDelegated !== '0x0'
        /*
                      (this.delegation.amountDelegated !== this.delegation.amountInWithdraw &&
                            this.delegation.amountDelegated !== '0x0') */
      );
    },

    canLockDelegation() {
      return this.canUndelegate && !this.isDelegationLocked && formatHexToInt(this.delegation.unlockedAmount);
    },

    /**
     * Sum of amount of withdraw requests (not withdrawn yet).
     */
    withdrawRequestsAmount() {
      let amount = 0;
      if (this.withdrawRequests) {
        this.withdrawRequests.forEach((_request) => {
          amount += formatHexToInt(_request.amount);
        });
      }

      return amount;
    },

    /**
     * @return {string}
     */
    lockedUntilMessageType() {
      return this.isLocked ? 'warning' : 'success';
    },

    /**
     * Color of 'Undelegate' button.
     *
     * @return {boolean}
     */
    orangeBtn() {
      return this.isLocked;
    },

    /**
     * Returns `true` if delegetion is still locked.
     *
     * @return {boolean}
     */
    isLocked() {
      return this.isDelegationLocked;
    },
  },

  created() {
    this.getDelegationInfo();
    this.getStakerInfo();
    this.calculatewithdrawIntervalDelay();
  },

  mounted() {
    this.$refs.doc.focus();
  },

  updated() {
    if (this.delegation && this.delegation.amountDelegated !== '0x0') {
      this.pendingRewardsIntervalID = setInterval(() => {
        this.getPendingRewards();
      }, 5 * 60 * 1000); // 5 minutes
    }

    if (this.delegation && this.delegation.amountInWithdraw !== '0x0') {
      this.withdrawRequestsIntervalID = setInterval(() => {
        this.getWithdrawRequests();
      }, this.withdrawIntervalDelay);
    }
  },

  beforeDestroy() {
    clearInterval(this.pendingRewardsIntervalID);
    clearInterval(this.withdrawRequestsIntervalID);
  },

  methods: {
    async calculatewithdrawIntervalDelay() {
      const sfcConfig = await this.$fWallet.getSFCConfig();
      const withdrawPeriod = sfcConfig.withdrawalPeriodTime.num;
      const timeObj = parseSeconds(withdrawPeriod, true, false);
      if (timeObj) {
        this.withdrawIntervalDelay = convertToMilliSeconds(1, timeObj.measurement);
      }
    },

    getWithdrawRequests() {
      const requests = [];

      if (this.delegation) {
        if (this.delegation.withdrawRequests && this.delegation.withdrawRequests.length) {
          this.delegation.withdrawRequests.forEach((_request) => {
            if (this.delegation.toStakerId === _request.stakerID) {
              requests.push(_request);
            }
          });
        }
      }

      this.withdrawRequests = requests;
    },

    getPendingRewards() {
      if (this.delegation && this.delegation.pendingRewards && this.delegation.pendingRewards.amount) {
        this.pendingRewards = toFTM(this.delegation.pendingRewards.amount);
      }
    },

    async getLockedAmount() {
      const unlockedAmount = await this.$fWallet.fetchUnlockedAmount(this.currentAccount.address, this.stakerId);
      const delegated = this.delegation ? this.delegation.amountDelegated : '0x0';
      this.lockedAmount = formatHexToInt(delegated) - formatHexToInt(unlockedAmount);
    },

    async getStakerInfo() {
      this.stakerInfo = await this.$fWallet.getStakerById(this.stakerId);
    },

    async getDelegationInfo() {
      this.delegation = await this.$fWallet.fetchDelegation(this.currentAccount.address, this.stakerId);
      this.getLockedAmount();
      this.getWithdrawRequests();
      this.getPendingRewards();
      this.isFluidStakingActive = this.delegation.isFluidStakingActive;
      this.lockedUntil = this.delegation.lockedUntil;
      this.isDelegationLocked = this.delegation.isDelegationLocked;
    },

    async undelegate() {
      if (!this.canUndelegate) {
        return;
      }

      this.$emit('change-component', {
        to: 'unstake-n-e-t-s',
        from: 'staking-info',
        data: {
          accountInfo: {
            delegation: this.delegation,
            stakerInfo: this.stakerInfo,
            withdrawRequestsAmount: this.withdrawRequestsAmount,
            lockedAmount: this.lockedAmount,
          },
          stakerId: this.stakerId,
        },
      });
    },

    lockDelegation() {
      if (!this.canLockDelegation) {
        return;
      }

      this.$emit('change-component', {
        to: 'delegation-lock',
        from: 'staking-info',
        data: {
          stakerId: this.stakerId,
        },
      });
    },

    now() {
      return new Date().getTime();
    },

    async claimRewards() {
      this.$emit('change-component', {
        to: 'claim-rewards-confirmation',
        from: 'staking-info',
        data: {
          accountInfo: {
            ...this.delegation,
            stakerInfo: this.stakerInfo,
          },
          stakerId: this.stakerId,
        },
      });
    },

    /**
     * По клику кнопки withdraw
     * @param {object} _withdrawRequest
     */
    async onWithdrawRequestSelected(_withdrawRequest) {
      this.$emit('change-component', {
        to: 'withdraw-f-t-m-confirmation',
        from: 'staking-info',
        data: {
          accountInfo: {
            ...this.delegation,
            stakerInfo: this.stakerInfo,
          },
          amount: formatHexToInt(_withdrawRequest.amount),
          withdraw: true,
          withdrawRequest: _withdrawRequest,
          stakerId: this.stakerId,
        },
      });
    },

    onPreviousBtnClick() {
      this.$emit('change-component', {
        to: this.previousComponent,
        from: 'stake-form',
      });
    },

    toFTM,
    timestampToDate,
    formatDate,
    formatHexToInt,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
