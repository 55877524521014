<template>
  <div
    class="account-list"
    :class="{ 'edit-mode': editMode }"
    @keyup="onAccountListKeyup"
  >
    <ul class="no-markers">
      <li
        v-for="(account, index) in accounts"
        :key="account.address"
        @click="onAccountCardClick(account.address, index, $event.target)"
        @mouseover="onAccountCardMouseOver(index, $event.target)"
      >
        <f-card
          hover
          :class="{ 'is-active-account': account.address === currentAddress }"
        >
          <h3
            slot="title"
            class="title"
          >
                        <span class="row no-collapse align-items-start">
                            <span
                              class="col-5 col-10-md"
                              :class="{ 'account-name-exists': !!account.name }"
                            >
                                <span class="address-col">
                                    <span
                                      v-if="pickMode"
                                      class="value clickable"
                                      :data-address="account.address"
                                      tabindex="0"
                                    >
                                        <account-name :account="account"/>
                                    </span>
                                    <router-link
                                      v-else
                                      :to="{
                                            name: routeName,
                                            params: { ...routeParams, address: account.address },
                                        }"
                                      :target="targetWindow"
                                      class="value clickable"
                                      aria-label="Address"
                                      :data-address="account.address"
                                    >
                                        <account-name :account="account"/>
                                    </router-link>
                                </span>
                                <span class="label">
                                    <template v-if="account.isLedgerAccount"> Ledger </template>
                                    <template v-else-if="account.isMetamaskAccount"> Metamask </template>
                                    <template v-else> Keystore file </template>
                                </span>
                            </span>
                            <span class="col col-6-md">
                                <span
                                  class="dark no-break-word account-list__balance"
                                >
                                  {{ toFTM(account.balance) }} <span class="ftm">NETS</span>
                                </span>
                                <!--            Цены в валюте временно скрыты в текущей реализации                  -->
                              <!-- <span class="currency">
                                  {{ formatCurrencyByLocale(account.balance, tokenPrice) }}
                              </span> -->
                                <span class="label">Available</span>
                            </span>
                            <span class="col col-6-md">
                                <span class="dark no-break-word account-list__balance">
                                    {{ toFTM(account.totalBalance) }} <span class="ftm">NETS</span>
                                </span>
                              <!--            Цены в валюте временно скрыты в текущей реализации                  -->
                              <!-- <span class="currency">
                                  {{ formatCurrencyByLocale(account.totalBalance, tokenPrice) }}
                              </span> -->
                                <span class="label">Total</span>
                            </span>

                <div class="action-btns">
                  <f-copy-button
                    :id="`copy-btn-${index}`"
                    :custom-id="`copy-btn-${index}`"
                    :text="account.address"
                    :default-icon-size="16"
                    class="btn light same-size round btn-copy"
                  >
                    <template #popover-text>
                      Address copied to clipboard.
                    </template>
                  </f-copy-button>

                  <button
                    v-if="editMode"
                    :id="`edit-btn-${index}`"
                    class="btn large_ light same-size round btn-edit"
                    :data-address="account.address"
                    :data-index="index"
                  >
                    <icon
                      data="@/assets/svg/monochrome/Options/Edit.svg"
                      width="16"
                      height="16"
                      aria-hidden="true"
                    />
                  </button>
                </div>
            </span>
          </h3>
        </f-card>
      </li>
    </ul>

    <account-settings-window
      v-if="editMode"
      ref="accountSettingsWindow"
      :account-address="chosenAccountAddress"
      :account-order="chosenAccountOrder"
      @window-hide="onSettingsWindowHide"
      @window-account-removed="onAccountRemoved"
    />
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="popoverAttachId"
      :attach-position="popoverAttachPosition"
      :preferred-attach-position="popoverAttachPosition"
      :attach-margin="[1, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FWindow from '../core/FWindow/FWindow.vue';
import FCard from '../core/FCard/FCard.vue';
import FCopyButton from '../core/FCopyButton/FCopyButton.vue';
import AccountSettingsWindow from '../windows/AccountSettingsWindow/AccountSettingsWindow.vue';
import AccountName from '../AccountName/AccountName.vue';
import { toFTM } from '../../utils/transactions';
import {
  DEACTIVATE_ACTIVE_ACCOUNT,
  SET_ACTIVE_ACCOUNT_ADDRESS,
  SET_ACTIVE_ACCOUNT_BY_ADDRESS,
} from '../../store/mutations.type';
import { eventBusMixin } from '../../mixins/event-bus';
import { UPDATE_ACCOUNTS_BALANCES } from '../../store/actions.type';
import { pollingMixin } from '../../mixins/polling';
import { formatCurrencyByLocale } from '../../filters';
import { isAriaAction } from '../../utils/aria';

export default {
  name: 'AccountList',

  components: {
    FCopyButton,
    AccountName,
    AccountSettingsWindow,
    FCard,
    FWindow,
  },

  mixins: [eventBusMixin, pollingMixin],

  props: {
    /** Show action icons on the right side. */
    editMode: {
      type: Boolean,
      default: false,
    },
    /** Emit 'account-picked' event when whole account element is clicked. */
    pickMode: {
      type: Boolean,
      default: false,
    },
    /** Target window for account links */
    targetWindow: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      routeName: 'account-history',
      routeParams: {},
      chosenAccountAddress: '',
      chosenAccountOrder: -1,
      popoverCreated: false,
      popoverAttachId: '',
      popoverAttachPosition: '',
      popoverText: '',
    };
  },

  computed: {
    ...mapGetters(['accounts', 'currentAccount']),

    currentAddress() {
      return this.currentAccount ? this.currentAccount.address : '';
    },

    /** Цены в валюте временно скрыты в текущей реализации */
    // tokenPrice() {
    //     return this.$store.state.tokenPrice;
    // },
  },

  watch: {
    $route(_route) {
      if (_route.params && _route.params.address) {
        this.$emit('account-picked', _route.params.address);
        this._eventBus.emit('account-picked', _route.params.address);
      }
    },
  },

  created() {
    const routeName = this.$route.name;

    if (
      routeName.indexOf('account-') > -1 ||
      routeName.indexOf('defi-') > -1 ||
      routeName.indexOf('fswap') > -1 ||
      routeName.indexOf('fswap-CBR') > -1 ||
      routeName.indexOf('staking') > -1 ||
      routeName.indexOf('gov-') > -1
    ) {
      this.routeName = routeName;
      this.routeParams = this.$route.params;
    }

    this.checkAccountsLength();
  },

  mounted() {
    this.$store.dispatch(UPDATE_ACCOUNTS_BALANCES);

    /*
        this.$store.dispatch(UPDATE_ACCOUNTS_BALANCES).then(() => {
            this.$emit('accounts-updated');
        });
        */

    this._polling.start(
      'account-list',
      () => {
        this.$store.dispatch(UPDATE_ACCOUNTS_BALANCES);
      },
      3000,
    );
  },

  beforeDestroy() {
    const route = this.$route;

    if (route.params && route.params.address) {
      this.pickAccount(route.params.address);
    }
  },

  methods: {
    /** If a user is on this page,
     * he must either have at least one connected  wallet
     * or be redirected to welcome page
     */
    checkAccountsLength() {
      if (!this.accounts.length && this.$route.name !== 'welcome') {
        console.log(this.$route.name);
        this.$router.push({
          name: 'welcome',
        });
      }
      if (this.accounts.length === 1) {
        this.pickAccount(this.accounts[0].address);
      }
    },
    /**
     * @param {string} _address
     */
    pickAccount(_address) {
      this.$store.commit(DEACTIVATE_ACTIVE_ACCOUNT);
      this.$store.commit(SET_ACTIVE_ACCOUNT_BY_ADDRESS, _address);
      this.$store.commit(SET_ACTIVE_ACCOUNT_ADDRESS, _address);
    },

    /**
     * @param {string} _address
     * @param {number} _index
     * @param {Event: target} _target
     */
    onAccountCardClick(_address, _index, _target) {
      if (_address) {
        this.hidePopover();
        this.pickAccount(_address);
        this.$emit('account-picked', _address);

        if (!isNaN(_index) && _index > -1) {
          const editBtnId = `#edit-btn-${_index}`;
          if (_target.closest(editBtnId)) {
            this.chosenAccountAddress = _address;
            this.chosenAccountOrder = _index + 1;
            this.$refs.accountSettingsWindow.show();
          }
        }
      }
    },

    /**
     * Shows or hides popovers for Edit and Copy buttons on hover
     * @param {string} _index - index of hovered wallet card
     * @param {Event: target} _target
     */
    onAccountCardMouseOver(_index, _target) {
      if (!isNaN(_index) && _index > -1) {
        const editBtnId = `#edit-btn-${_index}`;
        const copyBtnId = `#copy-btn-${_index}`;
        if (_target.closest(editBtnId)) {
          this.showPopover(editBtnId, 'Edit wallet', 'top');
        }
        if (_target.closest(copyBtnId)) {
          this.showPopover(copyBtnId, 'Copy address to clipboard', 'bottom');
        }
        if (!_target.closest(editBtnId) && !_target.closest(copyBtnId)) {
          this.hidePopover();
        }
      }
    },

    /**
     * Shows popovers for Edit and Copy buttons on hover
     * @param {string} _id - id of button that is hovered
     * @param {string} _text - text of the popover
     * @param {string} _position - position of the popover
     * @param {Event: target} _target
     */
    showPopover(id, text, position) {
      if (text) {
        this.popoverAttachId = id;
        this.popoverAttachPosition = position;
        this.popoverText = text;
        this.popoverCreated = true;
      }
    },

    //* * Hides popovers for Edit and Copy buttons on hover */
    hidePopover() {
      this.popoverCreated = false;
    },

    /**
     * @param {KeyboardEvent} _event
     */
    onAccountListKeyup(_event) {
      if (this.pickMode && isAriaAction(_event) && !_event.target.closest('.btn')) {
        const elem = _event.target.closest('[data-address]');
        const address = elem ? elem.getAttribute('data-address') : '';

        if (address) {
          this.$emit('account-picked', address);
        }
      }
    },

    /**
     * Активация кошелька при закрытии формы его редактирования. Если есть второй параметр, то это значит,
     * что был ужален активный аккаунт и не надо активироватькошелек с таким адресом
     *
     * @param {string} accountAddress
     */

    onSettingsWindowHide({ accountAddress: _address }, lastExistingAccountAddress) {
      if (_address && !lastExistingAccountAddress) {
        this.pickAccount(_address);
        this.$emit('account-picked', _address);
      }
    },

    /**
     * Удален активный аккаунт
     *
     * @param {string} lastExistingAccountAddress
     */
    onAccountRemoved(lastExistingAccountAddress) {
      if (lastExistingAccountAddress) {
        this.pickAccount(lastExistingAccountAddress);
        this.$emit('account-picked', lastExistingAccountAddress);
      }
    },

    formatCurrencyByLocale,
    toFTM,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
