import './governance.types';
import fetchData from '@/api';
import govContractsQuery from '@/api/queries/governance/govContracts';
import govContractByAddressQuery from '@/api/queries/governance/govContractByAddress';
import govProposalsQuery from '@/api/queries/governance/govProposals';
import govContractAndProposalByIdQuery from '@/api/queries/governance/govContractAndProposalById';
import govProposalVoteQuery from '@/api/queries/governance/govProposalVote';
import govProposalOptionStatesQuery from '@/api/queries/governance/govProposalOptionStates';
import govProposalDelegationsAndOptionStatesQuery from '@/api/queries/governance/govProposalDelegationsAndOptionStates';
// import blockchainStateQuery from '@/api/queries/blockchainState';

/** @type {Governance} */
export let governance = null;
export const GOV_PERCENTAGE_FRAC_DIGITS = 2;

/**
 * Plugin for various Governance requests and calculations.
 */
export class Governance {
  /**
   * @param {Vue} _Vue
   * @param {{apolloClient: ApolloClient}} _options
   */
  static install(_Vue, _options) {
    if (!governance) {
      governance = new Governance(_options);
      _Vue.prototype.$governance = governance;
    }
  }

  /**
   * @param {{apolloClient: ApolloClient}} _options
   */
  constructor(_options) {
    this.apolloClient = _options.apolloClient;
  }

  /**
   * @param {number} _minVotes
   * @return {{color: string, value: number}[]}
   */
  getOverallVotesColors(_minVotes = 0) {
    return [
      {
        value: _minVotes,
        color: '#15cd72',
      },
    ];
  }

  /**
   * @param {string} _status Hex number.
   * @return {string}
   */
  getProposalStatus(_status) {
    switch (parseInt(_status, 16)) {
      case 0:
        return 'In progress';
      case 1:
        return 'Resolved';
      case 2:
        return 'Failed';
      case 4:
        return 'Canceled';
      case 8:
        return 'Execution Expired';
    }

    return '-';
  }

  /**
   * @return {Promise<[GovernanceContract]|[]>}
   */
  async fetchContracts() {
    const data = await fetchData(govContractsQuery);

    return data.govContracts || [];
  }

  /**
   * @param {string} _govAddress Gov contract address
   * @param {string} _accountAddress
   * @param {string} _cursor
   * @param {number} [_count]
   * @return {Promise<GovernanceContract|{}>}
   */
  async fetchContractByAddress(_govAddress, _accountAddress, _cursor, _count = 20) {
    const data = await fetchData(govContractByAddressQuery, {
      address: _govAddress,
      from: _accountAddress,
      cursor: _cursor,
      count: _count,
    });

    return data.govContract || {};
  }

  /**
   * @param {string} _cursor
   * @param {number} [_count]
   * @return {Promise<Object>}
   */
  async fetchProposals(_cursor, _count = 20) {
    const data = await fetchData(govProposalsQuery, {
      cursor: _cursor,
      count: _count,
    });

    return data.govProposals || {};
  }

  /**
   * @param {string} _govAddress
   * @param {string} _accountAddress
   * @param {string} _proposalId
   * @return {Promise<Object>}
   */
  async fetchProposal(_govAddress, _accountAddress, _proposalId) {
    const data = await fetchData(govContractAndProposalByIdQuery, {
      address: _govAddress,
      from: _accountAddress,
      id: _proposalId,
    });

    return data.govContract || {};
  }

  /**
   * @param {string} _govAddress
   * @param {string} _accountAddress
   * @param {string} _delegatedTo
   * @param {string} _proposalId
   * @return {Promise<Object>}
   */
  async fetchProposalVote(_govAddress, _accountAddress, _delegatedTo, _proposalId) {
    const data = await fetchData(govProposalVoteQuery, {
      address: _govAddress,
      from: _accountAddress,
      delegatedTo: _delegatedTo,
      id: _proposalId,
    });
    const govContract = data.govContract || {};

    return govContract.proposal && govContract.proposal.vote
      ? {
        vote: govContract.proposal.vote,
        delegatedTo: _delegatedTo,
      }
      : {};
  }

  /**
   * @param {string} _govAddress
   * @param {string} _proposalId
   * @return {Promise<Object>}
   */
  async fetchProposalOptionStates(_govAddress, _proposalId) {
    const data = await fetchData(govProposalOptionStatesQuery, {
      address: _govAddress,
      id: _proposalId,
    });
    const govContract = data.govContract || {};

    return govContract.proposal && govContract.proposal.optionStates ? govContract.proposal.optionStates : [];
  }

  /**
   * @param {string} _govAddress
   * @param {string} _proposalId
   * @param {string} _accountAddress
   * @return {Promise<Object>}
   */
  async fetchProposalDelegationsAndOptionState(_govAddress, _proposalId, _accountAddress) {
    const data = await fetchData(govProposalDelegationsAndOptionStatesQuery, {
      address: _govAddress,
      from: _accountAddress,
      id: _proposalId,
      optionId: '0x0',
    });

    return data.govContract || {};
  }

  /**
   * @return {Promise<*>}
   */
  /*
    async fetchBlockchainState() {
    const data = await fetchData(blockchainStateQuery);
    return data.state;
    }
    */
}
