<template>
  <f-card class="receive-coins f-card-double-padding">
    <h2 class="align-left">Receive Aria NETS</h2>

    <h3 class="align-center h2">Which blockchain are you receiving NETS from?</h3>

    <blockchain-picker
      disable-e-t-h
      disable-b-n-b
      @blockchain-pick="onBlockchainPick"
    />

    <component
      :is="currentComponent"
      v-bind="currentComponentProperties"
      @change-component="onChangeComponent"
    ></component>
  </f-card>
</template>

<script>
import FCard from '../core/FCard/FCard.vue';
import BlockchainPicker from '../BlockchainPicker/BlockchainPicker.vue';
import ReceiveNETS from './ReceiveNETS.vue';
import ReceiveBNB from './ReceiveBNB.vue';
import ReceiveETH from './ReceiveETH.vue';
import TransactionCompleting from '../TransactionCompleting/TransactionCompleting.vue';
import { eventBusMixin } from '../../mixins/event-bus';

const DEFAULT_COMPONENT = 'receive-n-e-t-s';

export default {
  name: 'ReceiveCoins',

  components: {
    BlockchainPicker,
    FCard,
    ReceiveNETS,
    ReceiveBNB,
    ReceiveETH,
    TransactionCompleting,
  },

  mixins: [eventBusMixin],

  props: {
    /** Start verify NETS account in ReceiveNETS component */
    verifyAccount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentComponent: DEFAULT_COMPONENT,
      // keepAliveExclude: 'BlockchainPicker',
    };
  },

  computed: {
    currentComponentProperties() {
      switch (this.currentComponent) {
        case 'receive-n-e-t-s':
          return {
            verifyAccount: this.verifyAccount,
          };
        case 'transaction-completing':
          return {
            receive: true,
            tokenSwapData: this._data_,
          };
        default:
          return null;
      }
    },
  },

  created() {
    // temporary data
    this._data_ = null;

    this._eventBus.on('account-picked', this.onAccountPicked);
  },

  methods: {
    /**
     * @param {('opera'|'binance'|'ethereum')} _blockchain
     */
    onBlockchainPick(_blockchain) {
      switch (_blockchain) {
        case 'opera':
          this.currentComponent = 'ReceiveNETS';
          break;
        case 'binance':
          this.currentComponent = 'ReceiveBNB';
          break;
        case 'ethereum':
          this.currentComponent = 'ReceiveETH';
          break;
      }
    },

    /**
     * @param {Object} _data
     */
    onChangeComponent(_data) {
      this._data_ = _data.data;

      this.currentComponent = _data.to;

      this.$nextTick(() => {
        this._data_ = null;
      });
    },

    onAccountPicked() {
      const { currentComponent } = this;

      this.currentComponent = '';
      this.$nextTick(() => {
        this.currentComponent = currentComponent;
      });
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
