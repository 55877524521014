import { Governance } from '@/plugins/governance/governance';
import { Metamask } from '@/plugins/metamask/metamask';
import { FFetch } from '@/plugins/ffetch';
import Vue from 'vue';
import {
  Form, FormItem, Select, Option,
} from 'element-ui';
import App from './App.vue';
import appConfig from '../app.config';
import { router } from './plugins/router';
import { apolloProvider, apolloClient } from './plugins/apollo-provider';
import i18n from './plugins/i18n';
import './plugins/vue-timeago';
import './plugins/vue-svgicon';
import './plugins/vue-observe-visibility';
import './plugins/vue-async-computed';
import './plugins/dayjs';
import { store } from './store';
import './filters';
import './registerServiceWorker';
import { FantomWeb3Wallet } from './plugins/fantom-web3-wallet';
import { BNBridgeExchange } from './plugins/bnbridge-exchange/bnbridge-exchange';
import { FNano } from './plugins/fantom-nano';
import { DeFi } from './plugins/defi/defi';
import 'focus-visible';
import './wallet.types';

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(FFetch);
Vue.use(FantomWeb3Wallet, {
  apolloClient,
});
Vue.use(BNBridgeExchange, {
  apiUrl: appConfig.bnbridgeApi.url,
  apiToken: appConfig.bnbridgeApi.token,
});
Vue.use(FNano);
Vue.use(DeFi, { apolloClient });
Vue.use(Metamask);
Vue.use(Governance, { apolloClient });

Vue.config.productionTip = false;

// measure performance
// Vue.config.performance = true;

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
  apolloProvider,
}).$mount('#app');
