<template>
  <div>
    <ul class="f-social-media-links no-markers">
      <li
        v-for="link in socialMediaLinks"
        :id="link.id"
        :key="link.id"
        @mouseover="onLinkHover(link.id, link.title)"
        @mouseleave="hidePopover"
      >
        <a
          :href="link.address"
          target="_blank"
          rel="nofollow"
        >
          <icon
            :data="require(`@/assets/svg/social-media/${link.icon}`)"
            width="24"
            height="24"
          ></icon>
        </a>
      </li>
    </ul>
    <f-window
      v-if="popoverCreated"
      custom-class="blue-popover"
      popover
      visible
      position="absolute"
      :attach-to="popoverAttachId"
      :attach-position="popoverAttachPosition"
      :preferred-attach-position="popoverAttachPosition"
      :attach-margin="[1, 4, 4, 4]"
      :with-header="false"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      style="width: auto; max-width: 360px;"
    >
      <slot name="popover-text">{{ popoverText }}</slot>
    </f-window>
  </div>
</template>

<script>
import FWindow from '@/components/core/FWindow/FWindow.vue';
import { getUniqueId } from '@/utils';

export default {
  name: 'SocialMediaLinks',
  components: { FWindow },
  data() {
    return {
      socialMediaLinks: [
        {
          address: 'https://discord.gg/wzR64kyHkz',
          title: 'Discord',
          icon: 'discord.svg',
          id: getUniqueId(),
        },
        {
          address: 'https://twitter.com/Netsbo_official',
          title: 'Twitter',
          icon: 'twitter.svg',
          id: getUniqueId(),
        },
        {
          address: 'https://medium.com/@netsbo.official',
          title: 'Medium',
          icon: 'medium.svg',
          id: getUniqueId(),
        },
      ],
      popoverCreated: false,
      popoverAttachId: '',
      popoverAttachPosition: '',
      popoverText: '',
    };
  },

  methods: {
    /**
     * Shows popovers for links on hover
     * @param {string} _id - unique id of the link
     * @param {string} _title - text of the popover
     */
    onLinkHover(_id, _title) {
      this.showPopover(`#${_id}`, `Netsbo on ${_title}`);
    },

    /**
     * Shows popovers  on hover
     * @param {string} _id - id of link that is hovered
     * @param {string} _text - text of the popover
     * @param {string} _position - position of the popover
     */
    showPopover(id, text, position = 'bottom') {
      if (text) {
        this.popoverAttachId = id;
        this.popoverAttachPosition = position;
        this.popoverText = text;
        this.popoverCreated = true;
      }
    },

    /**
     * Hides popovers for link on hover
     */
    hidePopover() {
      this.popoverCreated = false;
    },
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
