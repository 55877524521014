<template>
  <f-card class="info-box f-card-double-padding">
    <div class="row no-vert-col-padding align-items-center align-center-lg no-collapse">
      <div class="col-6 col-12-lg align-center-lg info-box__actions-box">
        <template v-if="isAccountRoute">
          <account-actions-box/>
        </template>
        <template v-if="isDashboardRoute">
          <dashboard-actions-box/>
        </template>
      </div>
      <div class="col balances">
        <div class="balance">
          <h3
            id="available-balance"
            class="align-center-lg short-nets"
            @mouseenter="onAvailableBalanceHover"
            @mouseleave="onAvailableBalanceHover"
          >
            <span class="no-break-word">
              {{ formatNumberByLocale(availableBalance) }}
              <span class="ftm">NETS</span>
            </span>
          </h3>
          <f-window
            v-if="availableBalancePopoverCreated"
            custom-class="blue-popover"
            popover
            visible
            position="absolute"
            attach-to="#available-balance"
            attach-position="auto"
            preferred-attach-position="bottom"
            :attach-margin="[4, 4, 4, 4]"
            :with-header="false"
            animation-in="scale-center-enter-active"
            animation-out="scale-center-leave-active"
            style="width: auto; max-width: 360px;"
          >
            <slot name="popover-text">{{ availableBalance }}</slot>
          </f-window>
          <!--  Цены в валюте временно скрыты в текущей реализации-->
          <!-- <div class="currency">
              {{ availableCurrency }}
          </div> -->
          <div class="label h3">Available</div>
        </div>
        <div class="balance total-balance">
          <h3
            id="total-balance"
            class="align-center-lg"
            @mouseenter="onTotalBalanceHover"
            @mouseleave="onTotalBalanceHover"
          >
            <span class="no-break-word">
              {{ formatNumberByLocale(totalBalance) }}
              <span class="ftm">NETS</span>
            </span>
          </h3>
          <f-window
            v-if="totalBalancePopoverCreated"
            custom-class="blue-popover"
            popover
            visible
            position="absolute"
            attach-to="#total-balance"
            attach-position="auto"
            preferred-attach-position="bottom"
            :attach-margin="[4, 4, 4, 4]"
            :with-header="false"
            animation-in="scale-center-enter-active"
            animation-out="scale-center-leave-active"
            style="width: auto; max-width: 360px;"
          >
            <slot name="popover-text">{{ totalBalance }}</slot>
          </f-window>
          <!--  Цены в валюте временно скрыты в текущей реализации-->
          <!-- <div class="currency">
              {{ totalCurrency }}
          </div> -->
          <div
            id="my"
            class="label h3"
          >
            Total
          </div>
        </div>
      </div>
    </div>
  </f-card>
</template>

<script>
import { mapGetters } from 'vuex';
import FWindow from '../core/FWindow/FWindow.vue';
import FCard from '../core/FCard/FCard.vue';
import AccountActionsBox from '../AccountActionsBox/AccountActionsBox.vue';
import DashboardActionsBox from '../DashboardActionsBox/DashboardActionsBox.vue';
import { formatCurrencyByLocale, formatNumberByLocale } from '../../filters';
import { pollingMixin } from '../../mixins/polling';
import { UPDATE_ACCOUNT_BALANCE } from '../../store/actions.type';

export default {
  components: {
    FCard,
    AccountActionsBox,
    DashboardActionsBox,
    FWindow,
  },

  mixins: [pollingMixin],

  data() {
    return {
      totalBalancePopoverCreated: false,
      availableBalancePopoverCreated: false,
    };
  },

  computed: {
    ...mapGetters(['accounts', 'currentAccount', 'currentAccountAddress']),

    isAccountRoute() {
      return this.$route.name.includes('account');
    },

    isDashboardRoute() {
      return this.$route.name === 'dashboard';
    },

    availableBalance() {
      let balance = 0;
      if (this.isAccountRoute) {
        balance = this.WEIToFTM(this.accountBalance);
      }
      if (this.isDashboardRoute) {
        balance = this.accountsBalance;
      }
      return balance;
    },

    totalBalance() {
      let balance = 0;
      if (this.isAccountRoute) {
        balance = this.WEIToFTM(this.accountTotalBalance);
      }
      if (this.isDashboardRoute) {
        balance = this.accountsTotalBalance;
      }
      return balance;
    },

    // * currency is temporarily hidden
    // availableCurrency() {
    //     //* 3d argument means that we don't want to convert from WEI
    //     return formatCurrencyByLocale(this.availableBalance, this.tokenPrice, false);
    // },

    // totalCurrency() {
    //     //* 3d argument means that we don't want to convert from WEI
    //     return formatCurrencyByLocale(this.totalBalance, this.tokenPrice, false);
    // },

    // * is not used for now
    // tokenPrice() {
    //     return this.$store.state.tokenPrice;
    // },

    accountBalance() {
      return this.currentAccount ? this.currentAccount.balance : 0;
    },

    accountsBalance() {
      return this.accounts.reduce((_total, _currItem) => _total + this.WEIToFTM(_currItem.balance), 0);
    },

    accountTotalBalance() {
      return this.currentAccount ? this.currentAccount.totalBalance : 0;
    },

    accountsTotalBalance() {
      return this.accounts.reduce((_total, _currItem) => _total + this.WEIToFTM(_currItem.totalBalance), 0);
    },
  },

  mounted() {
    // todo - нужно ли обновлять баланс в разделе Home, где общий баланс кошельков?
    if (this.isAccountRoute) {
      this._polling.start(
        'update-account-balance',
        () => {
          this.$store.dispatch(UPDATE_ACCOUNT_BALANCE);
        },
        5000,
      );
    }
  },

  methods: {
    WEIToFTM(_value) {
      return parseFloat(this.$fWallet.WEIToFTM(_value));
    },

    onAvailableBalanceHover() {
      this.availableBalancePopoverCreated = !this.availableBalancePopoverCreated;
    },

    onTotalBalanceHover() {
      this.totalBalancePopoverCreated = !this.totalBalancePopoverCreated;
    },

    formatNumberByLocale,
    formatCurrencyByLocale,
  },
};
</script>

<style lang="scss">
@import 'style';
</style>
