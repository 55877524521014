<template>
  <div class="gov-instruction-window">
    <f-window
      ref="win"
      modal
      style="max-width: 720px;"
      class="colored-header create-account-color double-body-padding c-footer gov-instruction-window__inner"
      animation-in="scale-center-enter-active"
      animation-out="scale-center-leave-active"
      @window-hide="onWindowHide"
    >
      <template #title>
        <h2 class="gov-instruction-window__title">{{ $t('gov_instruction.window_title') }}</h2>
      </template>
      <template #controls>
        <button
          class="btn white-btn close-btn same-size round light"
          title="Close window"
        >
          <icon
            data="@/assets/svg/times.svg"
            width="20"
            height="20"
          />
        </button>
      </template>
      <div class="gov-instruction-window__body" >
        <div class="gov-instruction-window__instruction-text" v-html="$t('gov_instruction.text')">
          <h3 class="gov-instruction-window__title-3">NETSBO-Governance</h3>
          <h4 class="gov-instruction-window__title-4">Разворачивание Контракта предложения</h4>
          <p class="gov-instruction-window__paragraph">Разверните контракт
            <span class="gov-instruction-window__code">**proposal/ExecutableProposal.sol**</span>
            со следующими аргументами контруктора::
          </p>
          <ul class="list-marker-dash gov-instruction-window__deploy-list">
            <li><span class="gov-instruction-window__code">**__name**</span> — Название предложения.</li>
            <li><span class="gov-instruction-window__code">**__description**</span>
              — Описание предложения. Объясните, что вы хотите сделать своим предложением.</li>
            <li><span class="gov-instruction-window__code">**__start**</span>
              — Через сколько секунд запустится голосование.</li>
            <li><span class="gov-instruction-window__code">**__minEnd**</span>
              — Через сколько секунд после начала голосование может быть закрыто.</li>
            <li><span class="gov-instruction-window__code">**__maxEnd**</span>
              — Через сколько секунд после начала голосование голосование закроется.</li>
            <li><span class="gov-instruction-window__code">**__destination**</span>
              — Адрес контракта, который будет вызван в случае успешного голосования.</li>
            <li><span class="gov-instruction-window__code">**__data**</span>
              — Кодированные интерфейс функции контракта назначения вместе с аргументами. Используй
              <span class="gov-instruction-window__code">***web3.encodeFunctionSignature***</span>
              чтобы получить <span class="gov-instruction-window__code">__data.</span>
            </li>
          </ul>
          <p class="gov-instruction-window__paragraph">
            Параметры контракта должны следовать  <b>ограчениям данного типа предложения</b>.
            Вызовите <span class="gov-instruction-window__code">`ProposalTemplates.get({proposalType})`</span>
            , чтобы увидеть требования для данного типа предложения.
          </p>
          <ul class="no-markers gov-instruction-window__requirements-list">
            <li>
              <span class="gov-instruction-window__code--number">0</span>
              Неисполняемое предложение
            </li>
            <li>
              <span class="gov-instruction-window__code--number">1</span>
              Предложение с функцией <span class="gov-instruction-window__code">call</span>
            </li>
            <li>
              <span class="gov-instruction-window__code--number">2</span>
              Предложение с функцией <span class="gov-instruction-window__code">delegatecall</span>
            </li>
          </ul>
          <h4 class="gov-instruction-window__title-4">Добавление вашего предложения к голосованию</h4>
          <ol class="wide-markers gov-instruction-window__requirements-list">
            <li>Скопируйте адрес развернутого контракта предложения</li>
            <li>Установите <span class="gov-instruction-window__code">value</span>
              транзакции в размере комиссии за создание предложения
              (<span class="gov-instruction-window__code">`Governance.proposalFee()`</span>)
            </li>
            <li>
              Вызовите <span class="gov-instruction-window__code">`Governance.createProposal()`</span>
              функцию со скопированным адресом предложения.
            </li>
          </ol>
          <div class="gov-instruction-window__attention-block">
            Если вы увидели сообщение об ошибке,
            сравните параметры вашего предложения с требованиями шаблона для данного типа голосования.
          </div>
        </div>
        <div class="gov-instruction-window__address-box">
          <ul class="no-markers">
            <li>
              <span class="gov-instruction-window__code">Governance</span>
              &nbsp; 0x0f725f489dFa687AF7f0cB5B350429a61FC1a61b
            </li>
            <li>
              <span class="gov-instruction-window__code">Proposal Template Verifier</span>
              0x43f25Dae172555921D48e37a284F3F89E50043b6
            </li>
          </ul>
        </div>
      </div>
    </f-window>
  </div>
</template>

<script>
import FWindow from '../../core/FWindow/FWindow.vue';
import CreateAccount from '../../../views/CreateAccount/CreateAccount.vue';

export default {
  name: 'CreateAccountWindow',

  components: { FWindow },

  mixins: [CreateAccount],

  methods: {
    show() {
      this.$refs.win.show();
    },

    /**
     * Re-target `'window-hide'` event.
     *
     * @param {object} _data
     */
    onWindowHide(_data) {
      this.setDefaultComponent();

      this.$emit('window-hide', _data);
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/_fantom-color-palette.scss';
  .gov-instruction-window {
    &__inner {
      max-height: 931px !important;
    }

    &__title {
      text-align: left;
      width: fit-content;
      padding-left: 16px;
    }

    &__title-3 {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
    }

    &__title-4 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
    }

    &__body {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
    }

    &__code {
      color: var(--f-primary-color);
      font-family: monospace;
      word-break: break-word;
      &--number {
        color: var(--f-primary-color);
        font-family: monospace;
        display: inline-block;
        width: 48px;
        text-align: center;
      }
    }

    &__deploy-list {
      padding-bottom: 1rem;
    }

    &__requirements-list {
      padding-bottom: 32px;
    }

    &__attention-block {
      padding: 12px 20px;
      border: 1px solid $error-color;
      border-radius: 12px;
      margin-bottom: 32px;
    }
  }
</style>
