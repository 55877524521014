<template>
  <label
    class="toggle-btn"
    :class="sizeClass"
  >
        <span
          v-if="with2Labels"
          class="toggle-btn__double-label"
          :class="{ active: !isChecked }"
        >
            <slot>{{ leftLabel }}</slot>
        </span>
    <input
      type="checkbox"
      class="toggle-btn__input"
      v-bind="checkboxProps"
      @change="onChange"
    />
    <span class="toggle-btn__btn"></span>
    <span class="toggle-btn__label">
            <slot>{{ label }}</slot>
        </span>
    <span
      v-if="with2Labels"
      class="toggle-btn__double-label"
      :class="{ active: isChecked }"
    >
            <slot>{{ rightLabel }}</slot>
        </span>
  </label>
</template>

<script>
import { checkboxMixin } from '@/mixins/checkbox';

export default {
  name: 'FToggleButton',

  mixins: [checkboxMixin],

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    /**
     * Position of three dots.
     *
     * @type {('start' | 'middle' | 'end')}
     */
    size: {
      type: String,
      default: 'md',
      validator(_value) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(_value) !== -1;
      },
    },

    with2Labels: {
      type: Boolean,
      default: false,
    },

    leftLabel: {
      type: String,
      default: '',
    },

    rightLabel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // * if true, rightLabel is chosen, else - left one.
      isChecked: true,
    };
  },
  computed: {
    sizeClass() {
      return `toggle-btn--${this.size}`;
    },
  },

  methods: {
    onChange(_event) {
      this.isChecked = _event.target.checked;
      this.$emit('change', _event.target.checked);
    },
  },
};
</script>
